import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { ReportsApiServices } from "../actions/ReportsAction";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

export const generateAbsenceReport = createAsyncThunk(
  "ReportsReducer/generateAbsenceReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ReportsApiServices.generateAbsenceReport(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateLatenessReport = createAsyncThunk(
  "ReportsReducer/generateLatenessReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ReportsApiServices.generateLatenessReport(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateAnnualLeaveSummaryReport = createAsyncThunk(
  "ReportsReducer/generateAnnualLeaveSummaryReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response =
        await ReportsApiServices.generateAnnualLeaveSummaryReport(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateEmployeeDetailsReport = createAsyncThunk(
  "ReportsReducer/generateEmployeeDetailsReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ReportsApiServices.generateEmployeeDetailsReport(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateTimeSheetReport = createAsyncThunk(
  "ReportsReducer/generateTimeSheetReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ReportsApiServices.generateTimeSheetReport(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const generatePayrollReport = createAsyncThunk(
  "ReportsReducer/generatePayrollReport",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await ReportsApiServices.generatePayrollReport(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


const initialState = {
  alreadyLoggedIn: false,
  loading: "idle",
  error: null,
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const ReportReducer = createReducer(initialState, {
  [generateAbsenceReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [generateAbsenceReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateAbsenceReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  // generateLatenessReport
  [generateLatenessReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [generateLatenessReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateLatenessReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateAnnualLeaveSummaryReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [generateAnnualLeaveSummaryReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateAnnualLeaveSummaryReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateEmployeeDetailsReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },

  [generateEmployeeDetailsReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateEmployeeDetailsReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  // ----------START** GENERATEPAYROLL REPORT **START-----------
  [generatePayrollReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [generatePayrollReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [generatePayrollReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
// ----------END** GENERATEPAYROLL REPORT **END-----------

  [generateTimeSheetReport.pending]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateTimeSheetReport.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [generateTimeSheetReport.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});
