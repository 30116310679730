import { createAsyncThunk, createReducer, current } from "@reduxjs/toolkit";
import { StaffingApiServices } from "../actions/StaffingActions";
import { updateStaffingStatus } from "../../Utilities";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

export const StaffingRequest = createAsyncThunk(
  "StaffingReducer/StaffingRequest",
  async (payload) => {
    const response = await StaffingApiServices.getStaffingData(payload);
    return response;
  }
);

export const filteredStaffingRequest = createAsyncThunk(
  "StaffingReducer/filteredStaffingRequest",
  async (payload) => {
    const response = await StaffingApiServices.getFlteredStaffing(payload);
    return response;
  }
);

export const UpdateStaffing = createAsyncThunk(
  "StaffingReducer/UpdateStaffing",
  async (payload) => {
    return payload;
  }
);

export const callUpdateStaffingAPI = createAsyncThunk(
  "StaffingReducer/callUpdateStaffingAPI",
  async (payload) => {
    const response = await StaffingApiServices.changeStaffingStatus(payload);
    return response;
  }
);

export const createStaffing = createAsyncThunk(
  "StaffingReducer/createStaffing",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.createStaffing(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const renameStaffing = createAsyncThunk(
  "StaffingReducer/renameStaffing",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.renameStaffingRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStaffingRequest = createAsyncThunk(
  "StaffingReducer/deleteStaffingRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.deleteStaffingRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEvents = createAsyncThunk(
  "StaffingReducer/getEvents",
  async (payload) => {
    const response = await StaffingApiServices.getAllShiftsRequest(payload);
    return response;
  }
);

export const getRotaShif = createAsyncThunk(
  "StaffingReducer/getRotaShif",
  async (payload) => {
    const response = await StaffingApiServices.getRotaShiftRequest(payload);
    return response;
  }
);

export const updateStaffing = createAsyncThunk(
  "StaffingReducer/updateStaffing",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.updateStaffingRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createShift = createAsyncThunk(
  "StaffingReducer/createShift",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.createShiftRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateShift = createAsyncThunk(
  "StaffingReducer/updateShift",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.updateShiftRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getViewStaffing = createAsyncThunk(
  "StaffingReducer/getViewStaffing",
  async (payload) => {
    const response = await StaffingApiServices.getViewStaffingRequest(payload);
    return response;
  }
);

export const getStaffingById = createAsyncThunk(
  "StaffingReducer/getStaffingById",
  async (payload) => {
    const response = await StaffingApiServices.getStaffingByIdRequest(payload);
    return response;
  }
);

export const assignEmployeeReq = createAsyncThunk(
  "StaffingReducer/assignEmployeeReq",
  async (payload) => {
    const response = await StaffingApiServices.assignEmployeesRequest(payload);
    return response;
  }
);
export const UnAssignEmployeeReq = createAsyncThunk(
  "StaffingReducer/UnAssignEmployeeReq",
  async (payload) => {
    const response = await StaffingApiServices.unAssignEmployeesRequest(
      payload
    );
    return response;
  }
);

export const shiftHandlerReq = createAsyncThunk(
  "StaffingReducer/shiftHandlerReq",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.shiftHandlerRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkShiftHandler = createAsyncThunk(
  "StaffingReducer/bulkShiftHandler",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.bulkShiftHandlerRequest(payload);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);



export const deleteShiftReq = createAsyncThunk(
  "StaffingReducer/deleteShiftReq",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await StaffingApiServices.deleteShiftHandlerRequest(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMultipleCalendarSelect = createAsyncThunk(
  "StaffingReducer/updateMultipleCalendarSelect",
  async (payload) => {
    return payload;
  }
);

const emptyData = {
  name: "",
  start_date: "",
  duration: "",
  rotaId: "",
  currentUserId: "",
  shiftId: "",
  assignedWorker: "",
  shiftDay: "",
  shiftTimeStart: "",
  shiftTimeEnd: "",
  breakDuration: "",
  notes: "",
  users: [],
  ids: [],
  assignedWorkers: [],
};
const initialState = {
  alreadyLoggedIn: false,
  loading: "idle",
  error: null,
  data: [],
  newData: [],
  shifts: [],
  currentStaffing: emptyData,
  filteredStaffing: [],
  currentStaffingData: [],
  staff: null,
  multipleShifts: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const StaffingReducer = createReducer(initialState, {
  [StaffingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
      filteredStaffing: [],
    };
  },
  [StaffingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      data: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [StaffingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [filteredStaffingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
      filteredStaffing: [],
    };
  },
  [filteredStaffingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      filteredStaffing: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [filteredStaffingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [callUpdateStaffingAPI.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [callUpdateStaffingAPI.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [callUpdateStaffingAPI.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getStaffingById.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getStaffingById.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      staff: action.payload.data,
      error: null,
    };
  },
  [getStaffingById.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [assignEmployeeReq.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [assignEmployeeReq.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [assignEmployeeReq.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [deleteShiftReq.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteShiftReq.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [deleteShiftReq.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [UnAssignEmployeeReq.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [UnAssignEmployeeReq.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [UnAssignEmployeeReq.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  // ----------START BULK CREATE API  START------------- 
  [bulkShiftHandler.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [bulkShiftHandler.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [bulkShiftHandler.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },
  // ----------END  BULK CREATE API  END------------- 

  [shiftHandlerReq.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [shiftHandlerReq.fulfilled]: (state, action) => {
    return {
      ...state,

      loading: states.idle,
      error: null,
    };
  },
  [shiftHandlerReq.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [UpdateStaffing.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [UpdateStaffing.fulfilled]: (state, action) => {
    return {
      ...state,
      data: updateStaffingStatus(current(state.data), action.payload),
      loading: states.idle,
      error: null,
    };
  },
  [UpdateStaffing.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [createStaffing.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createStaffing.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createStaffing.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [getEvents.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getEvents.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
      shifts: action.payload.data,
    };
  },
  [getEvents.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },
  [getRotaShif.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getRotaShif.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      currentStaffing: action.payload.data,
      error: null,
    };
  },

  [getRotaShif.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
      currentStaffing: emptyData,
    };
  },

  [getViewStaffing.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      currentStaffingData: [],
      error: null,
    };
  },
  [getViewStaffing.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      currentStaffingData: action.payload.data,
      error: null,
    };
  },

  [getViewStaffing.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
      currentStaffingData: [],
    };
  },

  [updateStaffing.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateStaffing.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [updateStaffing.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [createShift.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [createShift.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [createShift.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [renameStaffing.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [renameStaffing.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [renameStaffing.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [deleteStaffingRequest.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [deleteStaffingRequest.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [deleteStaffingRequest.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [updateMultipleCalendarSelect.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [updateMultipleCalendarSelect.fulfilled]: (state, action) => {
    // console.log(
    //   "updateMultipleCalendarSelect ==== payload ==== ",
    //   action.payload
    // );
    return {
      ...state,
      loading: states.idle,
      multipleShifts: action.payload.array,
      error: null,
    };
  },
  [updateMultipleCalendarSelect.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: "",
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});
