import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { TimesheetApiServices } from "../actions/TimesheetActions";
import {
  stopLoaderAndEmptyErrors,
  logoutRequest,
} from "./AuthenticationReducer";

export const getClockedInAndOutUsersList = createAsyncThunk(
  "TimesheetReducer/getClockedInAndOutUsersList",
  async (payload) => {
    const response = await TimesheetApiServices.apiGetClockedInAndOutUsersList(
      payload
    );
    // console.log("ClockedInClockedOut Users ==== ", response); 
    return response;
  }
);

export const getUserTimeSheetHistory = createAsyncThunk(
  "TimesheetReducer/getUserTimeSheetHistory",
  async (payload) => {
    const response = await TimesheetApiServices.apiGetUserTimeSheetHistory(
      payload
    );
    return response;
  }
);

export const getUserTimeSheetDetails = createAsyncThunk(
  "TimesheetReducer/getUserTimeSheetDetails",
  async (payload) => {
    const response = await TimesheetApiServices.getUserTimeSheetDetailsRequest(
      payload
    );
    return response;
  }
);

export const toggleBreak = createAsyncThunk(
  "TimesheetReducer/toggleBreak",
  async (payload) => {
    const response = await TimesheetApiServices.toggleBreakRequest(payload);
    return response;
  }
);

export const toggleClock = createAsyncThunk(
  "TimesheetReducer/toggleClock",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await TimesheetApiServices.toggleClockRequest(payload);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
    // const response = await TimesheetApiServices.toggleClockRequest(payload);
    // return response;
  }
);
export const requestCustomClockOut = createAsyncThunk(
  "TimesheetReducer/requestCustomClockOut",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await TimesheetApiServices.apiRequestCustomClockOut(
        payload
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: "idle",
  error: null,
  whoseInUsersList: [],
  userTimeSheetResponse: [],
  userTimeSheetDetails: [],
  clockStatus: null,
  breakStatus: null,
  lastClockIn: null,
};

const states = {
  pending: "pending",
  idle: "idle",
};

export const TimesheetReducer = createReducer(initialState, {
  [getClockedInAndOutUsersList.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getClockedInAndOutUsersList.fulfilled]: (state, action) => {
    return {
      ...state,
      whoseInUsersList: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getClockedInAndOutUsersList.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getUserTimeSheetHistory.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getUserTimeSheetHistory.fulfilled]: (state, action) => {
    return {
      ...state,
      userTimeSheetResponse: action.payload.data,
      loading: states.idle,
      error: null,
    };
  },
  [getUserTimeSheetHistory.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [getUserTimeSheetDetails.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [getUserTimeSheetDetails.fulfilled]: (state, action) => {
    return {
      ...state,
      userTimeSheetDetails: action.payload.data.data,
      clockStatus: action.payload.data.clock_status,
      breakStatus: action.payload.data.break_status,
      lastClockIn: action.payload.data?.clock_time,
      loading: states.idle,
      error: null,
    };
  },
  [getUserTimeSheetDetails.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [toggleBreak.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [toggleBreak.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [toggleBreak.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [toggleClock.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [toggleClock.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [toggleClock.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [requestCustomClockOut.pending]: (state, action) => {
    return {
      ...state,
      loading: states.pending,
      error: null,
    };
  },
  [requestCustomClockOut.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },
  [requestCustomClockOut.rejected]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: action.error.message,
    };
  },

  [stopLoaderAndEmptyErrors.fulfilled]: (state, action) => {
    return {
      ...state,
      loading: states.idle,
      error: null,
    };
  },

  [logoutRequest.fulfilled]: (state, action) => {
    return {
      ...initialState,
    };
  },
});
