import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { get } from "lodash";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import Spinner from "../../components/Spinner";
import Header from "../../components/Header/Header";
import TimePanel from "./TimePanel";
import WhoseIn from "./WhoseIn";
import AppConstants from "../../Helpers/AppConstants";
import {CalendarLeavesForToday } from "../../Redux/reducers/CalenderReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { getApiDatePayload, showFaliureToast, getDateComponents } from "../../Utilities";

export default function TimeSheet() {
  // Dispatcher
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Localisation
  const { t } = useTranslation();

  // Hooks
  useTitleHandler(t("timesheet"));

  // Reducer States
  const { loading } = useSelector((state) => state.timesheet);
  const { token, user } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);

  // Local States
  const [reloadPagech, setReloadPage] = useState(false);

  // Priviliges
  const { exist: listTimesheetHistory } = usePrevilagesExist(
    AppConstants.previlages.listTimesheetHistory
  );

  // Hookes 
  useEffect(() => {
    getAbsenceFromCalender();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
  }, [token]);

  const { exist: ClockInOutOtherUsers } = usePrevilagesExist(
    AppConstants.previlages.ClockInOutOtherUsers
  );

  const getAbsenceFromCalender = () => {
    var start_date = getDateComponents(new Date()); 
    var formated_date = getApiDatePayload(start_date); 
    var filterParam = `&start_date=${formated_date}&end_date=${formated_date}`;
     dispatch(CalendarLeavesForToday({ token, lng , filterParam }))
      .then(unwrapResult)
      .then((_) => {
      })
      .catch((err) => {
        if (get(err, "message", "")) {
          showFaliureToast(get(err, "message", ""));
        } else {
          showFaliureToast(err);
        }
      });
  };


  return (
    <div>
      <Header active={"TimeSheet"} />
      <br />
      <div className="sp_main">
        {loading == "pending" && <Spinner />}
        <div className="myFlex-cs flex-wrap-sm justify-content-between">
          <h1 className="top-heading">{t("timesheet")}</h1>
          {listTimesheetHistory && (
            <button
              onClick={() =>
                navigate(
                  `${AppConstants.routes.timeSheetHistory}?user=${get(
                    user,
                    "user_id",
                    ""
                  )}`
                )
              }
              className="btn btn-primary-cs mb-2"
              type="button"
            >
              {t("myHistory")}
            </button>
          )}
        </div>
      </div>
      <div className="sp_main">
        <div className="d-flex gap-2 flex-wrap-sm">
          <TimePanel
            reloadPage={(value) => setReloadPage(value)}
            refreshStatus={reloadPagech}
            addClass={ClockInOutOtherUsers ? "" : "col-lg-12"}
          />
          {ClockInOutOtherUsers && (
            <WhoseIn
              reloadPage={(value) => setReloadPage(value)}
              refreshStatus={reloadPagech}
            />
          )}
        </div>
        <br />
      </div>
    </div>
  );
}
