import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
  Collapse,
  Card,
  Col,
  Row,
  Timeline,
  DatePicker,
  Button,
} from "antd";
import {
  getUserTimeSheetHistory,
  requestCustomClockOut,
} from "../../Redux/reducers/TimesheetReducer";
import { get } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import ViewOnGoogle from "../../components/AppButtons/ViewOnGoogle";
import moment from "moment";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import EditClockOutModal from "./EditClockOutModal";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";
import UseUsers from "../../Helpers/useUsers";
import "./History.css";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import "react-nice-dates/build/style.css";
import {
  formattedDateForAPI,
  showFaliureToast,
  showSuccessToast,
  unixToDateFormaterOld,
} from "../../Utilities";
import format from "date-fns/format";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export default function HistoryPanel() {
  // Dispatcher
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {} = UseUsers(false);
  // Localisation
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();

  const { reportCategories } = appConstants;
  // Params
  const [params] = useSearchParams();

  // Reducer States
  const { user, token } = useSelector((state) => state.AuthenticationReducer);
  const { userTimeSheetResponse } = useSelector((state) => state.timesheet);
  const { lng } = useSelector((state) => state.languageReducer);
  const { allEmployees } = useSelector((state) => state.employees);

  // Priviliges
  const { exist: listTimesheetHistory } = usePrevilagesExist(
    AppConstants.previlages.listTimesheetHistory
  );

  const { exist: listOthersTimesheetHistory } = usePrevilagesExist(
    AppConstants.previlages.listOthersTimesheetHistory
  );

  const { exist: ClockInOutOtherUsers } = usePrevilagesExist(
    AppConstants.previlages.ClockInOutOtherUsers
  );
  const { exist: generateTimesheetReport } = usePrevilagesExist(
    AppConstants.previlages.generateTimesheetReport
  );
  // Local States
  const [selectedClockItem, setSelectedClockItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [userHistory, setUserHistory] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [userDates, setUserDates] = useState({ startDate: "", endDate: "" });

  // Constants
  const userId = params.get("user");
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const current = new Date();
  const date_cs = `${String(current.getDate()).padStart(2, "0")}/${String(
    current.getMonth() + 1
  ).padStart(2, "0")}/${current.getFullYear()}`;

  useEffect(() => {
    if (userId) {
      getTimeSheetResponse(userId);
      setCurrentUserId(userId);
    } else {
      getTimeSheetResponse(user.user_id);
      setCurrentUserId(user.user_id);
    }
  }, [userId]);

  useEffect(() => {
    setUserHistory(userTimeSheetResponse);
  }, [userTimeSheetResponse]);

  useEffect(() => {
    getCurrentEmployee();
  }, [allEmployees]);

  const getCurrentEmployee = () => {
    if (userId == get(user, "user_id", "")) {
      setCurrentUser(user);
    } else {
      allEmployees.forEach((employee) => {
        if (get(employee, "user_id", "") == userId) {
          setCurrentUser(employee);
        } else {
          if (!userId) {
            setCurrentUser(user);
          }
        }
      });
    }
  };

  const getTimeSheetResponse = (id) => {
    const daterange = {
      startDate: formattedDateForAPI(userDates.startDate),
      endDate: formattedDateForAPI(userDates.endDate),
    };

    dispatch(getUserTimeSheetHistory({ token, dateRange: daterange, id, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("User timesheet history response", response);
      })
      .catch((err) => {
        Applogger("Error at getUserTimeSheetHistory", err.message);
      });
  };

  const onChange = (key) => {
    Applogger("onChange Key", key);
  };

  const isClockOutEditable = (clockIn) => {
    return timeDifference(clockIn) > 12;
  };

  const timeDifference = (clockInDate) => {
    var now = moment(); //todays date
    var end = moment.unix(clockInDate); // another date
    var totalMinutes = now.diff(end, "minutes");
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    return Number(`${padTo2Digits(hours)}.${padTo2Digits(minutes)}`);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  const handleCustomClockOut = (clockOutTime, reason) => {
    const body = {
      user_id: user.user_id,
      status: "CUSTOM_OUT",
      clock_out_date: formattedDateForAPI(
        moment(clockOutTime).format(AppConstants.defaultDateFormat)
      ),
      clock_out: moment(clockOutTime).format("HH:MM"),
      approval_reason: reason,
    };

    dispatch(requestCustomClockOut({ body, token }))
      .then(unwrapResult)
      .then((res) => {
        getTimeSheetResponse(currentUserId);
        Applogger("Response at requestCustomClockOut", res);
        setShowEditModal(false);
        showSuccessToast(res?.success ?? "Clock out time added successfully");
      })
      .catch((err) => {
        showFaliureToast(err);
        Applogger("Error at requestCustomClockOut", err);
      });
  };

  const getUserName = (selectedUser) => {
    if (selectedUser) {
      return `${get(selectedUser, "first_name", "")} ${get(
        selectedUser,
        "last_name",
        ""
      )}'s ${t("history")}`;
    }
  };

  const getTotalBreaks = (hoursItem) => {
    if (hoursItem.length > 0) {
      let totalBreaks = 0;
      hoursItem.forEach((hour) => {
        get(hour, "break", []).forEach((breaks) => {
          if (get(breaks, "time_start", "") && get(breaks, "time_end", "")) {
            const end = moment.unix(get(breaks, "time_end", ""));
            const start = moment.unix(get(breaks, "time_start", ""));
            const duration = moment.duration(end.diff(start));
            totalBreaks += duration.asHours();
          }
        });
      });
      return totalBreaks.toFixed(2);
    } else {
      return 0;
    }
  };

  const getTotalHours = (hoursItem) => {
    if (hoursItem && hoursItem.length > 0) {
      let totalHours = 0;
      hoursItem.forEach((hour) => {
        if (get(hour, "clock_in", "") && get(hour, "clock_out", "")) {
          const end = moment.unix(get(hour, "clock_out", ""));

          const start = moment.unix(get(hour, "clock_in", ""));
          const duration = moment.duration(end.diff(start));

          totalHours += duration.asHours();
        }
      });

      return totalHours.toFixed(2);
    } else {
      return 0;
    }
  };

  return (
    <div data-aos="fade-up" className="col-sm-12 col-md-12 col-lg-12 px-None">
      <EditClockOutModal
        isVisible={showEditModal}
        setIsVisible={setShowEditModal}
        clockItem={selectedClockItem}
        onConfirm={(clockOutTime, reason) => {
          handleCustomClockOut(clockOutTime, reason);
        }}
        onCancel={() => {
          setSelectedClockItem(null);
          setShowEditModal(false);
        }}
      />
      <div className="main-panel bg-white border-custom overflow-hidden">
        <div className="panel-head animate__animated animate__fadeInLeft">
          <div>
            {user && (
              <h3 className="mb-0 p-0">
                {currentUser ? getUserName(currentUser) : t("invalidUser")}
              </h3>
            )}
          </div>
          {(listTimesheetHistory || listOthersTimesheetHistory) && (
            <div className="position-relative">
              <RangePicker
                onChange={(date, dateString) => {
                  setUserDates({
                    ...userDates,
                    startDate: dateString[0],
                    endDate: dateString[1],
                  });
                }}
                format={AppConstants.defaultDateFormat}
                value={[
                  userDates.startDate
                    ? dayjs(
                        userDates.startDate,
                        AppConstants.defaultDateFormatDashed
                      )
                    : dayjs(),
                  userDates.endDate
                    ? dayjs(
                        userDates.endDate,
                        AppConstants.defaultDateFormatDashed
                      )
                    : dayjs(),
                ]}
              />
              <Button
                onClick={() => getTimeSheetResponse(currentUserId)}
                disabled={
                  userDates.startDate == "" && userDates.endDate == ""
                    ? true
                    : false
                }
                className="ml-2"
                type="primary"
              >
                {t("applyFilter")}
              </Button>
              <Button
                onClick={() => {
                  setUserDates({
                    startDate: "",
                    endDate: "",
                  });
                }}
                disabled={
                  userDates.startDate == "" && userDates.endDate == ""
                    ? true
                    : false
                }
                className="ml-2"
                type="primary"
              >
                {t("clearFilter")}
              </Button>
              {generateTimesheetReport && (
                <Button
                  onClick={() => {
                    navigate(
                      `${AppConstants.routes.analytics_details}?type=${reportCategories.blip_timesheet}`
                    );
                  }}
                  className="ml-2"
                  type="primary"
                >
                  {t("viewAnalytics")}
                </Button>
              )}
            </div>
          )}
        </div>
        <hr />
        <div className="panel-body p-2">
          <br />
          <div className="d-flex justify-content-between align-items-end flex-wrap">
            <h4 className="h4 fw-bold">{t("timeLog")}</h4>
            <h6 className="h6 fw-bold">
              {t("date")}: {date_cs}
            </h6>
          </div>
          <section id={"history"}>
            <Card style={{ overflow: "auto" }}>
              <Collapse defaultActiveKey={0} onChange={onChange}>
                {userHistory.length > 0 ? (
                  userHistory.map((val, index) => {
                    // console.log("userhistotry++++++++++++++++", userHistory);
                    return (
                      <Panel
                        className="animate__animated animate__backInUp"
                        header={`${moment
                          .unix(val.date)
                          .zone(0)
                          .format(AppConstants.dateFormat)}`}
                        key={index}
                      >
                        {val.data.length > 0
                          ? val.data.map((val, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <Row gutter={16}>
                                    <Col span={12}>
                                      <ClockCell
                                        title={t("clockIn")}
                                        time={val.clock_in}
                                        date={val.clock_in}
                                      />
                                      {get(val, "in_latitude", null) && (
                                        <div className="d-flex flex-column">
                                          <p className="mb-0">
                                            {t("clockInFrom")}
                                          </p>
                                          <ViewOnGoogle
                                            addCss="justify-content-start"
                                            latlong={{
                                              latitude: get(
                                                val,
                                                "in_latitude",
                                                ""
                                              ),
                                              longitude: get(
                                                val,
                                                "in_longitude",
                                                ""
                                              ),
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                    <Col span={12}>
                                      <ClockCell
                                        title={t("clockOut")}
                                        time={val.clock_out}
                                        date={val.clock_out}
                                        isEditable={
                                          isClockOutEditable(val.clock_in) &&
                                          !val.clock_out &&
                                          ClockInOutOtherUsers
                                        }
                                        onEdit={() => {
                                          setSelectedClockItem(val);
                                          setShowEditModal(true);
                                        }}
                                      />
                                      {get(val, "out_latitude", null) && (
                                        <div className="d-flex flex-column">
                                          <p className="mb-0">
                                            {t("clockOutFrom")}
                                          </p>
                                          <ViewOnGoogle
                                            addCss="justify-content-start"
                                            latlong={{
                                              latitude: get(
                                                val,
                                                "out_latitude",
                                                ""
                                              ),
                                              longitude: get(
                                                val,
                                                "out_longitude",
                                                ""
                                              ),
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  <br />
                                  <hr />
                                  <br />
                                  <div>
                                    <Timeline mode="left">
                                      <h2 className="h5">
                                        {t("breaks")}
                                        <span className="badge mx-2">
                                          {val.break.length}
                                        </span>
                                      </h2>
                                      {val.break.length > 0 ? (
                                        val.break.map((item, index) => {
                                          {
                                            return (
                                              <Timeline.Item
                                                key={index}
                                                label={
                                                  t("start") +
                                                  " " +
                                                  moment
                                                    .unix(item.time_start)
                                                    .format(
                                                      AppConstants.timeFormat
                                                    )
                                                }
                                              >
                                                {`${t("end")} ${
                                                  item.time_end
                                                    ? moment
                                                        .unix(item.time_end)
                                                        .format(
                                                          AppConstants.timeFormat
                                                        )
                                                    : "--/--"
                                                }`}
                                              </Timeline.Item>
                                            );
                                          }
                                        })
                                      ) : (
                                        <p>{t("noBreakFound")}</p>
                                      )}
                                    </Timeline>
                                  </div>
                                  <hr />
                                  <br />
                                </React.Fragment>
                              );
                            })
                          : null}

                        <p>
                          {"Total number of working hours: " +
                            getTotalHours(get(val, `data`, null))}
                        </p>
                        <p>
                          {"Total number of break hours: " +
                            getTotalBreaks(get(val, `data`, null))}
                        </p>
                      </Panel>
                    );
                  })
                ) : (
                  <h4>{t("noDataFound")}</h4>
                )}
              </Collapse>
            </Card>
          </section>
        </div>
      </div>
    </div>
  );
}

export const ClockCell = ({ time, date, title, isEditable, onEdit }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="h5">{title}</h2>
      <div className="mb-0">
        {time != null ? (
          <p className="me-2">
            <b>{moment.unix(time).format(AppConstants.timeFormat)}</b>
            <span className="mx-2">|</span>
            <span>{moment.unix(date).format(AppConstants.dateFormat)}</span>
          </p>
        ) : (
          <p className="me-2">
            <span>
              <b>--/--</b> | --/--
              {isEditable && (
                <Tooltip title={t("clickToEdit")} placement="right">
                  <i
                    className="ri-pencil-fill cursor-pointer ml-2 fs-4"
                    onClick={onEdit}
                  />
                </Tooltip>
              )}
            </span>
          </p>
        )}
      </div>
    </>
  );
};
