import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiStepForm, Step } from "react-multi-form";
import { Modal } from "antd";
import {
  CreateShiftBackButton,
  CreateShiftNextButton,
} from "../Employees/Forms/Components/Butttons";
import { showFaliureToast, unixToDateFormater } from "../../Utilities";
import { addMandatoryLeaves } from "../../Redux/reducers/CalenderReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import MandatoryDetails from "./MandatoryDetails";
import AssignEmployee from "../Staffing/AssignEmployee";
import AppConstants from "../../Helpers/AppConstants";
import AssignEmployeeMandatory from "../Staffing/AssignEmployeeMandatory";
import { employeesRequest } from "../../Redux/reducers/EmployeesReducer";
export default function ShiftsModal({
  employees,
  createShift,
  shiftDetails,
  onCancel,
  modalStatus,
  currentStaffingId,
  created,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { roles } = useSelector((state) => state.settings);

  const [currentStep, setCurrentStep] = useState(1);
  const [shiftData, setShiftData] = useState({
    rotaId: "",
    currentUserId: "",
    shiftId: "",
    assignedWorker: "",
    shiftDay: "",
    shiftTimeStart: "",
    shiftTimeEnd: "",
    breakDuration: "",
    notes: "",
    assignedWorkers: [],
    ids: [],
  });
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [mandatoryDetails, setMandatoryDetails] = useState({
    leaveName: "",
    leaveStartDate: "",
    leaveEndDate: "",
    assignEmployee: "no",
    deductEmployee: "no",
    notes: "",
  });

  const [allEmployees, setAllEmployees] = useState([]);
  const [rolesWithEmployees, setRolesWithEmployees] = useState([]);

  useEffect(() => {
    getAllEmp();
  }, []);

  useEffect(() => {
    if (employees) {
      setAllEmployees(employees);
      handleEmployeeRoles();
    }
  }, [employees]);

  useEffect(() => {
    if (shiftDetails) {
      setShiftDataHandler();
    }
  }, [shiftDetails]);

  useEffect(() => {
    if (currentStaffingId) {
      setShiftData({ ...shiftData, rotaId: currentStaffingId });
    }
  }, [currentStaffingId]);
  const getAllEmp = () => {
    dispatch(
      employeesRequest({
        token,
        type: "list",
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at employeesRequest", response);
      })
      .catch((err) => {
        Applogger("Error at employeesRequest", err.message);
      });
  };
  const handleEmployeeRoles = () => {
    var finalArray = [];
    roles.forEach((item) => {
      var employeesData = employees.filter(
        (emp) => emp.role_id == item.role_id
      );
      finalArray.push({
        ...item,
        employees: employeesData,
      });
    });

    setRolesWithEmployees(finalArray);
  };

  const setShiftDataHandler = () => {
    let currentEmployeData = null;
    const {
      break_duration,
      end_time,
      notes,
      rota_id,
      start_time,
      user_id,
      shift_id,
      shift_day,
    } = shiftDetails;
    if (allEmployees.length > 0) {
      let CurrentEmployeeIndex = allEmployees.findIndex(
        (val) => val.user_id === shiftDetails.user_id
      );
      currentEmployeData = allEmployees[CurrentEmployeeIndex];
    }

    const userId = get(currentEmployeData, "user_id", "");
    if (shiftDetails) {
      setShiftData({
        ...shiftData,
        rotaId: rota_id,
        currentUserId: userId,
        shiftId: shift_id,
        assignedWorker: userId,
        shiftTimeStart: start_time && unixToDateFormater(start_time, "hh:mm"),
        shiftTimeEnd: end_time && unixToDateFormater(end_time, "hh:mm"),
        notes: notes && notes,
        breakDuration: break_duration && break_duration,
        shiftDay:
          shift_day &&
          unixToDateFormater(shift_day, AppConstants.defaultDateFormat),
      });
    }
  };

  const onBackClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateShiftDetails = () => {
    if (
      mandatoryDetails.leaveName == "" ||
      mandatoryDetails.leaveStartDate == "" ||
      mandatoryDetails.leaveEndDate == "" ||
      mandatoryDetails.assignEmployee == "" ||
      mandatoryDetails.deductEmployee == ""
    ) {
      showFaliureToast(t("pleaseSelectAllFields"));
    } else {
      setCurrentStep(2);
    }
  };

  const addMandatoryLeavesHandler = () => {
    try {
      dispatch(
        addMandatoryLeaves({ token, selectedEmployees, mandatoryDetails })
      )
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at addMandatoryLeaves", res);
          created();
          onCancelHandler();
        })
        .catch((err) => {
          Applogger("Error at addMandatoryLeaves", err);
          // if (err == "Request failed with status code 400") {
          showFaliureToast(err ?? "Mandatory Leaves Not Created");
          // }
        });
    } catch (err) {
      Applogger("Error at addMandatoryLeavesHandler", err);
    }
  };
  const validateSelectedEmployees = () => {
    if (selectedEmployees.length > 0) {
      addMandatoryLeavesHandler();
    } else {
      showFaliureToast("Please Select Employee");
    }
  };
  const employeeSelectHandler = (val) => {
    setSelectedEmployees(val);
  };

  const onCancelHandler = () => {
    onCancel();
    // setSelectedEmployees([])
    setMandatoryDetails({
      leaveName: "",
      notes: "",
      assignEmployee: "no",
      deductEmployee: "no",
    });
    setCurrentStep(1);
  };

  const onNextClick = () => {
    if (currentStep === 1) {
      // validateEmployeeType();
      validateShiftDetails();
    } else if (currentStep === 2) {
      // validateEmployeeDetails();
      validateSelectedEmployees();
    } else if (currentStep === 3) {
      // validateWorkingHours();
      createShift(shiftData);
    }
  };

  return (
    <Modal
      title={t("addMandatoryLeave")}
      centered
      destroyOnClose={true}
      onCancel={onCancelHandler}
      visible={modalStatus}
      width={900}
      footer={false}
    >
      <div className="px-5">
        <MultiStepForm
          className={"sda"}
          accentColor={"#3DB0F7"}
          activeStep={currentStep}
        >
          <Step label={t("createLeave")}>
            <MandatoryDetails
              mandatoryDetails={mandatoryDetails}
              setMandatoryDetails={setMandatoryDetails}
            />
          </Step>
          <Step label={t("assignEmployees")}>
            <AssignEmployeeMandatory
              rolesWithEmps={rolesWithEmployees}
              employeeSelect={employeeSelectHandler}
              employees={allEmployees.length > 0 ? allEmployees : []}
            />
          </Step>
        </MultiStepForm>
      </div>
      <div className="d-flex  gap-2 my-4" style={{ marginLeft: "3.5rem" }}>
        {currentStep !== 1 && (
          <CreateShiftBackButton
            onClick={() => onBackClick()}
            title={t("back")}
          />
        )}
        {currentStep !== 2 && (
          <CreateShiftNextButton
            onClick={() => onNextClick()}
            title={t("assignEmployees")}
          />
        )}
        {currentStep === 2 && (
          <CreateShiftNextButton
            onClick={() => onNextClick()}
            title={t("submit")}
          />
        )}
        <CreateShiftBackButton onClick={onCancelHandler} title={t("cancel")} />
      </div>
    </Modal>
  );
}
