import { isUaeRegion } from "../Utilities/index";

const AppConstants = {
  routes: {
    main: "/",
    dashboard: "/dashboard",
    resetPassword: "/reset-password",
    changePassword: "/change-password",
    privacyPolicy: "/privacy-policy",
    calendar: "/calendar",
    employees: "/people",
    staffing: "/schedules",
    timeSheet: "/timesheet",
    timeSheetHistory: "/timesheet-history",
    onBoarding: "/onboarding",
    terms: "/terms-and-conditions",
    absence: "/absence",
    staffingSheet: "/schedules-sheet",
    analytics_details: "/analytics-details",
    profile_log_overtime: "/profile/log-overtime",
    analytics: "/analytics",
    settings: "/settings",
    documents: "/documents",
    sharedDocuments: "/shared-documents",
    login: "/login",
    forgot_password: "/forgot-password",
    profile: "/profile",
    invalid_page: "*",
  },
  clockStates: {
    in: "IN",
    out: "OUT",
  },

  dateConsts: {
    localStartDate: "LocalStartDate",
    localEndDate: "LocalEndDate",
  },
  reportsType: {
    summary: "summary",
    detailed: "detailed",
  },
  timePickerTypes: {
    startTime: "startTime",
    endTime: "endTime",
    breakTime: "breakTime",
    format: "HH: mm",
  },
  holidayTypes: {
    deducted: "Deducted",
    notDeducted: "Not Deducted",
    WorksPublicHolidays: "Works Public Holidays",
  },
  errorMessages: {
    invalidEmail: "Request failed with status code 400",
    invalidEmailMsg: "Please enter valid email address",
    userNotExist: "Request failed with status code 404",
    userNotExistMsg: "Login Failed. Kindly Add Correct Credentials",
  },
  absenceTypesNames: {
    sickness: "Sickness Absence",
    lateness: "Lateness",
    toil: "TOIL",
    annualLeave: "Annual Leave",
    mandatoryLeave: "Mandatory Leave",
    dependentLeave: "Dependents Leave",
  },
  dateFormat: "ddd DD MMM YYYY",
  dateFormatWithTimeAM: "ddd DD MMM yyyy hh:mm:ss A",
  dateFormatWithTime: "ddd DD MMM yyyy HH:mm:ss",
  dateformat2: "DD/MM/yyyy HH:mm",
  defaultDateFormat: "DD/MM/YYYY",
  defaultDateFormatDashed: "DD-MM-YYYY",
  defaultDateFormatMandatoryLeave: "YYYY-MM-DD",
  monthYearFormat: "MMMM YYYY",
  timeFormat: "HH:mm",
  roleTypes: {
    Owner: "Owner",
    Admin: "Owner",
  },
  userRoleTypes: {
    hours: "hours",
    daily: "days",
  },
  workingPattersTypes: {
    hours: "working_hour_patterns",
    daily: "working_time_patterns",
  },
  previlages: {
    weeklyCountCard: "weeklyCountCard", // new
    homeSummary: "homeSummary",
    otherCards: "otherCards",
    createOthersAnnualLeave: "createOthersAnnualLeave",
    listAnnualLeavesCalendar: "listAnnualLeavesCalendar", // new
    createAnnualLeave: "createAnnualLeave", // updated
    calenderUpdateDeleteLeave: "calenderUpdateDeleteLeave", // updated
    showPendingTab: "showPendingTab", //
    showMandatoryTab: "showMandatoryTab",
    manageMandatoryLanguage: "manageMandatoryLanguage",
    ApproveDeclinePendingLeave: "approveAmendAndDeclineLeaveRequests", // new
    listPublicLeaves: "listPublicLeaves", // new
    listDeclined: "listDeclined", // updated
    listEmployees: "listEmployees", // updated
    viewOtherProfile: "viewOtherProfile", // new
    createEmployee: "createEmployee", // new
    clockInOutBreak: "clockInOutBreak", // new
    ClockInOutOtherUsers: "ClockInOutOtherUsers", // new
    listTimesheetHistory: "listTimesheetHistory", // updated
    listOthersTimesheetHistory: "listOthersTimesheetHistory", // updated
    listCompanyDocs: "listCompanyDocs", // updated
    uploadCompanyFile: "uploadCompanyFile", // updated
    createCompanyFolder: "createCompanyFolder", // updated
    updateDeleteCompanyFile: "updateDeleteCompanyFile", // new
    generateAbsenceReport: "generateAbsenceReport", // updated
    generateHolidayReport: "generateHolidayReport", // updated
    generateTimesheetReport: "generateTimesheetReport", // updated
    generateEmployeeReport: "generateEmployeeReport", // updated
    generatePayrollReport: "generatePayrollReport", // updated
    updateCompanyProfile: "updateCompanyProfile", // updated
    enableCreateWorkTimePattern: "createWTP", // updated
    listWTP: "listWTP", // updated
    updateDeleteWTP: "updateDeleteWTP", // new
    listLocation: "listLocation", // updated
    createLocation: "createLocation", // updated
    updateDeleteLocation: "updateDeleteLocation", // new
    listRoleSetting: "listRoleSetting", // new
    createRoleSetting: "createRoleSetting", // new
    UpdateDeleteRoleSetting: "UpdateDeleteRoleSetting", // new
    updateSalarySensitiveInfo: "updateSalarySensitiveInfo", // new
    UpdateContractInfo: "UpdateContractInfo", // updated
    updateRoleInfo: "updateRoleInfo", // updated
    updateEmployeeRoleLocationCountry: "updateEmployeeRoleLocationCountry", // new WIP add after design is created
    updateContactInfo: "updateContactInfo", // new
    absenceRecordList: "absenceRecordList", // new
    listOvertimeLog: "listOvertimeLog", // new
    createOvertimeLog: "createOvertimeLog", // updated
    updateDeleteOvertimeLog: "updateDeleteOvertimeLog", // new
    listPersonalDocs: "listPersonalDocs", // updated
    uploadPersonalFile: "uploadPersonalFile", // updated
    createPersonalFolder: "createPersonalFolder", // updated
    updateDeletePersonalFile: "updateDeletePersonalFile", // new
    activeCreateStaffingBtn: "activeCreateStaffingBtn", // new
    listStaffing: "listStaffing", // new
    viewStaffing: "viewStaffing", // updated
    editStaffing: "editStaffing", // updated
    printStaffing: "printStaffing", // updated
    statusChangeStaffing: "statusChangeStaffing", // updated
    copyStaffing: "copyStaffing", // updated
    deleteStaffing: "deleteStaffing", // updated
    listShift: "listShift", // updated
    createShift: "createShift", // updated
    updateDeleteShift: "updateDeleteShift", // new
    assignEmployee: "assignEmployee", // new
    staffingSummary: "staffingSummary", // updated
    listSharedDocuments: "listSharedDocuments", // updated
    createModifySharedDocuments: "createModifySharedDocuments", // updated
  },
  addressOptions: {
    // componentRestrictions: { country: "uk" },
    componentRestrictions: { country: isUaeRegion() ? "ae" : "uk" },
    fields: [
      "address_components",
      "geometry",
      "icon",
      "name",
      "formatted_address",
    ],
  },
  pagination: {
    size: 10,
  },
  workingStatus: {
    any: "any",
    working_from_home: "working_from_home",
    at_another_site: "at_another_site",
    on_client_visit: "on_client_visit",
    working_from_usual_location: "working_from_usual_location",
    not_working: "not_working",
  },
  absenceTypes: {
    time_off: "time_off",
    annual_leave: "annual_leave",
    sickness_absence: "sickness_absence",
    toil: "toil",
    lateness: "lateness",
    bereavement_leave: "bereavement_leave",
    compassionate_leave: "compassionate_leave",
    dependents_leave: "dependents_leave",
    maternity_paternity_leave: "maternity_paternity_leave",
    other_authorised_paid_leave: "other_authorised_paid_leave",
    other_authrised_unpaid_leave: "other_authrised_unpaid_leave",
    unauthorised_leave: "unauthorised_leave",
    mandatory_leave: "mandatory_leave",
    public_holidays: "public_holidays",
  },
  previlagesModules: {
    dashboard: "homepage",
    calendar: "calendar",
    peopleHub: "employees_hub",
    timesheet: "timesheet",
    schedules: "staffing",
    documents: "company_docs",
    analytics: "reporting",
    settings: "settings",
    profile: "profile",
    sharedDocuments: "shared_documents",
  },
  documentTypes: {
    company: "all",
    shared: "shared",
    personal: "personal",
  },
  metersRange: 500,
  placeOfWorkArray: [
    {
      work_place_id: "works_from_home",
      name: "Works From Home",
      // location_type_name: "Works From Home",
    },
    {
      work_place_id: "works_from_client_location",
      // location_type_name: "Works From Client Location",
      name: "Works From Client Location",
    },
  ],
  maxDate: "2050-01-01",
  copyStaffingInitialState: {
    staffingName: "",
    staffingStartDate: "",
    duration: "",
  },
};

export default AppConstants;
