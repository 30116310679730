import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Welcome to React": "Welcome to React and react-i18next",
          name: "Name",
          acceptableRadius: "Acceptable Radius (Meters)",
          selectroles: "Select Roles",
          hoursSpent: "Hours spent this week",
          description: "Description",
          selectedWorkingHour: "Selected Days Total Hours",
          invalidReportType: "Invalid Report Type",
          //Dashboard Screen
          summaryText: "My Absence Summary",
          timesheetHistory: "Timesheet History",
          whoseIn: "Who is in?",
          directorStatus: "Director Status",
          nonDirector: "Non-Director",
          directorAnnualBasis: "Director (Annual basis)",
          directorProRataBasis: "Director (Pro-rata basis)",
          employementTypes: "Employment Type",
          temporary: "Temporary",
          contractor: "Contractor",
          expatriate: "Expatriate",
          initials: "Initials",
          dateDirectorshipBegan: "Date Directorship Began",
          maritalStatus: "Marital Status",
          single: "Single",
          married: "Married",
          civilPartnership: "Civil Partnership",
          divorced: "Divorced",
          widowed: "Widowed",
          other: "Other",
          phoneNumber: "Phone Number",
          bankDetails: "Bank Details",
          fieldsMarkedAs: "Note: Fields marked as",
          areMandatory: "are mandatory",
          bankAccount: "Bank Account",
          buildingSociety: "Building Society Account",

          bankAccountNumber: "Bank Account Number",
          bankAccountName: "Account Holder Name",
          bankAccountType: "Bank Account Type",
          buildingSocNumber: "Building Soc Number",
          BASCReference: "BACS Reference",
          bankName: "Bank Name",
          bankPostCode: "Bank Post Code",
          bankAddress: "Bank Address ",
          postCode: "Post Code",
          contractDetails: "Contract Details",

          userAddress: "User Address",
          employeeReference: "Employee Reference",
          worksReference: "Work Reference",
          workEndDate: "Work End Date",
          niCategory: "NI Category",
          wk1mth1Basis: "Week1/ Month1 Basis",
          paymentFrequency: "Payment Frequency",
          grossSalary: "Gross Salary",
          sortCode: "Sort Code",
          optionsFor: "Options For",
          commaException: "Do not use , (comma) in the text",
          payslipEmailAddress: "Payslip Email Address",
          payslipPassword: "Payslip Password",
          nonUKWorker: "Non UK Worker",
          rightToWorkConfirmStatus: "Right To Work Confirm Status",
          rightToWorkDocumentType: "Right To Work Document Type",
          rightToWorkDocumentExpiryDate: "Right To Work Document Expiry Date",
          rightToWorkReference: "Right To Work Reference",

          emergencyContact: "Emergency Contact",
          contactPermission: "Contact Permission",
          contactTelephoneNo: "Contact Telephone No",
          contactRelationship: "Contact Relationship",

          ukPassport: "UK Passport",
          euPassport: "EU Passport",
          euNationalIDCard: "EU National ID Card",
          biometricResidencePermit: "Biometric Residence Permit",
          nonEEAPassportWithEndorsement: "Non EEA Passport With Endorsement",
          eeaResidencePermit: "EEA Residence Permit",
          otherHOIssuedDocument: "Other HO Issued Document",
          birthAdoptionCertificationPlusNINO:
            "Birth Adoption Certification Plus NINO",
          certificateOfRegistration: "Certificate Of Registration",
          hoPositiveVerificationNotice: "HO Positive Verification Notice",
          pvnPlusARC: "PVN Plus ARC",
          pvnPlusCoA: "PVN Plus CoA",
          euOther: "EU Other",

          spouse: "Spouse",
          parent: "Parent",
          sibling: "Sibling",
          child: "Child",
          friend: "Friend",

          radiusInfo:
            "The radius will be used for GPS tracking of users clocking in and clocking out",
          clockInFrom: "Clocked In From:",
          clockOutFrom: "Clocked Out From:",
          allownDesc:
            "Leave allowance should only contain whole number or .5 in decimal",
          noClockedInUsersMsg: "There are no Clocked in users",
          noUpcomingHolidaysMsg: "There are no upcoming public holidays",
          upcomingHolidaysMsg: "Upcoming public holidays",
          passValidationMsg:
            "Password must contain at least 1 capital letter, 1 number, and 1 special character",
          locationPermission:
            "The system would like to locate your working location, Please allow location permissions",
          clearFilters: "Reset",
          resetPassword: "Reset Password",
          locationPermissionMsg: "Please allow location permission to view Map",
          roleType: "Role Type",
          viewPeople: "People",
          createWTPTitle: "Create Work Time Pattern",
          updateWTPTitle: "Update Work Time Pattern",
          nothingToUpdate: "There is nothing to update",
          viewOnMap: "View on Google Map",
          previlegeNotFoundTitle: "Found Unauthorized User ",
          previlegeNotFoundDesc:
            "You are not authorized to use this feature, Please contact your administrator",
          welcome: "My Absence Summary",
          reqTimeOff: "Request Time Off",
          remaining: "Remaining",
          selectWorkPlaceDesc: "Select Workplace",
          selectCountryDesc: "Select Country",
          selectRoleDesc: "Select Role",
          days: "Days",
          cancelBulkUpload: "Cancel Bulk Upload",
          allowance: "Allowance",
          nextUp: "Next up",
          noLeaveFound: "No Upcoming Leave",
          timeOff: "Time off",
          taken: "Taken",
          youHave: "You have",
          uploadpeopledata: "Upload People Data",
          overtimeClaimsApproved: "overtime claims approved",
          youAlsoTaken: "You've also taken",
          lateness: "Lateness",
          sickness: "Sickness",
          otherLeave: "Other Leave",
          thisWeek: "This Week",
          addTimeOff: "Add Timeoff",
          myInfo: "My Information",
          workingStatus: "Working status",
          annualLeave: "Annual Leave",
          sicknessAbsence: "Sickness Absence",
          reporting: "Reporting",
          calendar: "Calendar",
          finish: "Finish",
          employeeHub: "People's Hub",
          //
          absenceReport: "Absence Report",
          annualLeaveReport: "Annual leave Report",
          timeSheetReport: "Timesheet Report",
          empDetailReport: "People Details Report",
          //AddTimeOff
          fullDay: "Full Day",
          halfDay: "Half Day",
          end: "End Time: ",
          min: "Mins",
          hoursDeducted: "Hours Deducted",
          addAbsence: "Add Absence",
          employee: "People",
          absenceType: "Absence type",
          noWorktimePattern:
            "Please ask your administrator to assign you working time pattern",
          notes: "Notes",
          backToProfile: "Back to Profile",
          //Calendar
          requestLeave: "Request Leave",
          clickToEdit: "Click to edit",
          sync: "Sync",
          allTeams: "All teams",
          myTeams: "My teams",
          creatingTeams: "Creating teams",
          byType: "By type",
          all: "All",
          nameOfMendatoryLeaves: "Name of mandatory leave",
          leaveDate: "Leave Date",
          annualLeaveDeduction: "Deduct from annual leave entitlement",
          yes: "Yes",
          recordingThisElsewhere: "I am recording this elsewhere",
          no: "No",
          noCalendarData: "Click see more to view calendar activities",
          mandatoryLeave: "Mandatory Leave",
          publicHolidays: "Public Holidays",
          addMandatoryLeave: "Add Mandatory leave",
          year: "Year",
          publicHolidaysDesc:
            "Public holidays include New Year's Day, Independence Day, Labor Day, Christmas, Easter, and Thanksgiving. However, the specific holidays celebrated and their significance can vary greatly across different countries and cultures.",
          employeeHolidaysDates:
            "Dates your employees have to take as leave such as shutdown periods, local bank holidays etc. Once set, it will appear on people profile as Mandatory time off as well as in the calendar.",
          // employeeannualLeaveDeductions:
          //   "Deducted from annual leave entitlement for fixed employees only. Leave entitlement of variable hours employees will not be affected",
          employeeannualLeaveDeductions:
            "Deducted from annual leave entitlement",
          request: "Request",
          shouldIapprove: "Should I approve?",

          leaveYearPendingRequests: " is pending",
          exceedAllowance: "",
          noConflicts: "",
          approve: "Approve",
          decline: "Decline",
          //CovidEmployee
          ourCallService:
            "Need help with this? Don't worry, our expert advisors are available 24/7 to take your call. Simply give them a ring on",
          option: "option",
          generateAReport: "Generate a report",
          goto: "Go to",
          vaccinated: "Vaccinated",
          name: "Name",
          vaccineOne: "Vaccine one",
          vaccineTwo: "Vaccine two",
          booster: "Booster",
          pass: "pass",
          vaccTrakPass: "What is the VaccTrak pass used for?",
          employeeVaccination:
            "You can issue a VaccTrak pass to each people once they've been vaccinated. SpeedyHR sends the pass, which holds details of your people's vaccination, straight to their email address. Your employees can then use it to move freely around your workplace, visit client sites, or for their own personal records. This is a particularly useful tool for businesses in the healthcare, hospitality or construction industries. Government guidelines should be followed where they are applicable.",
          employeeVaccinationStatus:
            "People with Pending or No vaccination status",
          online: "online",
          pending: "Pending",
          exempt: "Exempt",
          preferNotSay: "Prefer not say",
          notSpecified: "Not Specified",
          addNotes: "Add notes",
          employeeNotes: "Notes are visible to people",
          save: "Save",
          //CustomModal
          close: "Close",
          deleteStaff: "Delete Staff",
          confirmStaffDeletion:
            "Are you sure you want to permanently delete the staff",
          continueSetup: "Continue Setup",
          withStartDate: "with start date",
          workStartDate: "Work Start Date",
          allocatedEmployees: "All allocated employees will be removed",
          delete: "Delete",
          cancel: "Cancel",
          selectAll: "Select All",
          renameStaff: "Rename Staff",
          update: "Update",
          //DashboardCards/BigCard
          new: "New",
          addBusiness:
            "Add your business to Speedy Exchange and start connecting to new customers today.",
          namePlaceholder: "Enter name to search ...",
          jobTitlePlaceholder: "Name, Job, Title, Team",
          myDocuments: "My Documents",
          companyDocuments: "Company documents",
          sharedDocuments: "Shared Documents",
          companyDetails: "Company Details",
          whoClockedIn: "See who's clocked in",
          standstormFissure: "Fissure in Sandstone",
          //OverViewCard
          working_from_home: "Working From Home",
          changePassToast:
            "Password changed successfully, Please login with new credentials",
          at_another_site: "At Another Site",
          on_client_visit: "On Client Visit",
          not_working: "Not Working",
          working_from_usual_location: "Working From Usual Location",
          seeMore: "See More",
          // Overtime
          logOvertime: "Log overtime",
          overTimeHistory: "Overtime History",
          timeOffTaken: "Time Off Taken",
          //Documents
          allFolders: "All Folders",
          folder: "Folder",
          file: "file",
          any: "any",
          fulltime: "Full Time",
          documentCreatedSuccessToast: "Document Created Successfully",
          documentCreatFailToast: "Document Creation Failed",
          fileUploadToast: "File Upload succesfully",
          fileUploadFail: "File Upload Failed",
          downloadunsuccess: "Download Unsucessfull",
          folderDeleted: "Folder Deleted Successfully",
          folderDeleteFail: "Folder Deletion Failed",
          pleaseEnterValidName: "Please enter valid name",
          folderNameUpdate: "Folder name updated successfully",
          unableToUpdateFolder: "Unable to update folder name",
          searchFiles: "Enter text here...",
          search: "Search",
          createNewFolder: "Create New Folder",
          folderName: "Folder Name",
          folderAlreadyExists: "Folder Already Exists",
          create: "Create",
          fileAlreadyExist: "File Already exists please Rename it",
          upload: "Upload",
          newFolder: "New folder",
          type: "Type",
          dateModified: "Date Modified",
          edit: "Edit",
          noFilesFolderFound: "No Files/ Folders Found",
          noFilesFound: "No Documents Found",
          enterNewName: "Enter new name",
          find: "Find",
          recommendedImage: "Recommended image size 1000 X 1000 (5 MB)",
          sortBy: "Sort By",
          firstName: "First Name",
          status: "Status",
          noStatusSet: "Any",
          //DoneAddEmployee
          addedToSpeedyHR: "has been added to SpeedyHR",
          richerProfile:
            "There are some things you can do to make a richer profile for",
          add: "Add",
          oneOrMoreTeams: "to one or more teams",
          addJobTitleOrRole: "Add job title or role",
          addEmergencyContacts: "Add emergency contacts",
          addAProfilePicture: "Add a profile picture",
          goToProfile: "Go to Profile",
          addAnotherEmployee: "Add Another Person",
          //EmployeeType
          fixedFullOrPartTime: "Fixed, full or part time",
          repeatedWorkingTimePattern:
            "People on a repeating working time pattern who work fixed, predictable number of hours every week and have a fixed.",
          shortHoursOrVariable: "Short hours or variable",
          employeesOnContract:
            "People on a contract who work a different number of hours or days from week to week (but not zero-hours contracts) ",
          typeOfEmployee: "Please select the type of people",
          //HoursEmployeeDetails
          email: "Email",
          startDate: "Start Date",
          //HoursSummary
          employeeStartDate: "Start Date",
          leaveYearStartDate: "Leave Year Start/End Date",
          fullTimeAnnualLeaveEntitlement:
            "Company's full time annual leave entitlement",
          allowanceForLeaveYear: "Allowance for leave year",
          annualLeaveEntitlement: "Annual leave entitlement",
          aboutToAdd: "You're about to add",
          toCompany: "to the company.",
          summary: "Summary",
          workingTimePattern: "Working Time Pattern",
          workingTimesList: "Unable to get working times list",
          contractedHoursPerWeek: "People contracted hours per week",
          anAverageWorkingDay: "An average working day",
          workingWeekExcludingBreaks:
            "Company's working week, excluding breaks",
          publicHolidaysObserved: "Public holidays observed for",
          selectCountry: "Select Country",
          selectRole: "Select Role",
          selectWorkPlace: "Select Workplace",
          hours: "hours",
          hrs: "Hrs",
          //AddEmployee
          invalidUser: "Found Invalid User",
          invalidUserTypeFound: "Found invalid user type please select again",
          employeeCreated: "People Created Successfully",
          emailAlreadyExist: "Email already exist",
          invalidEmailAddress: "Invalid email address",
          enterValidEmailAddress: "Please enter valid email address",
          pleaseSelectCountry: "Please select country",
          selectEmployeeType: "Please select people type",
          employeeType: "People Type",
          employeeDetails: "People Details",
          workingHours: "Working Hours",
          roleAndLocation: "Role & Location",
          selectRoleAndLocation: "Select Role & Location",
          done: "Done",
          back: "Back",
          nextStep: "Next Step",
          submit: "Submit",
          updateDocument: "Update Document",
          regenerateDocuments: "Regenerate Documents",
          //AddHourlyEmployee
          fileDownloaded: "File Downloaded Successfully",
          userCreated: "User Created Successfully",
          useCorrectFormatOfCsv: "Kindly use correct format of CSV",
          uploadEmployees: "Upload People",
          //EmployeeCard
          viewFullProfile: "View Full Profile",
          setStatus: "Set Status",
          viewAnalytics: "View Analytics",
          addANewEmployee: "Add New Person",
          addMultipleEmployees: "Add Multiple People",
          //MultipleEmployeesCSV
          downloadCsvTemplateFromAbove: "Download CSV template from above",
          fileShouldBeInCsvFormat: "File should be in CSV format",
          emailShouldBeUnique: "Email should be unique",
          useCorrectCsvTemplate:
            "Make sure you use correct CSV template for variable hours and daily employees",
          makeSureYouUse: "Make sure you use",
          formatInDates: "format in dates",
          hourlyUserType: "User type for variable hours users is 0",
          dailyUserType: "User type for daily users is 1",
          workingStatusUpdated: "People Working Status Updated Successfully",
          failedToUpdateStatus: "Failed To Update People Working Status",
          noOfEmployees: "No of People",
          noEmployeeFound: "No People Found",
          //Headers
          quickActions: "Quick Actions",
          addAnnualLeave: "Add Annual Leave",
          addSicknessAbsence: "Add Sickness Absence",
          addTimeoff: "Add Toil",
          addLateness: "Add Lateness",
          staffTeams: "STAFF & TEAMS",
          addEmployees: "Add People",
          addEmployee: "Add People",
          viewPublishedSchedule: "View Published Schedules",
          bulkAddEmployees: "Add People In Bulk",
          dashboard: "Dashboard",
          myEmployees: "People Hub",
          timesheet: "Timesheet",
          staffing: "Schedules",
          companyDocs: "Documents",
          analytics: "Analytics",
          settings: "Settings",
          menu: "Menu",
          youHaveNoNotifications: "You have no notifications",
          myAccount: "My Account",
          logout: "Logout",
          employees: "People",
          timeSheet: "TimeSheet",
          //ManageTeams/index
          addANewTeam: "Add a new team",
          enterTeamName: "Please enter a team name...",
          selectEmployees: "Select People",
          myMarker: "My Marker",
          //Profile/AbsenceRecord
          overTimeLog: "OverTime Log",
          imageUploaded: "Profile Image uploaded successfully",
          imageUploadFailed: "Profile Image upload failed",
          addStatus: "Add Status",
          resendRegistrationEmail: "Resend Registration Email",
          resendUserRegistrationEmail: "Resend user registration email",
          invite: "Invite",
          resend: "Resend Invitation",
          editStatus: "Edit Status",
          clearStatus: "Clear Status",
          aboutToRemove: "You are about to remove this",
          confirmation: "record. Are you sure you want to do this?",
          yesRemove: "Yes, remove",
          allAbsences: "All Absences",
          occurrence: "Occurrence",
          documentsRegeneratedSuccessfully:
            "Documents Regenerated Successfully",
          unableToRegenerateDocument: "Unable to regenerate documents",
          logged: "Logged",
          total: "Total",
          requestLateness: "Request Lateness",
          requestToil: "Request Toil",
          currentFuture: "Current & Future",
          absenceHistory: "Absence History",
          //EmergencyForm
          lastName: "Last Name",
          homePhone: "Home Phone",
          mobilePhone: "Mobile Phone",
          workPhone: "Work Phone",
          country: "Country",
          workPlace: "Workplace",
          pleaseSelect: "Please Select",
          address: "Address",
          address1: "Address 1",
          address2: "Address 2",
          address3: "Address 3",
          townCity: "Town/City",
          postcode: "Postcode",
          relationship: "Relationship",

          partner: "Partner",
          relative: "Relative",
          primaryContact: "Primary Contact",
          addEmergencyContact:
            "Add at least one emergency contact in case something unexpected happens.",
          addNewContact: "Add new contact",
          //ContractInfoUserEmployement
          annualLeaveInformation: "Contract Summary, Role & Place of Work",
          contractedHoursOfWork:
            "Joining Date, Termination Status, Role and Place of Work information",
          currentAnnualLeaveAllowance: "Current annual leave allowance",
          annualLeaveAllowance: "Annual Leave Allowance",
          contractSummary: "Contract Summary",
          contractWeek: "Contract Week",
          contractStartDate: "Current Contract",
          annualLeaveYearStart: "Annual leave year start",
          minLeaveEntitlement: "Min. leave entitlement",
          placeOfWork: "Place of Work",
          workingLocation: "Working Location",
          holidaysAndLeaveTypes: "Determines public holidays and leave types",
          // EditContractModal
          editContractInfo: "Edit Contract Info",
          selLeaveType: "Select Leave Type",
          contractInfo: "Contract Info",
          workHoursPerWeek:
            "Works 35 hrs over 5 days per week. Public holidays deducted from entitlement.",
          companyWorkingWeek: "Company's working week, excluding breaks",
          annualLeaveYearStartDate: "Annual leave year start date",
          minAnnualLeaveEntitlement: "Min. annual leave entitlement",
          publicHolidaysFor: "Public Holidays for",
          fullTimeWorkingWeek: "Full time working week",
          companyFullTimeAnnualLeave: "Company's full time annual leave",
          empWorkingTimePattern: "People's Working time pattern",
          //EditRoleModal
          fullTime: "Full-Time",
          partTime: "Part-Time",
          fixedTerm: "Fixed-Term",
          weeks: "Weeks",
          months: "Months",
          additionalResponsibilities: "Additional responsibilities",
          adjustment: "Adjustment",
          other: "Other",
          noNoticePeriod: "No Notice Period",
          period: "Period",
          editRoleInfo: "Edit Role Info",
          editRoles: "Edit Roles",
          roleInfo: "Role Info",
          jobTitle: "Job Title",
          contract: "Contract",
          notifyOfExpiryOn: "Notify of expiry on",
          probationPeriod: "Probation Period",
          noProbationPeriod: "No Probation Period",
          probationPeriodEndDate: "Probation period end date",
          noticeProbationPeriod: "Notice required during probation period",
          reasonForUpdate: "Reason for update",
          resetForm: "Reset Form",
          //EditSummaryModal
          demotion: "Demotion",
          editRoleInformation: "Edit role information",
          porbationRequired: "Porbation Required",
          noticePeriod: "Notice period",
          roleInformation: "Role information",
          probationAndNoticePeriod: "Job title, probation and notice period",
          contractType: "Contract type",
          fillInTheFormBelow: "Fill in the form below",
          salaryInformation: "Salary Information",
          salaryAmountAndPaymentFrequency:
            "Salary amount and payment frequency",
          payrollNumber: "Payroll number",
          salary: "Salary",
          salaryPerPeriod: "Salary Per Period",
          selectFrequency: "Select frequency",
          autoEnrExReason: "Auto Enrolment Exclusion Reason",
          yearly: "Yearly",

          noExclusion: "No Exclusion",
          directorWithoutECon: "Director without employment contract",
          nonSalariedLimited: "Non-salaried limited liability partner",
          maximumAnnualContribution: "Maximum annual contribution reached",
          receivedWindingUp: "Received winding-up lump sum",
          workingNoticePeriod: "Working Notice Period",
          firstDayCivilianEmp: "First Day of Civilian Employment",
          fortnightly: "Fortnightly",
          fourWeekly: "Four Weekly",
          monthly: "Monthly",
          annually: "Annually",
          weekly: "Weekly",
          daily: "Fixed Hours",
          hourly: "Variable Hours",
          pension: "Pension",
          pensionEligible: "Pension Eligible?",
          optedOut: "Opted out?",
          employeeContribution: "People contribution",
          employerContribution: "Employer contribution",
          enrolmentDate: "Enrollment date",
          //SensitiveInfoUserEmployement
          sensitiveInformation: "Sensitive Information",
          eligibilityInformation: "Tax, NI and eligibility information",
          tax: "Tax",
          taxCode: "Tax Code",
          niNumber: "NI Number",
          niNumberPH: "NI Number e.g QQ 12 34 56",
          rightToWork: "Right To Work",
          documentVerified: "Right To Work Document Verified",
          dateOfExpiry: "Licence Expiry Date",
          drivingLicence: "Driving Licence Number",
          licenceNumber: "Licence Number",
          updatedSuccessfull: "Updated Successfull",
          salaryInfoUpdatedSuccessfully: "Salary Info Updated Successfully",
          salaryInfoUpdateFailed: "Salary Info Update Failed",
          updateFailed: "Update Failed",
          //LogOverTime
          logUpdatedSucessfully: "Log Updated Successfully",
          updateLogFailed: "Update Log Failed",
          logCreatedSucessfully: "Log Created Successfully",
          createLogFailed: "Create Log Failed",
          overtimeStart: "Overtime Start",
          overtimeEnd: "Overtime End",
          overtimeLength: "Overtime length",
          payRate: "Pay rate",
          //OverTimeCancelModal
          cancelOvertime: "Cancel Overtime",
          permanentAction: "This is a permanent action and cannot be undone.",
          reason: "Reason",
          pleaseEnterReason: "Please enter a reason",
          approved: "Approved",
          addedToToilBalance: "Added to TOIL balance",
          toilAbsences: "TOIL absences",
          noToilAbsences: "No TOIL absences",
          availableToTake: "Available to take",
          timeOffInLieuToil: "Time off in lieu (TOIL)",
          toilLogged: "TOIL Logged",
          toilTaken: "TOIL Taken",
          toilBalance: "TOIL Balance",
          currentAndFuture: "Current and Future",
          history: "History",
          cancelled: "Cancelled",
          accountEmail: "Work Email",
          personalEmail: "Personal Email",
          workExtension: "Work Extension",
          medicalCondition: "Medical Conditions",
          medicalConditionNotes: "Medical Condition Notes",
          medicationOngoingTreatment: "Medication/Ongoing Treatment",
          medicationOngoingTreatmentNotes: "Medication/Ongoing Treatment Notes",
          anySpecificAidneeded: "Any Specific Aids or Adaptions Needed",
          informationUpdatedSucessfully: "Information Updated Successfully",
          invalidInformation: "Invalid Information!",
          onlyAdminCanEditThisInfo: "Only Admin can Edit this information",
          title: "Title",
          middleName: "Middle Name",
          dateOfBirth: "Date of Birth",
          jan: "Jan",
          feb: "Feb",
          mar: "Mar",
          apr: "Apr",
          may: "May",
          jun: "Jun",
          july: "July",
          aug: "Aug",
          sep: "Sep",
          oct: "Oct",
          nov: "Nov",
          dec: "Dec",
          gender: "Gender",
          male: "Male",
          female: "Female",
          notDisclosed: "Not Disclosed",
          updateMedicalInfoSuccessfull: "Update Medical Info Successfull",
          updateMedicalInfoFailure: "Update Medical Info Failure",
          contactInformation: "Contact Information",
          personalInformation: "Personal Information",
          medicalInformation: "Medical Information",
          emergencyDetails: "Emergency Details",
          generateNewReport: " Generate new report",
          thisMonth: "This Month",
          fourWeeks: "Four Weeks",
          fortnight: "Fortnight",
          week: "Week",
          month: "Month",
          todaysAgenda: "Today's Agenda",
          today: "Today",
          lastweek: "Last Week",
          last2week: "Last 2 Weeks",
          last3week: "Last 3 Weeks",
          reports: "Reports",
          dateRange: "Date Range",
          downloadAs: "Download As",
          infoToProcess:
            "Note: There's quite a lot of info for us to process with this type of report, and it may take a few minutes to generate.",
          generate: "Generate",
          currentleaveentitlement: "Current Leave Entitlement",
          previousleaveentitlement: "Previous Leave Entitlement",
          nextleaveentitlement: "Next Leave Entitlement",
          holidaySummaryReport: "Holiday Summary Report",
          backToReports: "Back to Analytics",
          selectDate: "Select Date",
          pleaseSelectDateRange: "Please select Date Range!",
          chooseDateRange: "Choose Date Range",
          searchEmployees: "Select Person",
          allEmployees: "All People",
          selectEmployee: "Select People",
          employeeInformation: "People Information",
          furloughedEmployees: "Furloughed People",
          furloughedEmployeesReport: "Furloughed People Report",
          weRecommendToDownload: "We recommend that you also download an",
          leaveMustBePaid:
            "to check for any annual leave taken in this period. Any annual leave taken during furlough must be paid at 100% of the employee’s rate. Government guidance suggests that after submitting your claim to HMRC, you should inform your employees that the claim has been made. There's quite a lot of info for us to process with this type of report, and it may take a few minutes to generate",
          overtimeRequests: "Overtime requests",
          overtimeRequestsForTeam:
            "A detailed breakdown of pending and declined overtime requests for your team.",
          payableOvertime: "Payable overtime",
          detailedBreakdownOfRecord:
            "A detailed breakdown of every overtime record approved as payable overtime for your team.",
          toilSummary: "TOIL summary",
          overviewOfToil:
            "An overview of TOIL for your team, how much TOIL has been accrued, and how much is taken or left to take.",
          toilDetails: "TOIL details",
          detailedBreakdownOfRecordAsToil:
            "A detailed breakdown of every overtime record approved as TOIL for your team.",
          overtime: "Overtime",
          overtimeReport: "Overtime Report",
          generateReport: "Generate Report",
          //PayrollException
          customDates: "Custom Dates",
          payrollExceptions: "Payroll exceptions",
          payrollExceptionsReport: "Payroll exceptions report",
          sensitiveInformationReport: "Sensitive information report",
          sicknessReport: "Sickness report",
          whichReport: "Which report",
          turnoverRetention: "Turnover & retention",
          turnoverRetentionReport: "Turnover & retention report",
          workingStatusReport: "Working status report",
          workingTimePatternCreated:
            "Working Time Pattern Created Successfully",
          unableCreateWorkingTimePattern: "Unable Create Working Time Pattern",
          patternDetails: "Pattern details",
          makeDefault: "Make default",
          addNewEmployeeToThisPattern:
            "By making this the default working time pattern when you add a new people they will automatically be assigned to this pattern.",
          whatIsThis: "What is this?",
          timesAndBreaks: "Times and breaks",
          startAndEndTime:
            "Enter start time end time & break for your working day.",
          start: "Start Time: ",
          break: "Break",
          repeat: "Select Working Days",
          workingDays: "Working days",
          selectedTotalling: "Total hours",
          excludingBreaks: "excluding breaks",
          publicHolidays: "Public Holidays",
          annualLeaveEntitlements:
            "Select if employees on this working time pattern work public holidays and if they are included as part of the annual leave entitlement.",
          deducted: "Deducted",
          yearlyHolidayEntitlement:
            "They'll have a day off for any public holidays they would normally be working on and this is taken from their yearly holiday entitlement.",
          notDeducted: "Not Deducted",
          dayOffForAnyPublicHolidays:
            "They'll have a day off for any public holidays they would normally be working on and this will be given on top of their yearly holiday entitlement.",
          worksPublicHolidays: "Works Public Holidays",
          abilityToWorkOnPublicHolidays:
            "Public holidays are seen as a normal day and they won't have the day off and People has the ability to work on public holidays.",
          assignEmployeesWhoWorkInASimilarArea:
            "Please select the employees you wish to assign to this place of work. The place of work you are creating is set to <b>Bute</b>; you can only assign employees who work in a similar area.",
          searchByName: "Search by Name",
          searchByGroup: "Search by Group",
          selected: "Selected",
          assignable: "Assignable",
          notssignable: "Not assignable",
          sureYouWantToAddPlaceOfWork:
            "Are you sure you want to add this place of work ?",
          addressLine: "Address Line",
          enterDetailsOfNewPlaceOfWork:
            "Please fill out the details of your new place of work.",
          filterAddresses: "You can use this name to filter addresses",
          hqOrMainDepot: "E.g. HQ or Main depot",
          workInProgressNotice:
            "This is where we share some of the features we're currently working on so that you can have early access and give us your feedback. The things in here are still work-in-progress and may not work perfectly yet, but we want you to be able to try them as early as possible. You can enable the features you want to test here, then look out for the labs icon for features that are part of SpeedyLabs",
          blipWhosIn: "Blip who's in",
          checkEmployeeStatusByThisFeature:
            "If your employees are using Blip app, you can now see who's clocked-in and on a break in People hub by enabling this feature.",
          enable: "Enable",
          disable: "Disable",
          generatePopReports:
            " We've made expenses even easier! Once enabled you'll be able to generate a handy PoP report directly from SpeedyHR.",
          //Settings/Company
          companySettingsUpdated: "Company settings updated successfully",
          unableToUpdateSettings: "Unable to update comapny settings",
          companyName: "Company name",
          companyAddress: "Company Address",
          hideEmailFromOtherEmployees:
            "Show or hide people email addresses from other employees. This will affect:",
          theemployeeHub: "The people hub",
          contactDetailsOnApp: "The contact details on the mobile app",
          show: "Show",
          hide: "Hide",
          employeeStatus: "People status",
          employeesCurrentStatus:
            "Enables the ability for employees to display their current status.",
          absenceConflict: "Absence conflict when not in teams",
          if: "If",
          enabled: "enabled",
          absenceConflictsSetting:
            "this setting allows you to also see absence conflicts between employees who haven't been added to a team.",
          disabled: "diabled",
          futureAnnualLeave: "Prevent employees cancelling future annual leave",
          deleteAnnualLeaveRequests:
            "Enabling this setting removes the ability for your employees to delete their own annual leave requests. Their manager or an administrator must take this action on their behalf.",
          restrictedAnalyticPermissions: "Restricted analytic permissions",
          restrictEditAndDeleteCapabilities:
            "By enabling this setting, you are restricting the edit and delete capabilities of your managers. Once created, analytics can be edited by the author and by admins. Additional managers can be given edit permissions from the analytic menu.",
          permissionsForAnalytics:
            "Permissions are only valid for analytics created after this setting is applied and cannot be used for existing analytics.",
          hideShiftLabels: "Hide shift labels in analytics from employees",
          preventEmployeesFromSeeingColours:
            "Enabling this setting will prevent employees from seeing any colours and labels assigned to shifts on your analytics.",
          alertToYourEmployees: "Send an alert to your employees",
          pushNotificationToUsers:
            "Send important messages to your employees. Your message will be sent as a push notification to users with our mobile app installed. Web users will see the message in the notification menu when they log in. There are limited characters available for messages. If you need to include a link, consider using a URL shortening service such as",
          sendNotification: "Send notification",
          notificationPreferences: "Notification preferences",
          changeSettingsForNotifications:
            "Change the settings for the notifications you receive about your employees. These changes will be applied to emails and mobile app push messages you receive.",
          employeeRequests: "People requests",
          notifyWhenRequestsAre:
            "We can send you notifications when requests are:",
          raised: "Raised",
          deleted: "Deleted",
          updated: "Updated",
          receiveNotificationsFrom: "Receive notifications from...",
          everyBody: "Everybody",
          noBody: "Nobody",
          personalAndContactInformation: "Personal and contact information",
          personalOrContactInfo:
            "We can notify you when your employees update their personal or contact information",
          personalAndContactDetails: "Personal and contact details",
          everybody: "Everybody",
          nobody: "Nobody",
          permissions: "Permissions",
          assignOrRevokePermissions:
            "Assign or revoke permissions for your staff",
          user: "User",
          roles: "Roles",
          privileges: "Privileges",
          subordinates: "Subordinates",
          otherPermissions: "Other permissions",
          admin: "Admin",
          manager: "Manager",
          payroll: "Payroll",
          //PlacesOfWork
          details: "Details",
          assignEmployees: "Assign People",
          assignEmployee: "Assign People",
          confirm: "Confirm",
          unableToGetWorkPlacesList: "Unable to get work places list",
          workPlaceCreated: "Work place created successfully",
          unableToCreateWorkPlace: "Unable to create work place",
          deletedPlaceOfWork: "Deleted Place of Work",
          placesOfWork: "Places of work",
          newPlacesOfWork: "New Places of work",
          workPlacesCannotBeEdit:
            "Note: Places of work cannot be fully edited once they have employees assigned.",
          usedForAddressFilters: "Used for address filters",
          assigned: "Assigned",
          actions: "Actions",
          noResultsFound:
            "No results found - please click the button above to set up a place of work",
          newPlaceOfWork: "New Location",
          updateLocation: "Update Location",
          previous: "Previous",
          pleaseSelectedEmployess: "Please selected employess",
          pleaseFillAllRequiredFields: "Please fill all required fields",
          unableToGetWorkingTimesList: "Unable to get working times list",
          unableToDeleteWorkingTimePattern:
            "Unable to delete working time pattern",
          pleaseNote: "Please note:",
          patternCannotBeEdited:
            "You cannot edit a pattern after it has been created so make sure you are happy before finalising it.",
          newEmployeesImported:
            "New people's imported into the system will be defaulted to the pattern:",
          currentWorkingTimePatterns: "Current working time patterns",
          showTimePatterns: "Show time patterns",
          createNewPattern: "Create new pattern",
          //WorkingTimePatternCell
          minBreak: "Mins break",
          totalling: "totalling",
          deductedPublicHolidays:
            "Public holidays are deducted from the annual leave entitlement.",
          createManage: "Create & manage",
          addMultipleStaffToShifts:
            "Create, plan and manage schedule all in one place. Add multiple staff to shifts, edit ongoing shifts and get an up-to-date view of who's working when.",
          manageShareWithEmployees: "Create, manage and Share with people",
          publishedStaffingWithStaff:
            " We'll automatically share the published schedule with your staff so they always know when they work. Made changes to shifts? We'll let the people know so you don't have to.",
          unableToFindStaffing: "Unable to find schedule with given date range",
          unpublishStaffings:
            "Are you sure you want to unpublish this schedule?",
          hideMyStaffing:
            "This will hide my schedule from your people's. They will no longer be able to access it or see their shifts and absence conflicts.",
          wantToPublishThisStaffing:
            "Are you sure you want to publish this schedule?",

          staffingRenamed: "Schedule Renamed Successfully",
          staffingRenamedFailed: "Schedule Renamed failed",
          staffingDeleted: "Schedule Deleted Successfully",
          staffingDeletionFailed: "Schedule Deletion Failed",
          staffingEditedFailed: "Schedule edited Failed",
          noRoleAssigned: "No Role Assigned",
          noLocationAssigned: "No Location Assigned",
          viewStaffingDetailRequestFailed:
            "View Schedule Detail Request Failed",
          createNewSchedule: "Create a New Schedule",
          viewAll: "View All",
          to: "To",
          publishedStaffing: "Published Schedules",
          unpublishedStaffing: "Unpublished Schedules",
          noEmployeeSelected: "No People Selected",
          noAssignableEmployeeFound: "No Assignable People Found",
          staffingMadeEasy: "Schedule made easy",
          staffingPlanningFeature:
            "Our schedule planning feature makes ensuring you have the right people, in the right place, at the right time, easier than ever! So whether you’re staggering shifts to support social distancing, or sharing notes with your staff about COVID-19 measures before their shift you'll have peace of mind that everything has been taken care of with SpeedyHR.",
          copyOfStaffing:
            "Even better, with this feature also available on our mobile app, your employees will always have a copy of their schedule to hand whenever they need it.",
          createStaffingSchedule: "Create a new schedule",
          staffingPlanningHelp: "Schedule planning help?",
          gettingStartedGuide:
            "Not sure where to start? No problem! We've put together this handy getting started guide that'll show you everything you need to know about schedule planning on SpeedyHR or watch our short how-to video to get you started.",
          takeMeToStaffingGuide: "Take me to schedule guide",
          viewHowToVideo: "View how-to video",
          tableView: "Table View",
          setYourShiftTimes:
            "Set your shift times and easily assign this across employees and dates at once, with a click.",
          staffingCreated: "Schedule created Successfully",
          staffingCreationFailed: "Schedule creation failed",
          shiftCreated: "Shift created Successfully",
          shiftCreationFailed: "Shift creation failed",
          copyAnExistingStaffing: "Copy an existing schedule",
          scheduleName: "Schedule Name",
          pleaseFillAllTheFields: "Please fill all the fields",
          staffingRequestFailed: "Schedule request failed",
          scheduleDuration: "Schedule Duration",
          selectYourStaffingView: "Select your schedule view",
          shiftTime: "Shift Time",
          breakDuration: "Break Duration",
          shiftDay: "Shift Day",
          createNewStaffingSchedule: "Create New Schedule",
          newBespokeStaffingSchedule:
            "Create a new bespoke schedule for your business. Choose your shift times, assign employees and add notes before publishing.",
          copyExistingStaffingSchedule: "Copy Existing Schedule",
          staffingNotes:
            "Choose to copy a previous schedule and pull through existing shifts and schedule notes, Then, select a new start date and make any changes you need before publishing",
          createAndManageEmployeeShiftPatterns:
            " Create and manage people shift patterns that are fully flexible to meet your team’s demand.",
          whatWouldYouLikeToDo: "What would you like to do?",
          generateStaffingReport: "Generate a schedule report",
          summaryOfTheHours:
            "At the tap of a button, you can download a summary of the hours your staff have worked over a set period. It also includes the total hours worked.",
          downloadStaffingReport: "Download a schedule report",
          olderStaffing: "Older Schedules",
          endDate: "End Date",
          inProgress: "In progress",
          currentlyActiveStaffing:
            "Schedule that are currently active and in progress will appear here.",
          futureStaffing: "Future schedule",
          futureStaffingAppear:
            "Schedule that are starting in the future will appear here.",
          unpublish: "Unpublish",
          publish: "Publish",
          staffingDetails: "Staffing Details",
          scheduleDetails: "Schedule Details",
          rotaName: "Name",
          rotaDay: "Day",
          rotaDuration: "Duration",
          rotaStatus: "Rota Status ",
          scheduleStatus: "Schedule Status",
          published: "Published",
          unPublished: "Un Published",
          shiftDetails: "Shift Details",
          shiftTimeStart: "Shift Time Start",
          shiftTimeEnd: "Shift Time End",
          shiftNotes: "Shift Notes",
          shiftUpdated: "Shift Updated Successfully",
          failedToUpdateShift: "Failed to Update Shift",
          breakDurationLessThanShiftDuration:
            "Break Duration should be less than shift Duration",
          shifts: "Shifts",
          assignee: "Assignee",
          noShiftsToSeeHereYet: "No shifts to see here yet...",
          createShift: "Create Shift",
          anyStaffingYouAssigned:
            "Any schedule you've been assigned to will all be available anytime, anywhere using our app. Whenever you're added to a new schedule or your shift changes, you'll get an automatic notification so you know where to be and when.",
          timelineView: "Timeline View",
          dragAndDropView: "Drag and Drop View",
          selectYourDefaultStaffing: "Select your default schedule view below",
          companyStaffingSettings: "Company schedule settings",
          restrictedStaffingPermissions: "Restricted schedule permissions",
          staffingCanBeEditedByTheAuthor:
            "By enabling this setting you are restricting the edit and delete capabilities of your managers. Once created, schedule can be edited by the author and by admins. Additional managers can be given edit permissions from the schedule menu.",
          permissionsAreOnlyValidForStaffing:
            "Permissions are only valid for schedule created after this setting is applied and cannot be used for existing schedule.",
          View: "View",
          print: "Print",
          rename: "Rename",
          Copy: "Copy",
          assignedUsers: "Assigned Users",
          youReAboutToAdd: "You're about to add",
          toThisRota: "to this rota",
          shiftAddedSuccessfully: "Shift Added Successfully",
          failedToAddShift: "Failed to add shift",
          breakandShiftDuration: "Break can not be greater than shift duration",
          employeeAssignedToStaffingSuccessfully:
            "People assigned to Schedule Successfully",
          employeeAssignRequestFailed: "People Assign Request Failed",
          selectEmployeeToAssignFirst: "Select people to assign first",
          userUnassignedFromStaffingSuccessfully:
            "User Unassigned from Schedule successfully",
          unassignRequestFailed: "Unassign request failed",
          removeEmployee: "Remove People",
          conflict: "CONFLICT",
          absent: "ABSENT",
          dailyTotal: "Daily total",
          totalHours: "Total Hours",
          incBreak: "Inc. breaks?",
          shift: "Shift",
          viewStaff: "View Schedule",
          currentContractedHours: "Current Contracted Hours",
          breaksTotal: "Breaks (Total)",
          inclBreaks: "Incl. breaks",
          nextUpPublicHoliday: "Next up - Public holiday",
          youVeAlsoTaken: "You've also taken",
          overtimeHistory: "Overtime history",
          timeOffInLieu: "Time off in lieu",
          overtimeClaimsPending: "overtime claims pending",
          //Pages
          pendingRequests: "My Pending Requests",
          othersPendingRequests: "Pending Requests",
          declinedRequests: "My Declined Leave",
          othersDeclinedRequests: "Declined Leave",
          text: "Text",
          select: "Select",
          lowest: "Lowest",
          highest: "Highest",
          byTeam: "by team",
          login: "Login",
          welcomeToYourAllInOne: "Welcome to your all in one",
          resourceConsumingHrProcesses:
            " Scroll down to see why start-ups and SMEs choose SpeedyHR to take care of the time and resource consuming HR processes, meanwhile they focus on what matters.",
          interestedToKnowMore: "Interested to know more?",
          arrangeAConsultation:
            "Arrange a consultation with one of our system experts",
          keepsYourManagerInformed:
            "SpeedyHR keeps your manager informed and teams connected.",
          simplifiedTimeAndAttendance:
            "Time and attendance simplified but organised.",
          cloudBasedAttendanceManagement:
            "Our cloud based attendance management software lets you:",
          createAndManageRotas: "Create and manage rotas",
          preventAbsenceClashesByCreatingStaffingPlans:
            "Plan ahead for busy periods and prevent absence clashes by creating schedule plans to ensure that your teams have suitable cover to operate.",
          monitorAttendance: "Monitor attendance",
          mobileFriendlySystem:
            "Using our mobile friendly system your teams clock in and out on a day to day basis. No matter where you are working from, you can see who’s running late, working from home, on a break or off sick.",
          manageLeaveRequestsWithEase: "Manage leave requests with ease",
          timeOffNotifications:
            "Not only do you receive a notification as soon as time off has been requested but you also have access to your team’s calendar and holiday entitlements so that you keep on top of schedule.",
          payrollProcessing:
            "Prepare for your payroll processing by running our built in timesheet, absence and holiday reports",
          storageSafety: "Keep everything safely stored in the one place.",
          documentStorage:
            "Save the trees and use our document storage to electronically store personnel files and shared company documents.",
          mobileFriendlySoftware:
            "With flexible workforces growing paper files are no longer fit for purpose, as a manager you are always on the go and thanks to our mobile friendly software you can access your employee’s documents no matter where you are.",
          personnelFilesSecurity:
            "We keep these personnel files securely stored and private so that only the manager and people can view them.",
          viewMissingAndOutdatedInformation:
            "Ensure your people records are up to date by running our built in report that allows managers to view missing and outdated information. Vital for HR audits.",
          separateStorageForCompanyPolicies:
            "Alongside personnel files we offer separate storage for your company policies that employees can easily access, saving you time emailing your health and",
          needSupportWithPolicies:
            "Need support with policies or templates? Keep reading to see how we are the perfect partner for you.",
          yourOneStopHrLibrary: "Your one stop HR library",
          saveAll: "Save All",
          accessToComprehensiveLibrary:
            "SpeedyHR gives you 24/7 access to a comprehensive library of HR forms, tools, policies and guides to support your business.",
          dealingWithJobOffers:
            " Whether you are dealing with job offers, performance management, disciplinaries or terminations, our templates have you covered.",
          cipdQualifiedProfessionals:
            "Every document comes with step by step guidance provided by our CIPD-qualified professionals. But if you still feel unsure our friendly advisors are a phone call or video-conference away.",
          hrLibrary: "Simply open the HR library in your company documents",
          pickYourTemplate: "pick your template",
          downloadItInstantly: "download it instantly",
          customiseItToYourNeeds: "customise it to your needs",
          easilyPrintOrShareDigitally: "easily print or share digitally.",
          downloadDocuments: "Our documents are ready for download now.",
          bookConsultation: "Book a Consultation",
          whyUseHrSoftware: "Why use HR software?",
          digitalSolutions:
            "Human resources software is the digital solution that lets organisations manage and maintain HR processes.",
          automatingManualHrTasks:
            "The standard HR software should assist with automating manual HR tasks, storing people information and producing reports.",
          allInOneSoftware: "Why use SpeedyHR's all in one HR software?",
          weAreYourPartners:
            "We are more than a “software”, we are your partners.",
          meetYourBusinessNeeds:
            "Our software is adaptable to fit in with today’s flexible working practices and evolving HR functions therefore no matter what size your business is we offer a wide range of features that meet your business needs.",
          ourSoftwareKeepsYourTeamsConnected:
            "HR admin time eliminated - our software supports your managers with absence management, resourceful planning and reporting plus our software keeps your teams connected with live location updates and a mobile friendly clock-in clock-out program.",
          cipdQualifiedAdvisors:
            "We offer more than an automated HR admin service, we have CIPD-qualified advisors available to offer ER support plus supportive templates and guides available in our HR library. Our HR library has a range of documents available for every scenario throughout the people life cycle with over 30 files that are kept up to date in line with current legislation. Don’t waste your time worrying about changing HR and health and safety legislations, leave it to your HR partner.",
          smartHrSoftware:
            "Smart HR software that transforms the way you manage your staff.",
          requestConsultation: "Request a consultation",
          speedyHrTakesCareOfYourNeeds:
            "See how SpeedyHR takes care of all your needs",
          pleaseEnterEmailToContinue: "Please enter Email to continue",
          passwordEmailSent: "Change password email sent successfully",
          forgotPassword: "Forgot password ?",
          enterYourEmailAddress: "Enter Your Email Address",
          emailAddress: "Email address",
          send: "Send",
          bothFieldsAreRequired: "Both fields are required",
          yourStrategic: "Your Strategic",
          hrPartner: "HR Partner",
          loginToSpeedyHr: "Login to SpeedyHR",
          password: "Password",
          forgotYourPassword: "Forgot your password?",
          speedyHrAgreement: "By logging in to SpeedyHR you agree to our ",
          termsOfUse: "Terms of Use",
          termsAndConditions: "Terms & Conditions",
          privacyPolicy: "Privacy Policy",
          passwordDoesNotMatch:
            "New password and Confirm new password does not match",
          passwordRequirement: "Password must contain atleast 8 characters",
          passwordChangedSuccessfully: "Password changed successfully",
          pleaseContactYourAdministrator:
            "Invalid Token, Please contact your administrator",
          enterNewPassword: "New Password",
          confirmNewPassword: "Confirm New Password",
          currentPassword: "Current Password",
          pageNotFound: "Page Not Found",
          pagesNotFound: "We can't find the page you're looking for.",
          countriesReceivedSuccessfully: "Countries received successfully",
          labApiTabsExample: "lab API tabs example",
          employmentInfo: "Employment Info",
          personalInfo: "Personal Info",
          personalDocuments: "Personal Documents",
          companyDocuments: "Company Documents",
          absenceRecord: "Absence Record",
          overtimeLog: "Overtime Log",
          absence: "Absence",
          upcomingAbsencesPerEmployee:
            "A breakdown of taken, pending, cancelled and upcoming absences per people.",
          holidaySummary: "Holiday Summary",
          breakdownOfAllAnnualLeaves:
            "A breakdown of all annual leave taken, pending and upcoming as well as the amount of entitlement remaining.",
          generateTimesheetReport:
            "Generate a timesheet report for your people.",
          personalDetailsInformation:
            "A breakdown of all information including absence records, personal details and contract information for a specific people.",
          latenessReportText: "Generate a lateness report for your employees",
          clockOutUserReportText : "Generate clocked-out users report of your employees",
          payRollReportText: "Generate a payroll report for your employees",
          company: "Company",
          workingTimePatterns: "Working time patterns",
          activeStaffing: "Active Schedules",
          createStaffing: "Create Schedule",
          applyFilter: "Apply Filter",
          clearFilter: "Clear Filter",
          timeLog: "Time Log",
          date: "Date",
          clockIn: "Clock In",
          clockOut: "Clock Out",
          clockOutUsers : "Clocked Out Users", 
          breaks: "Breaks",
          noBreakFound: "No Breaks Found",
          noDataFound: "No Data Found",
          unableToGetUserTimesheetHistory:
            "Unable to get user timesheet history",
          unableToGetWhoseInList: "Unable to get whose in list",
          clockedIn: "Clocked In",
          clockedOut: "Clocked Out",
          whoIn: "Who’s In",
          filterEmployee: "Filter People",
          showHistory: "Show History",
          myHistory: "My History",
          clockUserOut: "Clock Out User",
          clockInUser: "Clock In User",
          onBreak: "On Break",
          onLeave : "On Leave", 
          pleaseLoginToContinue: "Please Login To Continue",
          pleaseSelectAllFields: "Please Select All Fields",
          allFieldsRequired: "All fields are required",
          pleaseFillAllData: "Please Fill All Data",
          pleaseFillAllFields: "Please fill all fields",
          timeOffUpdatedSuccessfully: "Time off Updated Successfully",
          absenceAddedSuccessfully: "Absence Added Successfully",
          leaveAlreadyExist: "Leave Already Exist",
          addingAbsenceFailed: "Adding Absence Failed",
          deletedMandatoryLeave: "Deleted Mandatory Leave Successfully",
          mandatoryLeaveDeletionFailed: "Mandatory Leave Deletion Failed",
          users: "Users",
          pleaseSelectEmployee: "Please Select People",
          createLeave: "Create Leave",
          successfully: "successfully",
          failed: "failed",
          requestedOn: "Requested on",
          willHave: "",
          none: "None",
          uploadFile: "Upload File",
          files: "Files",
          validatingEmail: "Validating email...",
          clear: "Clear",
          updateRole: "Update Role",
          addRole: "Add Role",
          employeesCreationFailed: "People Creation Failed",
          followingErrorOccured: "Following Errors Occured:",
          note: "Note",
          noOfEmployees: "No. of People",
          teamName: "Team Name",
          by: "by",
          filterAbsences: "Filter Absences",
          toil: "TOIL",
          leaveYear: "Leave Year",
          annualEntitlement: "Annual Entitlement",
          mr: "Mr",
          mrs: "Mrs",
          miss: "Miss",
          ms: "Ms",
          dr: "Dr",
          prof: "Prof",
          sir: "Sir",
          theRev: "The Rev",
          theHon: "The Hon",

          selectAPerson: "Select a person",
          employeeInformationReport: "People information report",
          latenessReport: "Lateness report",
          staffingReport: "Schedule report",
          detailed: "Detailed",
          report: "report",
          Vacctrakreport: "VaccTrak report",
          Vacctrak: "VaccTrak",
          patternName: "Pattern Name",
          speedyLabs: "SpeedyLabs",
          companyLogo: "Company Logo",
          privacy: "Privacy",
          next: "Next",
          employeeName: "People Name",
          selectStaffing: "Select Schedule",
          editStaff: "Edit Staff",
          editStaffDetails: "Edit Staff Details",
          editAssignEmployee: "Edit Assign People",
          shiftStart: "Shift Start",
          shiftEnd: "Shift End",
          viewYourShifts: "View your shifts on the go",
          staffMembers: "staff members",
          notPublished: "Not Published",
          documents: "Documents",
          whyUseSpeedyHr: "Why use SpeedyHR’s all in one HR software?",
          goBackTo: "Go to",
          goToCalendar: "Go to Calendar",
          allStaffing: "All Schedules",
          showShiftsFor: "Show shifts for",
          everyone: "Everyone",
          me: "Me",
          specificPeople: "Specific people...",
          showNotes: "Show notes",
          publishStaffing:
            "Once a shift has been added you can publish this schedule.",
          calories: "Calories",
          dessert: "Dessert (100g serving)",
          fat: "Fat",
          carbs: "Carbs",
          protein: "Protein",
          displayNotes:
            "Showing notes on schedule will also display them when printing.",
          breakStarted: "Break Started",
          updateAbsence: "Update Absence",
          showMore: "Show More",
          showLess: "Show Less",
          endBreak: "End Break",
          startBreak: "Start Break",
          start: "Start",
          end: "End",
          selectYourCurrentStatus: "Select your current status",
          hi: "Hi",
          //OnBoarding
          tellUsALittleBitAboutYourOrganisation:
            "Tell us a little bit about your organisation.",
          excludingClientSitesTellUsAboutAnyLocations:
            "Excluding client sites, tell us about any locations you operate from, or do this later in “Settings”.",
          tellUsAboutTheRoles:
            "Tell us about the roles in your organisation, or do this later in “Settings”.",
          addPeopleFromYourOrganisation:
            "Add people from your organisation to SpeedyHR, you can add more later in “Settings”.",
          assignPeopleToRolesAndLocation:
            "Assign people to roles and locations. These can be amended later if required.",
          location: "Location",
          roleSettings: "Role Settings",
          role: "Role",
          assignLineManager: "Assign Line Manager",
          assignRolePrevilages: "Assign Privileges to Roles ",
          setHoliday: "Set Holidays",
          bulkEmployeeType: "Bulk People Type",
          bulkUploadEmployees: "Add People",
          editEmployees: "Edit People",
          rolesAndPrevilages: "Role Settings & Privileges",
          companySettings: "Company Settings",
          addALocation: "Add a Location",
          addARole: "Add Role Settings",
          managingRoles: "Managing Roles",
          locationType: "Location Type",
          postalCode: "Postal Code",
          action: "Action",
          userType: "User Type",
          lineManager: "Line Manager",
          approver: "Approver",
          leaveAllowance: "Leave Allowance",
          previlges: "Previlges",
          noRoleSelected: "No Role Selected",
          addWorkTimePattern: "Add Working Time Pattern",
          timeAndBreak: "Time and Break",
          publicHoliday: "Public Holiday",
          noRoles: "No Roles",
          contractedHours: "Contracted Hours",
          contractedHoursPerPeriod: "Contracted Hours Per Period",
          avgWorkingHours: "Avg. Working Hours",
          companyWorkingHours: "Company Working Hours",
          hourly: "Variable Hours",
          selectColumnsOfCsv: "What information would you like to upload:",
          reset: "Reset",
          onBoardingStatus: "Found errors during the onboarding process",
          // Document Titles
          dtHome: "Home",
          dtOnboaring: "OnBoarding",
          dtLogin: "Login",
          dtForgotPass: "Forgot Password",
          dtChangePassword: "Change Password",
          dtDdashboard: "Dashboard",
          dtCalendar: "Calendar",
          dtEmployeesHub: "People Hub",
          dtTimesheet: "Timesheet",
          dtStaffing: "Schedule",
          dtCompanyDocs: "Documents",
          dtAnalytics: "Analytics",
          dtSettings: "Settings",
          dtProfile: "Profile",
          restDay: "Rest Day",
          addRollSetting: "Add Role Settings",
          backto: "Back to",
          rolesselected: "Roles Selected",
          downloadcsvtemplate: "Download CSV Template",
          uploadcsvformultiple:
            "Upload CSV for Multiple people according to provided template",
          csvcontainformat: "CSV Contain the format of adding multiple people",
          others: "Others",
          // Edit Role Modal
          parttime: "Part Time",
          fixedtime: "Fixed Time",
          // Edit Contract Modal
          compromiseagreement: "Compromise agreement",
          deceased: "Deceased",
          dismissed: "Dismissed",
          endofcontract: "End of contract",
          redundant: "Redundant",
          resigned: "Resigned",
          retired: "Retired",
          // Working Time Patterns
          sunday: "Sunday",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          // Place of Work
          updatedsuccess: "Updated Successfully",
          updatedfailed: "Updated Failed",
          terminated: "Terminated",
          terminationDate: "Termination Date",
          terminationReason: "Termination Reason",
          noApprovedcClaims: "No approved claims",
          LogOvertimeFor: "Log overtime for",
          add: "Add",
          AreyouSureyouWant: "Are you sure you Want",
          DeclinedAbsence: "Declined Absence",
          analyticsDesc: "<p>Generate the following analytics</p>",
          calendarDesc: "<p>Absences, public holidays & notable dates.</p>",
          employeeHubDesc: "<p>Search people by name or job title</p>",
          sharedDocumentsDesc: "<p>Manage your documents.</p>",
          whoseInDesc: "<p>See who's clocked in</p>",
          staffingDesc:
            "<p>Create and manage schedules & shift patterns that are fully flexible to meet your team\u2019s demand.</p>",
          dated: "Dated",
          generatedBy: "Generated by",
          companyName: "Company Name",
          companyAddress: "Company Address",
          copyright: "Copyright ©",
          skip: "Skip",
          roleSettingsNote:
            "Add at least one role, additional roles can be added later in settings",
          guideToCSV: "Guide to filling in .csv file",

          // CTA Titles 
          addShifts : "Add Shifts",
          clearSelection : "Clear Selection", 
          selectMultiple : "Select Multiple",
        },
      },

      ar: {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Welcome to React": "Welcome to React and react-i18next",
          name: "الاسم",
          acceptableRadius: "النص النص النص",
          selectroles: "حدد الأدوار",
          hoursSpent: "الساعات الممضاة هذا الأسبوع",
          description: "الوصف",
          selectedWorkingHour: "إجمالي ساعات العمل المحددة",
          invalidReportType: "نوع التقرير غير صالح",
          // شاشة اللوحة
          summaryText: "ملخص غيابي",
          timesheetHistory: "تاريخ الجدول الزمني",
          whoseIn: "من في الداخل؟",
          directorStatus: "حالة المدير",
          nonDirector: "غير مدير",
          directorAnnualBasis: "المدير (على أساس سنوي)",
          directorProRataBasis: "المدير (على أساس متناسب)",
          employementTypes: "نوع التوظيف",
          temporary: "مؤقت",
          contractor: "مقاول",
          expatriate: "مغترب",
          initials: "الأحرف الأولى",
          dateDirectorshipBegan: "تاريخ بداية المديرية",
          maritalStatus: "الحالة الزوجية",
          single: "أعزب",
          married: "متزوج",
          civilPartnership: "شراكة مدنية",
          divorced: "مطلق",
          widowed: "أرمل",
          other: "آخر",
          phoneNumber: "رقم الهاتف",
          bankDetails: "تفاصيل البنك",
          fieldsMarkedAs: "ملاحظة: الحقول المميزة بـ",
          areMandatory: "إلزامية",
          bankAccount: "حساب البنك",
          buildingSociety: "حساب الجمعية الإسكتلندية",

          bankAccountNumber: "رقم حساب البنك",
          bankAccountName: "اسم صاحب الحساب",
          bankAccountType: "نوع حساب البنك",
          buildingSocNumber: "رقم الجمعية الإسكتلندية",
          BASCReference: "الإشارة إلى BASC",
          bankName: "اسم البنك",
          bankPostCode: "الرمز البريدي للبنك",
          bankAddress: "عنوان البنك",
          postCode: "الرمز البريدي",
          contractDetails: "تفاصيل العقد",

          userAddress: "عنوان المستخدم",
          employeeReference: "المرجع الموظف",
          worksReference: "المرجع العمل",
          workEndDate: "تاريخ انتهاء العمل",
          niCategory: "فئة NI",
          wk1mth1Basis: "أساس الأسبوع1/ الشهر1",
          paymentFrequency: "تكرار الدفع",
          grossSalary: "الراتب الإجمالي",
          sortCode: "رمز التصنيف",
          optionsFor: "خيارات ل",
          commaException: "لا تستخدم , (فاصلة) في النص",
          payslipEmailAddress: "عنوان بريد الكتروني للكشوف الدفع",
          payslipPassword: "كلمة مرور الكشف الدفع",
          nonUKWorker: "عامل غير مملكة متحدة",
          rightToWorkConfirmStatus: "حالة تأكيد الحق في العمل",
          rightToWorkDocumentType: "نوع وثيقة الحق في العمل",
          rightToWorkDocumentExpiryDate: "تاريخ انتهاء وثيقة الحق في العمل",
          rightToWorkReference: "المرجع لحق العمل",

          emergencyContact: "جهة الاتصال الطارئة",
          contactPermission: "إذن الاتصال",
          contactTelephoneNo: "رقم هاتف الاتصال",
          contactRelationship: "علاقة الاتصال",

          ukPassport: "جواز سفر المملكة المتحدة",
          euPassport: "جواز سفر الاتحاد الأوروبي",
          euNationalIDCard: "بطاقة الهوية الوطنية للاتحاد الأوروبي",
          biometricResidencePermit: "تصريح الإقامة الحيوي",
          nonEEAPassportWithEndorsement:
            "جواز سفر غير الاتحاد الأوروبي مع تصديق",
          eeaResidencePermit: "تصريح الإقامة للاتحاد الأوروبي",
          otherHOIssuedDocument: "وثيقة صادرة عن وزارة الداخلية الأخرى",
          birthAdoptionCertificationPlusNINO:
            "شهادة الميلاد/التبني بالإضافة إلى NINO",
          certificateOfRegistration: "شهادة التسجيل",
          hoPositiveVerificationNotice:
            "إشعار التحقق الإيجابي من وزارة الداخلية",
          pvnPlusARC: "PVN بالإضافة إلى ARC",
          pvnPlusCoA: "PVN بالإضافة إلى CoA",
          euOther: "وثيقة أخرى في الاتحاد الأوروبي",
          spouse: "الزوج/الزوجة",
          parent: "الوالد/الوالدة",
          sibling: "الأخ/الأخت",
          child: "الابن/الابنة",
          friend: "الصديق/الصديقة",

          radiusInfo:
            "سيتم استخدام نص النص النص لتتبع GPS للمستخدمين عند الدخول والخروج",
          clockInFrom: "تسجيل الدخول من:",
          clockOutFrom: "تسجيل الخروج من:",
          allownDesc:
            "يجب أن يحتوي الإذن بالمغادرة فقط على أرقام صحيحة أو .5 كقيمة عشرية",
          noClockedInUsersMsg: "لا توجد مستخدمين مسجلين",
          noUpcomingHolidaysMsg: "لا توجد عطلات عامة مقبلة",
          upcomingHolidaysMsg: "العطلات العامة القادمة",
          passValidationMsg:
            "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل ورقم وحرف خاص واحد على الأقل",
          locationPermission:
            "النظام يرغب في تحديد موقع عملك، الرجاء السماح بأذونات الموقع",
          clearFilters: "إعادة تعيين",
          resetPassword: "إعادة تعيين كلمة المرور",
          locationPermissionMsg: "الرجاء السماح بإذن الموقع لعرض الخريطة",
          roleType: "نوع الدور",
          viewPeople: "الأشخاص",
          createWTPTitle: "إنشاء نمط وقت العمل",
          updateWTPTitle: "تحديث نمط وقت العمل",
          nothingToUpdate: "لا يوجد شيء للتحديث",
          viewOnMap: "عرض على الخريطة",
          previlegeNotFoundTitle: "تم العثور على مستخدم غير مصرح به",
          previlegeNotFoundDesc:
            "ليس لديك إذن لاستخدام هذه الميزة، الرجاء الاتصال بالمسؤول",
          welcome: "ملخص غيابي",
          reqTimeOff: "طلب إجازة",
          remaining: "متبقي",
          selectWorkPlaceDesc: "حدد مكان العمل",
          selectCountryDesc: "حدد البلد",
          selectRoleDesc: "حدد الدور",
          days: "أيام",
          cancelBulkUpload: "إلغاء التحميل الجماعي",
          allowance: "الإعانة",
          nextUp: "القادم",
          noLeaveFound: "لا توجد إجازات مقبلة",
          timeOff: "إجازة",
          taken: "تم الاستفادة منها",
          youHave: "لديك",
          uploadpeopledata: "تحميل بيانات الأشخاص",
          overtimeClaimsApproved: "المطالبات الإضافية تمت الموافقة عليها",
          youAlsoTaken: "لقد قمت أيضًا بالاستفادة من",
          lateness: "التأخر",
          sickness: "المرض",
          otherLeave: "إجازة أخرى",
          thisWeek: "هذا الأسبوع",
          addTimeOff: "إضافة إجازة",
          myInfo: "معلوماتي",
          workingStatus: "حالة العمل",
          annualLeave: "الإجازة السنوية",
          sicknessAbsence: "غياب بسبب المرض",
          reporting: "الإبلاغ",
          calendar: "التقويم",
          finish: "انتهاء",
          employeeHub: "مركز الأشخاص",
          //
          absenceReport: "تقرير الغياب",
          annualLeaveReport: "تقرير الإجازة السنوية",
          timeSheetReport: "تقرير الجدول الزمني",
          empDetailReport: "تقرير تفاصيل الأشخاص",
          //AddTimeOff
          fullDay: "يوم كامل",
          halfDay: "نصف يوم",
          end: "وقت الانتهاء: ",
          min: "دقائق",
          hoursDeducted: "ساعات مخصومة",
          addAbsence: "إضافة غياب",
          employee: "الأشخاص",
          absenceType: "نوع الغياب",
          noWorktimePattern: "الرجاء طلب من المسؤول تعيين نمط وقت العمل",
          notes: "ملاحظات",
          backToProfile: "العودة إلى الملف الشخصي",
          //Calendar
          requestLeave: "طلب إجازة",
          clickToEdit: "انقر للتحرير",
          sync: "مزامنة",
          allTeams: "جميع الفرق",
          myTeams: "فرقي",
          creatingTeams: "إنشاء الفرق",
          byType: "حسب النوع",
          all: "الكل",
          nameOfMendatoryLeaves: "اسم الإجازة الإلزامية",
          leaveDate: "تاريخ الإجازة",
          annualLeaveDeduction: "خصم من حقوق الإجازة السنوية",
          yes: "نعم",
          recordingThisElsewhere: "أقوم بتسجيل هذا في مكان آخر",
          no: "لا",
          noCalendarData: "انقر لعرض المزيد لعرض أنشطة التقويم",
          mandatoryLeave: "الإجازة الإلزامية",
          publicHolidays: "الأعياد الرسمية",
          addMandatoryLeave: "إضافة إجازة إلزامية",
          year: "السنة",
          publicHolidaysDesc:
            "تشمل الأعياد الرسمية يوم رأس السنة وعيد الاستقلال وعيد العمال وعيد الميلاد وعيد الفصح وعيد الشكر. ومع ذلك، يمكن أن تختلف الأعياد المحتفل بها وأهميتها بشكل كبير من بلد إلى آخر ومن ثقافة إلى أخرى.",
          employeeHolidaysDates:
            "تواريخ يجب على موظفيك أخذها كإجازة، مثل فترات الإغلاق والعطل المصرفية المحلية وما إلى ذلك. بمجرد تعيينها، ستظهر على ملف الأشخاص كإجازة إلزامية بالإضافة إلى التقويم.",
          employeeannualLeaveDeductions: "يتم خصمها من حقوق الإجازة السنوية",
          request: "طلب",
          shouldIapprove: "هل يجب علي الموافقة؟",

          leaveYearPendingRequests: " قيد الانتظار",
          exceedAllowance: "",
          noConflicts: "",
          approve: "الموافقة",
          decline: "الرفض",
          //CovidEmployee
          regenerateDocuments: "إعادة إنشاء المستندات",
          ourCallService:
            "هل تحتاج إلى مساعدة في هذا؟ لا تقلق، خبراءنا متاحون على مدار الساعة للرد على مكالمتك. ما عليك سوى الاتصال بهم على",
          option: "خيار",
          generateAReport: "إنشاء تقرير",
          goto: "الانتقال إلى",
          vaccinated: "تم تلقي اللقاح",
          name: "الاسم",
          vaccineOne: "اللقاح 1",
          vaccineTwo: "اللقاح 2",
          booster: "الجرعة المعززة",
          pass: "التصريح",
          vaccTrakPass: "لماذا يتم استخدام التصريح VaccTrak؟",
          employeeVaccination:
            "يمكنك إصدار تصريح VaccTrak لكل شخص بمجرد تلقيهم للتطعيم. يرسل SpeedyHR التصريح، الذي يحتوي على تفاصيل تطعيم الأشخاص، مباشرة إلى عنوان بريدهم الإلكتروني. يمكن لموظفيك استخدامه للتنقل بحرية في مكان العمل، زيارة مواقع العملاء، أو لأغراضهم الشخصية. هذه أداة مفيدة بشكل خاص للشركات في قطاعات الرعاية الصحية والضيافة أو البناء. يجب اتباع الإرشادات الحكومية حيثما تكون قابلة للتطبيق.",
          employeeVaccinationStatus:
            "الأشخاص ذوي الحالة الغير مؤكدة أو الغير ملتزمين بالتطعيم",
          online: "عبر الإنترنت",
          pending: "قيد الانتظار",
          exempt: "معفى",
          preferNotSay: "يفضل عدم الإفصاح",
          notSpecified: "غير محدد",
          addNotes: "إضافة ملاحظات",
          employeeNotes: "الملاحظات مرئية للأشخاص",
          save: "حفظ",
          //CustomModal
          close: "إغلاق",
          deleteStaff: "حذف الموظف",
          confirmStaffDeletion: "هل أنت متأكد من رغبتك في حذف الموظف نهائيًا؟",
          continueSetup: "متابعة الإعداد",
          withStartDate: "بتاريخ البدء",
          workStartDate: "تاريخ بدء العمل",
          allocatedEmployees: "سيتم إزالة جميع الموظفين المخصصين",
          delete: "حذف",
          cancel: "إلغاء",
          renameStaff: "إعادة تسمية الموظف",
          selectAll: "اختر الكل",
          update: "تحديث",
          new: "جديد",
          addBusiness:
            "أضف شركتك إلى Speedy Exchange وابدأ في الاتصال بعملاء جدد اليوم.",
          namePlaceholder: "أدخل الاسم للبحث ...",
          jobTitlePlaceholder: "الاسم، الوظيفة، اللقب، الفريق",
          myDocuments: "مستنداتي",
          companyDocuments: "مستندات الشركة",
          sharedDocuments: "مستندات مشتركة",
          companyDetails: "تفاصيل الشركة",
          whoClockedIn: "انظر من تسجيل الدخول",
          standstormFissure: "تصدع في الصخور الرملية",
          working_from_home: "العمل من المنزل",
          changePassToast:
            "تم تغيير كلمة المرور بنجاح. يرجى تسجيل الدخول باستخدام بيانات الاعتماد الجديدة.",
          at_another_site: "في موقع آخر",
          on_client_visit: "أثناء زيارة العميل",
          not_working: "غير مشغول",
          working_from_usual_location: "العمل من الموقع المعتاد",
          seeMore: "عرض المزيد",
          logOvertime: "سجل الوقت الإضافي",
          overTimeHistory: "سجل الوقت الإضافي",
          timeOffTaken: "الإجازات المأخوذة",
          allFolders: "جميع المجلدات",
          folder: "مجلد",
          file: "ملف",
          any: "أي",
          fulltime: "دوام كامل",
          documentCreatedSuccessToast: "تم إنشاء المستند بنجاح",
          documentCreatFailToast: "فشل إنشاء المستند",
          fileUploadToast: "تم تحميل الملف بنجاح",
          fileUploadFail: "فشل تحميل الملف",
          downloadunsuccess: "فشل التنزيل",
          folderDeleted: "تم حذف المجلد بنجاح",
          folderDeleteFail: "فشل حذف المجلد",
          pleaseEnterValidName: "الرجاء إدخال اسم صالح",
          folderNameUpdate: "تم تحديث اسم المجلد بنجاح",
          unableToUpdateFolder: "غير قادر على تحديث اسم المجلد",
          searchFiles: "أدخل النص هنا...",
          search: "بحث",
          createNewFolder: "إنشاء مجلد جديد",
          folderName: "اسم المجلد",
          folderAlreadyExists: "المجلد موجود بالفعل",
          create: "إنشاء",
          fileAlreadyExist: "الملف موجود بالفعل. يرجى إعادة تسميته",
          upload: "تحميل",
          newFolder: "مجلد جديد",
          type: "النوع",
          dateModified: "تاريخ التعديل",
          edit: "تحرير",
          noFilesFolderFound: "لم يتم العثور على ملفات / مجلدات",
          noFilesFound: "لم يتم العثور على مستندات",
          enterNewName: "أدخل اسمًا جديدًا",
          find: "بحث",
          recommendedImage: "الحجم الموصى به للصورة 1000 × 1000 (5 ميجابايت)",
          sortBy: "ترتيب حسب",
          firstName: "الاسم الأول",
          status: "الحالة",
          noStatusSet: "أي",
          addedToSpeedyHR: "تمت إضافة شخص إلى SpeedyHR",
          richerProfile:
            "هناك بعض الأشياء التي يمكنك القيام بها لإنشاء ملف شخصي أغنى لـ",
          add: "إضافة",
          oneOrMoreTeams: "لفريق واحد أو أكثر",
          addJobTitleOrRole: "إضافة الوظيفة أو الدور",
          addEmergencyContacts: "إضافة جهات الاتصال للطوارئ",
          addAProfilePicture: "إضافة صورة ملف شخصي",
          goToProfile: "الانتقال إلى الملف الشخصي",
          addAnotherEmployee: "إضافة شخص آخر",
          fixedFullOrPartTime: "ثابت بدوام كامل أو جزئي",
          repeatedWorkingTimePattern:
            "الأشخاص على نمط عمل ثابت ومتكرر يعملون ساعات محددة أسبوعيًا ولديهم عدد ثابت من الساعات.",
          shortHoursOrVariable: "ساعات قصيرة أو متغيرة",
          employeesOnContract:
            "الأشخاص في عقد يعملون بعدد مختلف من الساعات أو الأيام من أسبوع إلى أسبوع (ولكن ليسوا في عقود ساعات صفرية)",
          typeOfEmployee: "الرجاء اختيار نوع الشخص",
          email: "البريد الإلكتروني",
          startDate: "تاريخ البدء",
          employeeStartDate: "تاريخ البدء",
          leaveYearStartDate: "تاريخ بداية/نهاية سنة الإجازة",
          fullTimeAnnualLeaveEntitlement: "إجازة سنوية بدوام كامل للشركة",
          allowanceForLeaveYear: "السماح للسنة الإجازة",
          annualLeaveEntitlement: "الإجازة السنوية",
          aboutToAdd: "أنت على وشك إضافة",
          toCompany: "إلى الشركة.",
          summary: "ملخص",
          workingTimePattern: "نمط العمل",
          workingTimesList: "غير قادر على الحصول على قائمة أوقات العمل",
          contractedHoursPerWeek: "عدد ساعات العمل المتعاقد بها أسبوعيًا",
          anAverageWorkingDay: "يوم عمل متوسط",
          workingWeekExcludingBreaks:
            "أيام العمل في الشركة، باستثناء الاستراحات",
          publicHolidaysObserved: "أيام العطل الرسمية الملاحظة لـ",
          selectCountry: "اختر البلد",
          selectRole: "اختر الدور",
          selectWorkPlace: "اختر مكان العمل",
          hours: "ساعات",
          hrs: "ساعة",
          //AddEmployee
          invalidUser: "تم العثور على مستخدم غير صالح",
          invalidUserTypeFound:
            "تم العثور على نوع مستخدم غير صالح، يرجى اختيار مرة أخرى",
          employeeCreated: "تم إنشاء الأشخاص بنجاح",
          emailAlreadyExist: "البريد الإلكتروني موجود بالفعل",
          invalidEmailAddress: "عنوان البريد الإلكتروني غير صالح",
          enterValidEmailAddress: "الرجاء إدخال عنوان بريد إلكتروني صالح",
          pleaseSelectCountry: "الرجاء اختيار البلد",
          selectEmployeeType: "الرجاء اختيار نوع الأشخاص",
          employeeType: "نوع الأشخاص",
          employeeDetails: "تفاصيل الأشخاص",
          workingHours: "ساعات العمل",
          roleAndLocation: "الدور والمكان",
          selectRoleAndLocation: "اختر الدور والمكان",
          done: "تم",
          back: "عودة",
          nextStep: "الخطوة التالية",
          submit: "إرسال",
          updateDocument: "تحديث المستند",
          //AddHourlyEmployee
          fileDownloaded: "تم تنزيل الملف بنجاح",
          userCreated: "تم إنشاء المستخدم بنجاح",
          useCorrectFormatOfCsv: "الرجاء استخدام تنسيق صحيح لملف CSV",
          uploadEmployees: "تحميل الأشخاص",
          //EmployeeCard
          viewFullProfile: "عرض الملف الشخصي الكامل",
          setStatus: "تعيين الحالة",
          viewAnalytics: "عرض التحليلات",
          addANewEmployee: "إضافة شخص جديد",
          addMultipleEmployees: "إضافة أشخاص متعددين",
          //MultipleEmployeesCSV
          downloadCsvTemplateFromAbove: "قم بتنزيل قالب CSV من أعلاه",
          fileShouldBeInCsvFormat: "يجب أن يكون الملف بتنسيق CSV",
          emailShouldBeUnique: "يجب أن يكون البريد الإلكتروني فريدًا",
          useCorrectCsvTemplate:
            "تأكد من استخدام القالب الصحيح لمستخدمي الساعات المتغيرة واليوميين",
          makeSureYouUse: "تأكد من استخدام",
          formatInDates: "تنسيق التواريخ",
          hourlyUserType: "نوع المستخدم لمستخدمي الساعات المتغيرة هو 0",
          dailyUserType: "نوع المستخدم لمستخدمي اليوميين هو 1",
          workingStatusUpdated: "تم تحديث حالة العمل للأشخاص بنجاح",
          failedToUpdateStatus: "فشل تحديث حالة العمل للأشخاص",
          noOfEmployees: "عدد الأشخاص",
          noEmployeeFound: "لم يتم العثور على أشخاص",
          //Headers
          quickActions: "إجراءات سريعة",
          addAnnualLeave: "إضافة إجازة سنوية",
          addSicknessAbsence: "إضافة غياب بسبب المرض",
          addTimeoff: "إضافة إجازة بدوام جزئي",
          addLateness: "إضافة تأخر",
          staffTeams: "الموظفين والفرق",
          addEmployees: "إضافة أشخاص",
          addEmployee: "إضافة أشخاص",
          viewPublishedSchedule: "عرض الجداول المنشورة",
          bulkAddEmployees: "إضافة أشخاص بالجملة",
          dashboard: "لوحة التحكم",
          myEmployees: "مركز الأشخاص",
          timesheet: "ورقة الحضور",
          staffing: "الجداول",
          companyDocs: "الوثائق",
          analytics: "التحليلات",
          settings: "الإعدادات",
          menu: "القائمة",
          youHaveNoNotifications: "ليس لديك إشعارات",
          myAccount: "حسابي",
          logout: "تسجيل الخروج",
          employees: "الأشخاص",
          timeSheet: "ورقة الحضور",
          //ManageTeams/index
          addANewTeam: "إضافة فريق جديد",
          enterTeamName: "الرجاء إدخال اسم الفريق...",
          selectEmployees: "اختيار الأشخاص",
          myMarker: "علامتي",
          //Profile/AbsenceRecord
          overTimeLog: "سجل العمل الإضافي",
          imageUploaded: "تم تحميل صورة الملف الشخصي بنجاح",
          imageUploadFailed: "فشل تحميل صورة الملف الشخصي",
          addStatus: "إضافة حالة",
          resendRegistrationEmail: "إعادة إرسال رسالة تسجيل البريد الإلكتروني",
          resendUserRegistrationEmail:
            "إعادة إرسال رسالة تسجيل البريد الإلكتروني للمستخدم",
          invite: "دعوة",
          resend: "إعادة إرسال الدعوة",
          editStatus: "تحرير الحالة",
          clearStatus: "مسح الحالة",
          aboutToRemove: "أنت على وشك إزالة هذا",
          confirmation: "السجل. هل أنت متأكد أنك تريد القيام بذلك؟",
          yesRemove: "نعم، قم بالإزالة",
          allAbsences: "جميع الغيابات",
          occurrence: "الحدوث",
          documentsRegeneratedSuccessfully: "تم إعادة إنشاء المستندات بنجاح",
          unableToRegenerateDocument: "غير قادر على إعادة إنشاء المستندات",
          logged: "تم تسجيله",
          total: "الإجمالي",
          requestLateness: "طلب التأخر",
          requestToil: "طلب الإجازة بدوام جزئي",
          currentFuture: "الحالي والمستقبل",
          absenceHistory: "تاريخ الغياب",
          //EmergencyForm
          lastName: "الاسم الأخير",
          homePhone: "الهاتف المنزلي",
          mobilePhone: "الهاتف المحمول",
          workPhone: "الهاتف العملي",
          country: "البلد",
          workPlace: "مكان العمل",
          pleaseSelect: "الرجاء التحديد",
          address: "العنوان",
          address1: "العنوان 1",
          address2: "العنوان 2",
          address3: "العنوان 3",
          townCity: "البلدة/المدينة",
          postcode: "الرمز البريدي",
          relationship: "العلاقة",
          partner: "الشريك",
          relative: "الأقارب",
          primaryContact: "جهة الاتصال الرئيسية",
          addEmergencyContact:
            "إضافة جهة اتصال طارئة على الأقل في حالة حدوث شيء غير متوقع.",
          addNewContact: "إضافة جهة اتصال جديدة",
          //ContractInfoUserEmployement
          annualLeaveInformation: "ملخص العقد والدور ومكان العمل",
          contractedHoursOfWork:
            "معلومات عن تاريخ الانضمام وحالة الإنهاء والدور ومكان العمل",
          currentAnnualLeaveAllowance: "مدة الإجازة السنوية الحالية",
          annualLeaveAllowance: "مدة الإجازة السنوية",
          contractSummary: "ملخص العقد",
          contractWeek: "أسبوع العقد",
          contractStartDate: "العقد الحالي",
          annualLeaveYearStart: "بداية العام للإجازة السنوية",
          minLeaveEntitlement: "أدنى مدة للإجازة",
          placeOfWork: "مكان العمل",
          workingLocation: "مكان العمل",
          holidaysAndLeaveTypes: "يحدد الأعياد الرسمية وأنواع الإجازات",

          editContractInfo: "تعديل معلومات العقد",
          selLeaveType: "حدد نوع الإجازة",
          contractInfo: "معلومات العقد",
          workHoursPerWeek:
            "يعمل 35 ساعة على مدار 5 أيام في الأسبوع. تُحسب الأعياد الرسمية من الحق",
          companyWorkingWeek: "أيام العمل في الشركة، باستثناء الاستراحات",
          annualLeaveYearStartDate: "تاريخ بدء السنة الإجازة",
          minAnnualLeaveEntitlement: "الحد الأدنى لحقوق الإجازة السنوية",
          publicHolidaysFor: "أيام العطل الرسمية لـ",
          fullTimeWorkingWeek: "أسبوع العمل بدوام كامل",
          companyFullTimeAnnualLeave: "إجازة الشركة السنوية للعمل بدوام كامل",
          empWorkingTimePattern: "نمط وقت العمل للأفراد",
          fullTime: "بدوام كامل",
          partTime: "بدوام جزئي",
          fixedTerm: "محددة المدة",
          weeks: "أسابيع",
          months: "أشهر",
          additionalResponsibilities: "مسؤوليات إضافية",
          adjustment: "تعديل",
          other: "أخرى",
          noNoticePeriod: "بدون فترة إشعار",
          period: "فترة",
          editRoleInfo: "تحرير معلومات الدور",
          editRoles: "تحرير الأدوار",
          roleInfo: "معلومات الدور",
          jobTitle: "عنوان الوظيفة",
          contract: "عقد",
          notifyOfExpiryOn: "إخطار بانتهاء الصلاحية في",
          probationPeriod: "فترة الاختبار",
          noProbationPeriod: "بدون فترة اختبار",
          probationPeriodEndDate: "تاريخ انتهاء فترة الاختبار",
          noticeProbationPeriod: "الإشعار المطلوب أثناء فترة الاختبار",
          reasonForUpdate: "السبب في التحديث",
          resetForm: "إعادة تعيين النموذج",
          demotion: "تخفيض",
          editRoleInformation: "تحرير معلومات الدور",
          porbationRequired: "مطلوب فترة اختبار",
          noticePeriod: "فترة الإشعار",
          roleInformation: "معلومات الدور",
          probationAndNoticePeriod:
            "عنوان الوظيفة، فترة الاختبار وفترة الإشعار",
          contractType: "نوع العقد",
          fillInTheFormBelow: "املأ النموذج أدناه",
          salaryInformation: "معلومات الراتب",
          salaryAmountAndPaymentFrequency: "مبلغ الراتب وتردد الدفع",
          payrollNumber: "رقم الرواتب",
          salary: "الراتب",
          salaryPerPeriod: "الراتب لكل فترة",
          selectFrequency: "اختر التردد",
          autoEnrExReason: "سبب استبعاد التسجيل التلقائي",
          yearly: "سنوي",
          noExclusion: "بدون استبعاد",
          directorWithoutECon: "مدير بدون عقد عمل",
          nonSalariedLimited: "شريك ذو مسؤولية محددة غير مرتب",
          maximumAnnualContribution: "الحد الأقصى للمساهمة السنوية",
          receivedWindingUp: "استلام مبلغ التصفية",
          workingNoticePeriod: "فترة الإشعار أثناء العمل",
          firstDayCivilianEmp: "اليوم الأول للوظيفة المدنية",
          fortnightly: "كل أسبوعين",
          fourWeekly: "كل أربعة أسابيع",
          monthly: "شهرياً",
          annually: "سنوياً",
          weekly: "أسبوعياً",
          daily: "بساعات ثابتة",
          hourly: "بساعات متغيرة",
          pension: "تقاعد",
          pensionEligible: "هل مؤهل للتقاعد؟",
          optedOut: "تم الاستبعاد؟",
          employeeContribution: "مساهمة الفرد",
          employerContribution: "مساهمة العمل",
          enrolmentDate: "تاريخ التسجيل",
          sensitiveInformation: "معلومات حساسة",
          eligibilityInformation:
            "معلومات الضرائب والضمان الاجتماعي وأهلية العمل",
          tax: "الضرائب",
          taxCode: "رمز الضرائب",
          niNumber: "رقم الضمان الوطني",
          niNumberPH: "رقم الضمان الوطني مثل QQ 12 34 56",
          rightToWork: "الحق في العمل",
          documentVerified: "تم التحقق من وثيقة الحق في العمل",
          dateOfExpiry: "تاريخ انتهاء الترخيص",
          drivingLicence: "رقم رخصة القيادة",
          licenceNumber: "رقم الرخصة",
          updatedSuccessfull: "تم التحديث بنجاح",
          salaryInfoUpdatedSuccessfully: "تم تحديث معلومات الراتب بنجاح",
          salaryInfoUpdateFailed: "فشل تحديث معلومات الراتب",
          updateFailed: "فشل التحديث",
          logUpdatedSucessfully: "تم تحديث السجل بنجاح",
          updateLogFailed: "فشل تحديث السجل",
          logCreatedSucessfully: "تم إنشاء السجل بنجاح",
          createLogFailed: "فشل إنشاء السجل",
          overtimeStart: "بداية العمل الإضافي",
          overtimeEnd: "نهاية العمل الإضافي",
          overtimeLength: "مدة العمل الإضافي",
          payRate: "معدل الأجر",
          cancelOvertime: "إلغاء العمل الإضافي",
          permanentAction: "هذا إجراء دائم ولا يمكن التراجع عنه.",
          reason: "السبب",
          pleaseEnterReason: "الرجاء إدخال سبب",
          approved: "تم الموافقة عليه",
          addedToToilBalance: "تمت إضافته إلى رصيد العمل الإضافي الإجباري",
          toilAbsences: "غيابات العمل الإضافي الإجباري",
          noToilAbsences: "لا توجد غيابات في العمل الإضافي الإجباري",
          availableToTake: "متاحة للأخذ",
          timeOffInLieuToil: "إجازة مقابل العمل الإضافي (TOIL)",
          toilLogged: "تم تسجيل الـ TOIL",
          toilTaken: "تم أخذ الـ TOIL",
          toilBalance: "رصيد الـ TOIL",
          currentAndFuture: "الحالي والمستقبلي",
          history: "التاريخ",
          cancelled: "ملغاة",
          accountEmail: "البريد الإلكتروني الخاص بالعمل",
          personalEmail: "البريد الإلكتروني الشخصي",
          workExtension: "الرقم التوجيهي للعمل",
          medicalCondition: "الحالات الطبية",
          medicalConditionNotes: "ملاحظات حول الحالة الطبية",
          medicationOngoingTreatment: "الأدوية/العلاج المستمر",
          medicationOngoingTreatmentNotes: "ملاحظات حول الأدوية/العلاج المستمر",
          anySpecificAidneeded: "هل هناك حاجة لمعونة أو تكييفات معينة؟",
          informationUpdatedSucessfully: "تم تحديث المعلومات بنجاح",
          invalidInformation: "معلومات غير صالحة!",
          onlyAdminCanEditThisInfo: "يمكن للمسؤول فقط تحرير هذه المعلومات",
          title: "اللقب",
          middleName: "الاسم الأوسط",
          dateOfBirth: "تاريخ الميلاد",
          jan: "يناير",
          feb: "فبراير",
          mar: "مارس",
          apr: "إبريل",
          may: "مايو",
          jun: "يونيو",
          july: "يوليو",
          aug: "أغسطس",
          sep: "سبتمبر",
          oct: "أكتوبر",
          nov: "نوفمبر",
          dec: "ديسمبر",
          gender: "الجنس",
          male: "ذكر",
          female: "أنثى",
          notDisclosed: "لم يتم الكشف",
          updateMedicalInfoSuccessfull: "تم تحديث معلومات الرعاية الصحية بنجاح",
          updateMedicalInfoFailure: "فشل تحديث معلومات الرعاية الصحية",
          contactInformation: "معلومات الاتصال",
          personalInformation: "معلومات شخصية",
          medicalInformation: "معلومات طبية",
          emergencyDetails: "تفاصيل الحالة الطارئة",
          generateNewReport: "إنشاء تقرير جديد",
          thisMonth: "هذا الشهر",
          fourWeeks: "أربعة أسابيع",
          fortnight: "أسبوعين",
          week: "أسبوع",
          month: "شهر",
          todaysAgenda: "جدول اليوم",
          today: "اليوم",
          lastweek: "الأسبوع الماضي",
          last2week: "آخر أسبوعين",
          last3week: "آخر 3 أسابيع",
          reports: "التقارير",
          dateRange: "نطاق التاريخ",
          downloadAs: "تحميل كـ",
          infoToProcess:
            "ملاحظة: هناك الكثير من المعلومات لنقوم بمعالجتها مع هذا النوع من التقرير، وقد يستغرق إنشاء التقرير بضع دقائق.",
          generate: "إنشاء",
          currentleaveentitlement: "حقوق الإجازة الحالية",
          previousleaveentitlement: "حقوق الإجازة السابقة",
          nextleaveentitlement: "حقوق الإجازة المقبلة",
          holidaySummaryReport: "تقرير ملخص الإجازات",
          backToReports: "العودة إلى التقارير",
          selectDate: "اختيار التاريخ",
          pleaseSelectDateRange: "الرجاء اختيار نطاق التاريخ!",
          chooseDateRange: "اختيار نطاق التاريخ",
          searchEmployees: "اختيار شخص",
          allEmployees: "جميع الأشخاص",
          selectEmployee: "اختيار الأشخاص",
          employeeInformation: "معلومات الأشخاص",
          furloughedEmployees: "الأشخاص المعطلين",
          furloughedEmployeesReport: "تقرير الأشخاص المعطلين",
          weRecommendToDownload: "نوصي أيضًا بتنزيل",
          leaveMustBePaid:
            "للتحقق من أي إجازة سنوية تم أخذها خلال هذه الفترة. يجب دفع أي إجازة سنوية تم أخذها خلال فترة التعطيل بنسبة 100٪ من أجر الموظف. تشير إرشادات الحكومة إلى أنه بعد تقديم مطالبتك لهيئة الإيرادات والجمارك الملكية، يجب عليك إعلام موظفيك بأن المطالبة قد تمت. هناك الكثير من المعلومات لنقوم بمعالجتها مع هذا النوع من التقرير، وقد يستغرق إنشاء التقرير بضع دقائق.",
          overtimeRequests: "طلبات العمل الإضافي",
          overtimeRequestsForTeam:
            "تفصيل مفصل للطلبات المعلقة والمرفوضة للعمل الإضافي لفريقك.",
          payableOvertime: "العمل الإضافي المستحق",
          detailedBreakdownOfRecord:
            "تفصيل مفصل لكل سجل للعمل الإضافي المعتمد كعمل إضافي مستحق لفريقك.",
          toilSummary: "ملخص الـ TOIL",
          overviewOfToil:
            "نظرة عامة على الـ TOIL لفريقك، وكم تم تراكم الـ TOIL، وكم تم أخذه أو تركه لاحقًا.",
          toilDetails: "تفاصيل الـ TOIL",
          detailedBreakdownOfRecordAsToil:
            "تفصيل مفصل لكل سجل للعمل الإضافي المعتمد كـ TOIL لفريقك.",
          overtime: "العمل الإضافي",
          overtimeReport: "تقرير العمل الإضافي",
          generateReport: "إنشاء تقرير",
          customDates: "تواريخ مخصصة",
          payrollExceptions: "استثناءات الرواتب",
          payrollExceptionsReport: "تقرير استثناءات الرواتب",
          sensitiveInformationReport: "تقرير المعلومات الحساسة",
          sicknessReport: "تقرير المرض",
          whichReport: "أي تقرير",
          turnoverRetention: "التحويل والاحتفاظ",
          turnoverRetentionReport: "تقرير الانتقال والاحتفاظ",
          workingStatusReport: "تقرير حالة العمل",
          workingTimePatternCreated: "تم إنشاء نمط العمل بنجاح",
          unableCreateWorkingTimePattern: "غير قادر على إنشاء نمط العمل",
          patternDetails: "تفاصيل النمط",
          makeDefault: "اجعله افتراضيًا",
          addNewEmployeeToThisPattern:
            "من خلال جعل هذا النمط نمط العمل الافتراضي عند إضافة شخص جديد، سيتم تعيينهم تلقائيًا لهذا النمط.",
          whatIsThis: "ما هذا؟",
          timesAndBreaks: "الأوقات والاستراحة",
          startAndEndTime:
            "أدخل وقت البدء والانتهاء وفترة الاستراحة ليوم العمل الخاص بك.",
          start: "وقت البدء: ",
          break: "استراحة",
          repeat: "حدد أيام العمل",
          workingDays: "أيام العمل",
          selectedTotalling: "الإجمالي بالساعات",
          excludingBreaks: "باستثناء الاستراحات",
          publicHolidays: "العطل الرسمية",
          annualLeaveEntitlements:
            "حدد ما إذا كان الموظفون العاملون بهذا النمط يعملون في العطل الرسمية وما إذا كانوا مشمولين كجزء من حقوق الإجازة السنوية.",
          deducted: "مخصومة",
          yearlyHolidayEntitlement:
            "سيكون لديهم يوم إجازة في أي عطل رسمي يكونون عادة فيه على حساب حقوق الإجازة السنوية.",
          notDeducted: "غير مخصومة",
          dayOffForAnyPublicHolidays:
            "سيكون لديهم يوم إجازة في أي عطل رسمي يكونون عادة فيه على حساب حقوق الإجازة السنوية.",
          worksPublicHolidays: "يعمل في العطل الرسمية",
          abilityToWorkOnPublicHolidays:
            "يعتبر الأعياد الرسمية أيامًا عادية ولن يكون لديهم عطلة في هذا اليوم، والأشخاص لديهم القدرة على العمل في الأعياد الرسمية.",
          assignEmployeesWhoWorkInASimilarArea:
            "الرجاء تحديد الموظفين الذين ترغب في تعيينهم في هذا مكان العمل. المكان الذي تقوم بإنشائه محدد على أنه <b>Bute</b>؛ يمكنك تعيين الموظفين الذين يعملون في منطقة مماثلة فقط.",
          searchByName: "البحث حسب الاسم",
          searchByGroup: "البحث حسب المجموعة",
          selected: "مختارة",
          assignable: "يمكن التعيين",
          notssignable: "غير قابل للتعيين",
          sureYouWantToAddPlaceOfWork:
            "هل أنت متأكد من رغبتك في إضافة هذا المكان العمل؟",
          addressLine: "سطر العنوان",
          enterDetailsOfNewPlaceOfWork: "الرجاء ملء تفاصيل مكان العمل الجديد.",
          filterAddresses: "يمكنك استخدام هذا الاسم لفرز العناوين",
          hqOrMainDepot: "مثل المقر الرئيسي أو المستودع الرئيسي",
          workInProgressNotice:
            "هذا هو المكان الذي نشارك فيه بعض الميزات التي نعمل عليها حاليًا حتى تتمكن من الوصول المبكر وتقديم ملاحظاتك. الأشياء الموجودة هنا ما زالت قيد التطوير وقد لا تعمل بشكل مثالي بعد، ولكننا نرغب في أن تكون قادرًا على تجربتها في أقرب وقت ممكن. يمكنك تمكين الميزات التي ترغب في اختبارها هنا، ثم ابحث عن رمز المختبرات لمعرفة الميزات التي تتمثل في SpeedyLabs.",
          blipWhosIn: "Blip who's in",
          checkEmployeeStatusByThisFeature:
            "إذا كان موظفوك يستخدمون تطبيق Blip، يمكنك الآن رؤية من قام بتسجيل الدخول ومن في فترة استراحة في مركز الأشخاص من خلال تمكين هذه الميزة.",
          enable: "تمكين",
          disable: "تعطيل",
          generatePopReports:
            "لقد جعلنا إدارة المصاريف أسهل! بمجرد تمكينها، ستتمكن من إنشاء تقرير PoP مفيد مباشرةً من SpeedyHR.",
          //Settings/Company
          companySettingsUpdated: "تم تحديث إعدادات الشركة بنجاح",
          unableToUpdateSettings: "غير قادر على تحديث إعدادات الشركة",
          companyName: "اسم الشركة",
          companyAddress: "عنوان الشركة",
          hideEmailFromOtherEmployees:
            "إظهار أو إخفاء عناوين البريد الإلكتروني للأشخاص من باقي الموظفين. سيؤثر ذلك على:",
          theemployeeHub: "مركز الأشخاص",
          contactDetailsOnApp: "تفاصيل الاتصال على التطبيق المحمول",
          show: "عرض",
          hide: "إخفاء",
          employeeStatus: "حالة الأشخاص",
          employeesCurrentStatus: "يمكن للموظفين عرض حالتهم الحالية.",
          absenceConflict: "تعارض الغياب عندما لا يكونوا في فرق",
          if: "إذا",
          enabled: "ممكن",
          absenceConflictsSetting:
            "هذا الإعداد يسمح لك أيضًا برؤية تعارضات الغياب بين الموظفين الذين لم يتمتعوا بتعيين في فريق.",
          disabled: "معطل",
          futureAnnualLeave: "منع الموظفين من إلغاء الإجازة السنوية المستقبلية",
          deleteAnnualLeaveRequests:
            "بتمكين هذا الإعداد، ستزيل القدرة عن موظفيك لحذف طلبات إجازتهم السنوية الخاصة. يجب على مديرهم أو مسؤول تنفيذي أن يقوم بهذا الإجراء نيابةً عنهم.",
          restrictedAnalyticPermissions: "أذونات التحليل المقيدة",
          restrictEditAndDeleteCapabilities:
            "من خلال تمكين هذا الإعداد، أنت تقيد إمكانية تحرير وحذف مديريك. بمجرد إنشاءها، يمكن تحرير التحليلات من قبل الكاتب والمشرفين. يمكن منح المديرين إذن بالتحرير من قائمة التحليل.",
          permissionsForAnalytics:
            "الأذونات سارية فقط للتحليلات التي تم إنشاؤها بعد تطبيق هذا الإعداد ولا يمكن استخدامها للتحليلات الحالية.",
          hideShiftLabels: "إخفاء تسميات الورديات في التحليلات من قبل الموظفين",
          preventEmployeesFromSeeingColours:
            "بتمكين هذا الإعداد، ستمنع الموظفين من رؤية أي ألوان وتسميات مخصصة للورديات على تحليلاتك.",
          alertToYourEmployees: "إرسال تنبيه لموظفيك",
          pushNotificationToUsers:
            "أرسل رسائل مهمة إلى موظفيك. ستتم إرسال رسالتك كإشعار قابل للدفع للمستخدمين الذين قاموا بتثبيت تطبيق الجوال لدينا. سيرى مستخدمو الويب الرسالة في قائمة الإشعارات عند تسجيل الدخول. هناك عدد محدود من الأحرف المتاحة للرسائل. إذا كنت بحاجة لتضمين رابط ، فكر في استخدام خدمة اختصار عناوين URL مثل",
          sendNotification: "إرسال إشعار",
          notificationPreferences: "تفضيلات الإشعار",
          changeSettingsForNotifications:
            "قم بتغيير الإعدادات للإشعارات التي تتلقاها بشأن موظفيك. سيتم تطبيق هذه التغييرات على الرسائل البريدية والإشعارات القابلة للدفع التي تتلقاها عبر تطبيق الجوال.",
          employeeRequests: "طلبات الموظفين",
          notifyWhenRequestsAre:
            "يمكننا إرسال إشعارات إليك عندما تكون الطلبات:",
          raised: "تم إثارتها",
          deleted: "تم حذفها",
          updated: "تم تحديثها",
          receiveNotificationsFrom: "استقبل الإشعارات من...",
          everyBody: "الجميع",
          noBody: "لا أحد",
          personalAndContactInformation: "المعلومات الشخصية ومعلومات الاتصال",
          personalOrContactInfo:
            "يمكننا إخطارك عندما يقوم موظفوك بتحديث معلوماتهم الشخصية أو معلومات الاتصال بهم",
          personalAndContactDetails: "تفاصيل الاتصال والشخصية",
          everybody: "الجميع",
          nobody: "لا أحد",
          permissions: "أذونات",
          assignOrRevokePermissions: "قم بتخصيص أو إلغاء الأذونات لموظفيك",
          user: "مستخدم",
          roles: "أدوار",
          privileges: "امتيازات",
          subordinates: "المرؤوسين",
          otherPermissions: "أذونات أخرى",
          admin: "مشرف",
          manager: "مدير",
          payroll: "رواتب",
          //PlacesOfWork
          details: "تفاصيل",
          assignEmployees: "تعيين الموظفين",
          assignEmployee: "تعيين موظف",
          confirm: "تأكيد",
          unableToGetWorkPlacesList:
            "غير قادر على الحصول على قائمة أماكن العمل",
          workPlaceCreated: "تم إنشاء مكان العمل بنجاح",
          unableToCreateWorkPlace: "غير قادر على إنشاء مكان العمل",
          deletedPlaceOfWork: "مكان العمل تم حذفه",
          placesOfWork: "أماكن العمل",
          newPlacesOfWork: "أماكن العمل جديدة",
          workPlacesCannotBeEdit:
            "ملاحظة: لا يمكن تحرير أماكن العمل بالكامل بمجرد تعيين الموظفين بهم.",
          usedForAddressFilters: "يتم استخدامها لفلاتر العناوين",
          assigned: "تم التعيين",
          actions: "إجراءات",
          noResultsFound:
            "لم يتم العثور على نتائج - يرجى النقر على الزر أعلاه لإعداد مكان عمل",
          newPlaceOfWork: "مكان العمل الجديد",
          updateLocation: "تحديث الموقع",
          previous: "السابق",
          pleaseSelectedEmployess: "الرجاء تحديد الموظفين",
          pleaseFillAllRequiredFields: "يرجى ملء جميع الحقول المطلوبة",
          unableToGetWorkingTimesList:
            "غير قادر على الحصول على قائمة أوقات العمل",
          unableToDeleteWorkingTimePattern: "غير قادر على حذف نمط أوقات العمل",
          pleaseNote: "يرجى ملاحظة:",
          patternCannotBeEdited:
            "لا يمكنك تحرير النمط بعد إنشائه ، لذلك تأكد من رضاك قبل الانتهاء منه.",
          newEmployeesImported:
            "سيتم تعيين الأشخاص الجدد المستوردين إلى النظام بشكل افتراضي إلى النمط:",
          currentWorkingTimePatterns: "أنماط أوقات العمل الحالية",
          showTimePatterns: "عرض أنماط الوقت",
          createNewPattern: "إنشاء نمط جديد",
          //WorkingTimePatternCell
          minBreak: "فترة استراحة بالدقائق",
          totalling: "المجموع",
          deductedPublicHolidays:
            "يتم خصم الأعياد الرسمية من إجازة السنة السنوية.",
          createManage: "إنشاء وإدارة",
          addMultipleStaffToShifts:
            "إنشاء وتخطيط وإدارة الجدول في مكان واحد. أضف العديد من الموظفين إلى الورديات ، وقم بتحرير الورديات الجارية واحصل على رؤية حديثة لمن يعمل متى.",
          manageShareWithEmployees: "إنشاء وإدارة ومشاركة مع الأشخاص",
          publishedStaffingWithStaff:
            "سنقوم تلقائيًا بمشاركة الجدول المنشور مع موظفيك حتى يعرفوا دائمًا متى يعملون. هل أجريت تغييرات على الورديات؟ سنخبر الأشخاص بذلك بحيث لا يتعين عليك القيام به.",
          unableToFindStaffing:
            "غير قادر على العثور على الجدول مع نطاق تاريخ معين",
          unpublishStaffings: "هل ترغب حقًا في عدم نشر هذا الجدول؟",
          hideMyStaffing:
            "سيتم إخفاء الجدول الخاص بي عن أشخاصك. لن يكون بإمكانهم الوصول إليه أو رؤية وردياتهم وصراعات الغياب بعد الآن.",
          wantToPublishThisStaffing: "هل ترغب حقًا في نشر هذا الجدول؟",
          staffingRenamed: "تمت إعادة تسمية الجدول بنجاح",
          staffingRenamedFailed: "فشلت عملية إعادة تسمية الجدول",
          staffingDeleted: "تم حذف الجدول بنجاح",
          staffingDeletionFailed: "فشلت عملية حذف الجدول",
          staffingEditedFailed: "فشل تحرير الجدول",
          noRoleAssigned: "لم يتم تعيين أي دور",
          noLocationAssigned: "لم يتم تعيين أي موقع",
          viewStaffingDetailRequestFailed: "فشل طلب عرض تفاصيل الجدول",
          createNewSchedule: "إنشاء جدول جديد",
          viewAll: "عرض الكل",
          to: "إلى",
          publishedStaffing: "الجدول المنشور",
          unpublishedStaffing: "الجدول غير المنشور",
          noEmployeeSelected: "لم يتم اختيار أي شخص",
          noAssignableEmployeeFound: "لم يتم العثور على أي شخص يمكن تعيينه",
          staffingMadeEasy: "سهولة إعداد الجداول",
          staffingPlanningFeature:
            "يجعل ميزة التخطيط للجداول لدينا التأكد من وجود الأشخاص المناسبين في المكان المناسب في الوقت المناسب أسهل من أي وقت مضى! لذلك سواء كنت تتناوب في الورديات لدعم التباعد الاجتماعي أو تشارك الملاحظات مع موظفيك حول تدابير COVID-19 قبل وردياتهم ، ستكون واثقًا من أن كل شيء تم التعامل معه بسرعة مع SpeedyHR.",
          copyOfStaffing:
            "الأفضل من ذلك ، مع هذه الميزة متاحة أيضًا على تطبيق الجوال لدينا ، سيكون لدى موظفيك دائمًا نسخة من جدولهم في أي وقت يحتاجون فيه.",
          createStaffingSchedule: "إنشاء جدول جديد",
          staffingPlanningHelp: "مساعدة في تخطيط الجداول؟",
          gettingStartedGuide:
            "غير متأكد من أين تبدأ؟ لا مشكلة! لقد قمنا بإعداد دليل بدء الاستخدام الذي سيظهر لك كل ما تحتاج معرفته عن تخطيط الجداول على SpeedyHR أو شاهد فيديو تعليمي قصير لبدء الاستخدام.",
          takeMeToStaffingGuide: "أخذني إلى دليل الجداول",
          viewHowToVideo: "عرض الفيديو التعليمي",
          tableView: "عرض الجدول",
          setYourShiftTimes:
            "قم بتعيين أوقات الورديات الخاصة بك وقم بتعيينها بسهولة عبر الموظفين والتواريخ دفعة واحدة بنقرة واحدة.",
          staffingCreated: "تم إنشاء الجدول بنجاح",
          staffingCreationFailed: "فشل إنشاء الجدول",
          shiftCreated: "تم إنشاء الوردية بنجاح",
          shiftCreationFailed: "فشل إنشاء الوردية",
          copyAnExistingStaffing: "نسخ جدول موجود",
          scheduleName: "اسم الجدول",
          pleaseFillAllTheFields: "يرجى ملء جميع الحقول",
          staffingRequestFailed: "فشل طلب الجدول",
          scheduleDuration: "مدة الجدول",
          selectYourStaffingView: "اختر عرض الجدول الخاص بك",
          shiftTime: "وقت الوردية",
          breakDuration: "مدة الاستراحة",
          shiftDay: "يوم الوردية",
          createNewStaffingSchedule: "إنشاء جدول جديد",
          newBespokeStaffingSchedule:
            "إنشاء جدول مخصص جديد لعملك. اختر أوقات الورديات الخاصة بك، قم بتعيين الموظفين، وأضف ملاحظات قبل النشر.",
          copyExistingStaffingSchedule: "نسخ الجدول الموجود",
          staffingNotes:
            "اختر لنسخ الجدول السابق واستخراج الورديات الحالية وملاحظات الجدول ، ثم اختر تاريخ بدء جديد وقم بإجراء أي تغييرات تحتاجها قبل النشر.",
          createAndManageEmployeeShiftPatterns:
            "إنشاء وإدارة أنماط ورديات الموظفين التي تكون مرنة تمامًا لتلبية احتياجات فريقك.",
          whatWouldYouLikeToDo: "ماذا ترغب في القيام به؟",
          generateStaffingReport: "إنشاء تقرير الجدول",
          summaryOfTheHours:
            "بنقرة زر واحدة ، يمكنك تنزيل ملخص للساعات التي عملها موظفوك على مدار فترة محددة. يتضمن أيضًا إجمالي الساعات المعمول بها.",
          downloadStaffingReport: "تنزيل تقرير الجدول",
          olderStaffing: "الجداول القديمة",
          endDate: "تاريخ الانتهاء",
          inProgress: "جاري التنفيذ",
          currentlyActiveStaffing:
            "الجداول التي تعمل حاليًا وقيد التنفيذ ستظهر هنا.",
          futureStaffing: "جداول مستقبلية",
          futureStaffingAppear: "الجداول التي ستبدأ في المستقبل ستظهر هنا.",
          unpublish: "إلغاء النشر",
          publish: "نشر",
          staffingDetails: "تفاصيل الجدول",
          scheduleDetails: "تفاصيل الجدول الزمني",
          rotaName: "الاسم",
          rotaDay: "اليوم",
          rotaDuration: "المدة",
          rotaStatus: "حالة الجدول",
          scheduleStatus: "حالة الجدول الزمني",
          published: "تم النشر",
          unPublished: "لم يتم النشر",
          shiftDetails: "تفاصيل الوردية",
          shiftTimeStart: "بداية وقت الوردية",
          shiftTimeEnd: "نهاية وقت الوردية",
          shiftNotes: "ملاحظات الوردية",
          shiftUpdated: "تم تحديث الوردية بنجاح",
          failedToUpdateShift: "فشل تحديث الوردية",
          breakDurationLessThanShiftDuration:
            "يجب أن تكون مدة الاستراحة أقل من مدة الوردية",
          shifts: "الورديات",
          assignee: "المكلف",
          noShiftsToSeeHereYet: "لا توجد ورديات للعرض هنا بعد...",
          createShift: "إنشاء وردية",
          anyStaffingYouAssigned:
            "سيكون أي جدول تم تعيينك عليه متاحًا في أي وقت وفي أي مكان باستخدام تطبيقنا. كلما تمت إضافتك إلى جدول جديد أو تغيير ورديتك ، ستتلقى إشعارًا تلقائيًا حتى تعرف أين يجب أن تكون ومتى.",
          timelineView: "عرض الجدول الزمني",
          dragAndDropView: "عرض السحب والإفلات",
          selectYourDefaultStaffing: "اختر عرض الجدول الافتراضي الخاص بك أدناه",
          companyStaffingSettings: "إعدادات الجدول للشركة",
          restrictedStaffingPermissions: "أذونات الجدول المقيدة",
          staffingCanBeEditedByTheAuthor:
            "من خلال تمكين هذا الإعداد ، ستقيد قدرة مديريك على تحرير وحذف الجداول. بمجرد إنشاء الجدول ، يمكن للمؤلف والمشرفين تحريره. يمكن منح المديرين إضافيين أذونات التحرير من قائمة الجداول.",
          permissionsAreOnlyValidForStaffing:
            "الأذونات صالحة فقط للجداول التي تم إنشاؤها بعد تطبيق هذا الإعداد ولا يمكن استخدامها للجداول الحالية.",
          View: "عرض",
          print: "طباعة",
          rename: "إعادة التسمية",
          Copy: "نسخ",
          assignedUsers: "المستخدمون المعينون",
          youReAboutToAdd: "أنت على وشك إضافة",
          toThisRota: "إلى هذا الجدول",
          shiftAddedSuccessfully: "تمت إضافة الوردية بنجاح",
          failedToAddShift: "فشل إضافة الوردية",
          breakandShiftDuration:
            "لا يمكن أن تكون مدة الاستراحة أكبر من مدة الوردية",
          employeeAssignedToStaffingSuccessfully:
            "تم تعيين الأشخاص إلى الجدول بنجاح",
          employeeAssignRequestFailed: "فشل طلب تعيين الأشخاص",
          selectEmployeeToAssignFirst: "حدد الأشخاص لتعيينهم أولاً",
          userUnassignedFromStaffingSuccessfully:
            "تم إلغاء تعيين المستخدم من الجدول بنجاح",
          unassignRequestFailed: "فشل طلب إلغاء التعيين",
          removeEmployee: "إزالة الأشخاص",
          conflict: "تعارض",
          absent: "غائب",
          dailyTotal: "إجمالي اليومي",
          totalHours: "إجمالي الساعات",
          incBreak: "شامل الاستراحة؟",
          shift: "الوردية",
          viewStaff: "عرض الجدول",
          currentContractedHours: "ساعات العقد الحالي",
          breaksTotal: "الاستراحات (الإجمالي)",
          inclBreaks: "شامل الاستراحات",
          nextUpPublicHoliday: "العطلة العامة التالية",
          youVeAlsoTaken: "لقد أخذت أيضًا",
          overtimeHistory: "سجل الوقت الإضافي",
          timeOffInLieu: "وقت الراحة بدل الوقت الإضافي",
          overtimeClaimsPending: "مطالبات الوقت الإضافي قيد الانتظار",
          pendingRequests: "الطلبات المعلقة الخاصة بي",
          othersPendingRequests: "الطلبات المعلقة",
          declinedRequests: "ترك الغائبين الخاص بي",
          othersDeclinedRequests: "ترك الغائبين",
          text: "نص",
          select: "اختيار",
          lowest: "أدنى",
          highest: "أعلى",
          byTeam: "حسب الفريق",
          login: "تسجيل الدخول",
          welcomeToYourAllInOne: "مرحبًا بك في الكل في واحد الخاص بك",
          resourceConsumingHrProcesses:
            "انتقل إلى الأسفل لمعرفة لماذا تختار الشركات الناشئة والشركات الصغيرة SpeedyHR للعناية بعمليات إدارة الموارد البشرية المستهلكة للوقت والموارد، بينما يركزون على ما يهمهم.",
          interestedToKnowMore: "هل ترغب في معرفة المزيد؟",
          arrangeAConsultation: "قم بترتيب استشارة مع أحد خبراء النظام لدينا",
          keepsYourManagerInformed:
            "يحافظ SpeedyHR على إشعار مديرك ويبقي الفرق متصلة.",
          simplifiedTimeAndAttendance: "الوقت والحضور مبسط ولكن منظم.",
          cloudBasedAttendanceManagement:
            "برامج إدارة الحضور السحابية لدينا تتيح لك:",
          createAndManageRotas: "إنشاء وإدارة الجداول",
          preventAbsenceClashesByCreatingStaffingPlans:
            "تخطيط للفترات المزدحمة في المستقبل ومنع تداخل الغياب من خلال إنشاء خطط جدول لضمان وجود تغطية مناسبة لفرقك للتشغيل.",
          monitorAttendance: "مراقبة الحضور",
          mobileFriendlySystem:
            "من خلال نظامنا الودود للهواتف المحمولة، يمكن لفرقك تسجيل الحضور والانصراف يوميًا. بغض النظر عن مكان عملك، يمكنك معرفة من هو متأخر، يعمل من المنزل، في إجازة أو مريض.",
          manageLeaveRequestsWithEase: "إدارة طلبات الإجازة بسهولة",
          timeOffNotifications:
            "لا تتلقى إشعارًا فقط عندما يتم طلب الإجازة، بل لديك أيضًا إمكانية الوصول إلى تقويم فريقك وحقوق الإجازة بحيث تبقى على اطلاع دائم على الجدول.",
          payrollProcessing:
            "الاستعداد لمعالجة الرواتب من خلال تشغيل تقارير السجل الزمني والغياب والعطل المدمجة لدينا",
          storageSafety: "احتفظ بكل شيء بأمان في مكان واحد.",
          documentStorage:
            "وفّر الأشجار واستخدم تخزين الوثائق لدينا لتخزين الملفات الشخصية والوثائق المشتركة للشركة بشكل إلكتروني.",
          mobileFriendlySoftware:
            "مع زيادة أعداد الأفراد الذين يعملون بنظام مرن، لم تعد ملفات الورق مناسبة لأغراض الإدارة، وبصفتك مديرًا، دائمًا مشغول، وبفضل برامجنا الودية للهواتف المحمولة، يمكنك الوصول إلى وثائق موظفيك أينما كنت.",
          personnelFilesSecurity:
            "نحتفظ بهذه الملفات الشخصية بأمان وخصوصية حتى يمكن رؤيتها فقط من قبل المدير والأفراد.",
          viewMissingAndOutdatedInformation:
            "تأكد من أن سجلات موظفيك محدثة عن طريق تشغيل تقرير مدمج يتيح للمديرين رؤية المعلومات الناقصة والقديمة. أمر حيوي لتدقيق الموارد البشرية.",
          separateStorageForCompanyPolicies:
            "بالإضافة إلى الملفات الشخصية، نقدم تخزينًا منفصلاً لسياسات شركتك التي يمكن للموظفين الوصول إليها بسهولة، مما يوفر لك الوقت الذي كنت تنفقه في إرسال رسائل بريد إلكتروني حول صحتك و",
          needSupportWithPolicies:
            "هل تحتاج إلى دعم فيما يتعلق بالسياسات أو القوالب؟ تابع القراءة لمعرفة كيف يمكننا أن نكون الشريك المثالي لك.",
          yourOneStopHrLibrary: "مكتبة الموارد البشرية الوحيدة التي تحتاجها",
          saveAll: "حفظ الكل",
          accessToComprehensiveLibrary:
            "يمنحك SpeedyHR الوصول على مدار الساعة إلى مكتبة شاملة من النماذج والأدوات والسياسات والإرشادات الموارد البشرية لدعم عملك.",
          dealingWithJobOffers:
            "سواء كنت تتعامل مع عروض العمل، أو إدارة الأداء، أو الجزاءات، أو الإنهاءات، فإن قوالبنا توفر لك الدعم.",
          cipdQualifiedProfessionals:
            "كل وثيقة تأتي مع إرشاد خطوة بخطوة مقدم من محترفي CIPD المؤهلين لدينا. ولكن إذا كنت لا تزال غير متأكد، فمستشارونا الودودون على بعد مكالمة هاتفية أو مؤتمر فيديو.",
          hrLibrary: "افتح ببساطة مكتبة الموارد البشرية في مستندات شركتك",
          pickYourTemplate: "اختر القالب الخاص بك",
          downloadItInstantly: "قم بتنزيله على الفور",
          customiseItToYourNeeds: "قم بتخصيصه وفقًا لاحتياجاتك",
          easilyPrintOrShareDigitally: "قم بطباعته أو مشاركته بسهولة.",
          downloadDocuments: "وثائقنا جاهزة للتنزيل الآن.",
          bookConsultation: "احجز استشارة",
          whyUseHrSoftware: "لماذا استخدام برامج إدارة الموارد البشرية؟",
          digitalSolutions:
            "برامج إدارة الموارد البشرية هي الحلا الرقمي الذي يتيح للمؤسسات إدارة وصيانة العمليات الإدارية للموارد البشرية.",
          automatingManualHrTasks:
            "يجب أن تساعد برامج إدارة الموارد البشرية القياسية في أتمتة المهام الإدارية اليدوية، وتخزين معلومات الأفراد وإنتاج التقارير.",
          allInOneSoftware:
            "لماذا استخدام برنامج إدارة الموارد البشرية SpeedyHR الكل في واحد؟",
          weAreYourPartners: "نحن أكثر من مجرد 'برنامج'، نحن شركاء لك.",
          meetYourBusinessNeeds:
            "برنامجنا قابل للتكيف ليلائم ممارسات العمل المرنة الحالية ووظائف الموارد البشرية المتطورة، لذا بغض النظر عن حجم شركتك، نقدم مجموعة واسعة من الميزات التي تلبي احتياجات عملك.",
          ourSoftwareKeepsYourTeamsConnected:
            "تم القضاء على الوقت الإداري لإدارة الموارد البشرية - يدعم برنامجنا مديريك في إدارة الغياب والتخطيط الموارد وإعداد التقارير، بالإضافة إلى أن برنامجنا يبقي فرقك متصلة من خلال تحديثات الموقع الحية وبرنامج تسجيل الحضور والانصراف الودود للهواتف المحمولة.",
          cipdQualifiedAdvisors:
            "نحن نقدم أكثر من خدمة إدارة موارد بشرية آلية، لدينا مستشاري CIPD المؤهلين متاحون لتقديم الدعم في مجال إدارة العلاقات العمالية بالإضافة إلى القوالب الداعمة والأدلة المتاحة في مكتبتنا الموارد البشرية. مكتبتنا الموارد البشرية تحتوي على مجموعة من الوثائق المتاحة لكل سيناريو طوال دورة حياة الأفراد مع أكثر من 30 ملفًا يتم تحديثها بما يتوافق مع التشريعات الحالية. لا تهدر وقتك في القلق بشأن تغيير تشريعات الموارد البشرية وصحة وسلامة العمل، اتركها لشريك الموارد البشرية الخاص بك.",
          smartHrSoftware:
            "برمجية إدارة الموارد البشرية الذكية التي تحول طريقة إدارتك للموظفين.",
          requestConsultation: "اطلب استشارة",
          speedyHrTakesCareOfYourNeeds:
            "انظر كيف تعتني SpeedyHR بجميع احتياجاتك",
          pleaseEnterEmailToContinue: "الرجاء إدخال البريد الإلكتروني للمتابعة",
          passwordEmailSent: "تم إرسال رسالة تغيير كلمة المرور بنجاح",
          forgotPassword: "هل نسيت كلمة المرور؟",
          enterYourEmailAddress: "أدخل عنوان بريدك الإلكتروني",
          emailAddress: "عنوان البريد الإلكتروني",
          send: "إرسال",
          bothFieldsAreRequired: "كل الحقول مطلوبة",
          yourStrategic: "استراتيجيتك",
          hrPartner: "شريك الموارد البشرية",
          loginToSpeedyHr: "تسجيل الدخول إلى SpeedyHR",
          password: "كلمة المرور",
          forgotYourPassword: "هل نسيت كلمة المرور؟",
          speedyHrAgreement: "بتسجيل الدخول إلى SpeedyHR، أنت توافق على",
          termsOfUse: "شروط الاستخدام",
          termsAndConditions: "الشروط والأحكام",
          privacyPolicy: "سياسة الخصوصية",
          passwordDoesNotMatch:
            "كلمة المرور الجديدة وتأكيد كلمة المرور الجديدة غير متطابقين",
          passwordRequirement:
            "يجب أن تحتوي كلمة المرور على ما لا يقل عن 8 أحرف",
          passwordChangedSuccessfully: "تم تغيير كلمة المرور بنجاح",
          pleaseContactYourAdministrator:
            "رمز غير صالح، يرجى الاتصال بمسؤول النظام",
          enterNewPassword: "كلمة المرور الجديدة",
          confirmNewPassword: "تأكيد كلمة المرور الجديدة",
          currentPassword: "كلمة المرور الحالية",
          pageNotFound: "الصفحة غير موجودة",
          pagesNotFound: "لا يمكننا العثور على الصفحة التي تبحث عنها.",
          countriesReceivedSuccessfully: "تم استلام البلدان بنجاح",
          labApiTabsExample: "مثال على علامات API المختبر",
          employmentInfo: "معلومات التوظيف",
          personalInfo: "معلومات شخصية",
          personalDocuments: "وثائق شخصية",
          companyDocuments: "وثائق الشركة",
          absenceRecord: "سجل الغياب",
          overtimeLog: "سجل العمل الإضافي",
          absence: "الغياب",
          upcomingAbsencesPerEmployee:
            "تقسيم للغياب المأخوذ والمعلق والملغى والمقبل حسب الأفراد.",
          holidaySummary: "ملخص الإجازات",
          breakdownOfAllAnnualLeaves:
            "تقسيم لكل الإجازات السنوية المأخوذة والمعلقة والمقبلة بالإضافة إلى المبلغ المتبقي.",
          generateTimesheetReport: "إنشاء تقرير الجدول الزمني لموظفيك.",
          personalDetailsInformation:
            "تفصيل لجميع المعلومات بما في ذلك سجلات الغياب والمعلومات الشخصية ومعلومات العقد لشخص معين.",
          latenessReportText: "إنشاء تقرير التأخير لموظفيك",
          company: "الشركة",
          workingTimePatterns: "أنماط العمل الزمني",
          activeStaffing: "الجداول النشطة",
          createStaffing: "إنشاء جدول",
          applyFilter: "تطبيق الفلتر",
          clearFilter: "مسح الفلتر",
          timeLog: "سجل الزمن",
          date: "التاريخ",
          clockIn: "بدء الدوام",
          clockOut: "انتهاء الدوام",
          breaks: "فترات الراحة",
          noBreakFound: "لا توجد فترات راحة متاحة",
          noDataFound: "لا توجد بيانات متاحة",
          unableToGetUserTimesheetHistory:
            "غير قادر على الحصول على تاريخ جدول زمني المستخدم",
          unableToGetWhoseInList: "غير قادر على الحصول على قائمة من هو داخل",
          clockedIn: "بدأ الدوام",
          clockedOut: "انتهى الدوام",
          whoIn: "من داخل",
          filterEmployee: "تصفية الأفراد",
          showHistory: "عرض التاريخ",
          myHistory: "تاريخي",
          clockUserOut: "تسجيل خروج المستخدم",
          clockInUser: "تسجيل دخول المستخدم",
          onBreak: "في فترة الراحة",
          pleaseLoginToContinue: "الرجاء تسجيل الدخول للمتابعة",
          pleaseSelectAllFields: "الرجاء تحديد جميع الحقول",
          allFieldsRequired: "كل الحقول مطلوبة",
          pleaseFillAllData: "الرجاء ملء جميع البيانات",
          pleaseFillAllFields: "الرجاء ملء جميع الحقول",
          timeOffUpdatedSuccessfully: "تم تحديث الإجازة بنجاح",
          absenceAddedSuccessfully: "تمت إضافة الغياب بنجاح",
          leaveAlreadyExist: "الإجازة موجودة بالفعل",
          addingAbsenceFailed: "فشل إضافة الغياب",
          deletedMandatoryLeave: "تم حذف الإجازة الإلزامية بنجاح",
          mandatoryLeaveDeletionFailed: "فشل حذف الإجازة الإلزامية",
          users: "المستخدمون",
          pleaseSelectEmployee: "الرجاء تحديد الأشخاص",
          createLeave: "إنشاء إجازة",
          successfully: "بنجاح",
          failed: "فشل",
          requestedOn: "طُلب في",
          willHave: "سيكون لديه",
          none: "لا شيء",
          uploadFile: "تحميل ملف",
          files: "ملفات",
          validatingEmail: "التحقق من البريد الإلكتروني...",
          clear: "مسح",
          updateRole: "تحديث الدور",
          addRole: "إضافة دور",
          employeesCreationFailed: "فشل إنشاء الأشخاص",
          followingErrorOccured: "حدث الخطأ التالي:",
          note: "ملاحظة",
          noOfEmployees: "عدد الأشخاص",
          teamName: "اسم الفريق",
          by: "بواسطة",
          filterAbsences: "تصفية الغياب",
          toil: "TOIL",
          leaveYear: "عام الإجازة",
          annualEntitlement: "حق الإجازة السنوي",
          mr: "السيد",
          mrs: "السيدة",
          miss: "الآنسة",
          ms: "الآنسة",
          dr: "الدكتور",
          prof: "الأستاذ",
          sir: "السيد",
          theRev: "القس",
          theHon: "الشرف",
          selectAPerson: "تحديد شخص",
          employeeInformationReport: "تقرير معلومات الأشخاص",
          latenessReport: "تقرير التأخير",
          staffingReport: "تقرير الجداول",
          Detailed: "مفصل",
          report: "تقرير",
          Vacctrakreport: "تقرير VaccTrak",
          Vacctrak: "VaccTrak",
          patternName: "اسم النمط",
          speedyLabs: "SpeedyLabs",
          companyLogo: "شعار الشركة",
          privacy: "الخصوصية",
          next: "التالي",
          employeeName: "اسم الشخص",
          selectStaffing: "اختر الجدول",
          editStaff: "تحرير الموظفين",
          editStaffDetails: "تحرير تفاصيل الموظفين",
          editAssignEmployee: "تحرير تعيين الأشخاص",
          shiftStart: "بداية الوردية",
          shiftEnd: "نهاية الوردية",
          viewYourShifts: "عرض وردياتك أثناء التنقل",
          staffMembers: "أعضاء الفريق",
          notPublished: "غير منشور",
          documents: "الوثائق",
          whyUseSpeedyHr:
            "لماذا استخدام برمجية إدارة الموارد البشرية SpeedyHR متعددة الخدمات؟",
          goBackTo: "العودة إلى",
          goToCalendar: "انتقال إلى التقويم",
          allStaffing: "جميع الجداول",
          showShiftsFor: "عرض الورديات لـ",
          everyone: "الجميع",
          me: "أنا",
          specificPeople: "أشخاص محددين...",
          showNotes: "عرض الملاحظات",
          publishStaffing: "بمجرد إضافة وردية، يمكنك نشر هذا الجدول.",
          calories: "السعرات الحرارية",
          dessert: "الحلوى (حصة 100 جرام)",
          fat: "الدهون",
          carbs: "الكربوهيدرات",
          protein: "البروتين",
          displayNotes: "عرض الملاحظات في الجدول سيظهرها أيضًا عند الطباعة.",
          breakStarted: "بدء الاستراحة",
          updateAbsence: "تحديث الغياب",
          showMore: "عرض المزيد",
          showLess: "عرض أقل",
          endBreak: "انتهاء الاستراحة",
          startBreak: "بدء الاستراحة",
          start: "بداية",
          end: "نهاية",
          selectYourCurrentStatus: "حدد وضعك الحالي",
          hi: "مرحبًا",
          //OnBoarding
          tellUsALittleBitAboutYourOrganisation: "أخبرنا قليلاً عن مؤسستك",
          excludingClientSitesTellUsAboutAnyLocations:
            "باستثناء مواقع العملاء، أخبرنا عن أي مواقع تعمل منها، أو قم بذلك لاحقًا في الإعدادات",
          tellUsAboutTheRoles:
            "أخبرنا عن الأدوار في مؤسستك، أو قم بذلك لاحقًا في الإعدادات",
          addPeopleFromYourOrganisation:
            "أضف أشخاصًا من مؤسستك إلى SpeedyHR، ويمكنك إضافة المزيد لاحقًا في الإعدادات",
          assignPeopleToRolesAndLocation:
            "تعيين الأشخاص للأدوار والمواقع. ويمكن تعديلها لاحقًا إذا لزم الأمر",
          location: "المواقع",
          roleSettings: "إعدادات الدور",
          role: "الدور",
          assignLineManager: "تعيين مدير الخط",
          assignRolePrevilages: "تعيين الامتيازات للأدوار",
          setHoliday: "تعيين الأعياد",
          bulkEmployeeType: "نوع الأشخاص بالجملة",
          bulkUploadEmployees: "إضافة أشخاص",
          editEmployees: "تحرير الأشخاص",
          rolesAndPrevilages: "إعدادات الأدوار والامتيازات",
          companySettings: "إعدادات الشركة",
          addALocation: "إضافة موقع",
          addARole: "إضافة إعدادات الدور",
          managingRoles: "إدارة الأدوار",
          locationType: "نوع الموقع",
          postalCode: "الرمز البريدي",
          action: "إجراء",
          userType: "نوع المستخدم",
          lineManager: "مدير الخط",
          approver: "الموافق",
          leaveAllowance: "سماح الإجازة",
          previlges: "امتيازات",
          noRoleSelected: "لم يتم تحديد دور",
          addWorkTimePattern: "إضافة نمط العمل والاستراحة",
          timeAndBreak: "الزمن والاستراحة",
          publicHoliday: "العطل الرسمية",
          noRoles: "لا توجد أدوار",
          contractedHours: "ساعات العمل المتعاقد بها",
          contractedHoursPerPeriod: "ساعات العمل المتعاقد بها للفترة",
          avgWorkingHours: "متوسط ساعات العمل",
          companyWorkingHours: "ساعات العمل في الشركة",
          hourly: "ساعات متغيرة",
          selectColumnsOfCsv: ":ما هي المعلومات التي ترغب في تحميلها",
          reset: "إعادة تعيين",
          onBoardingStatus: "تم العثور على أخطاء أثناء عملية التسجيل",
          // Document Titles
          dtHome: "الصفحة الرئيسية",
          dtOnboaring: "عملية التسجيل",
          dtLogin: "تسجيل الدخول",
          dtForgotPass: "نسيت كلمة المرور",
          dtChangePassword: "تغيير كلمة المرور",
          dtDdashboard: "لوحة التحكم",
          dtCalendar: "التقويم",
          dtEmployeesHub: "مركز الأشخاص",
          dtTimesheet: "ورقة الحضور",
          dtStaffing: "جدول الورديات",
          dtCompanyDocs: "الوثائق",
          dtAnalytics: "تحليلات",
          dtSettings: "الإعدادات",
          dtProfile: "الملف الشخصي",
          restDay: "يوم الراحة",
          addRollSetting: "إضافة إعدادات الدور",
          backto: "العودة إلى",
          rolesselected: "الأدوار المحددة",
          downloadcsvtemplate: "تنزيل قالب CSV",
          uploadcsvformultiple: "تحميل CSV لأشخاص متعددين وفقًا للقالب المقدم",
          csvcontainformat: "يحتوي ملف CSV على تنسيق إضافة أشخاص متعددين",
          others: "آخرون",
          // Edit Role Modal
          parttime: "بدوام جزئي",
          fixedtime: "بدوام كامل",
          // Edit Contract Modal
          compromiseagreement: "اتفاق تسوية",
          deceased: "متوفى",
          dismissed: "مفصول",
          endofcontract: "نهاية العقد",
          redundant: "عديم الفائدة",
          resigned: "استقال",
          retired: "تقاعد",
          // Working Time Patterns
          sunday: "الأحد",
          monday: "الاثنين",
          tuesday: "الثلاثاء",
          wednesday: "الأربعاء",
          thursday: "الخميس",
          friday: "الجمعة",
          saturday: "السبت",
          // Place of Work
          updatedsuccess: "تم التحديث بنجاح",
          updatedfailed: "فشل التحديث",
          terminated: "تم الإنهاء",
          terminationDate: "تاريخ الإنهاء",
          terminationReason: "سبب الإنهاء",
          noApprovedcClaims: "لا يوجد مطالبات معتمدة",
          LogOvertimeFor: "تسجيل ساعات العمل الإضافية لـ",
          add: "إضافة",
          AreyouSureyouWant: "هل أنت متأكد أنك تريد",
          DeclinedAbsence: "الغياب المرفوض",
          analyticsDesc: "<p>إنشاء التحليلات التالية</p>",
          calendarDesc: "<p>الغيابات والأعياد الرسمية والأحداث الملحوظة.</p>",
          employeeHubDesc: "<p>البحث عن الأشخاص بالاسم أو الوظيفة</p>",
          sharedDocumentsDesc: "<p>إدارة المستندات الخاصة بك.</p>",
          whoseInDesc: "<p>رؤية من تسجل الدخول</p>",
          staffingDesc:
            "<p>إنشاء وإدارة الجداول وأنماط الورديات التي تكون مرنة بشكل كامل لتلبية احتياجات فريقك.</p>",
          dated: "تاريخ",
          generatedBy: "تم إنشاءه بواسطة",
          companyName: "اسم الشركة",
          companyAddress: "عنوان الشركة",
          copyright: "حقوق النشر ©",
          skip: "يتخطى",
          roleSettingsNote:
            "أضف دورًا واحدًا على الأقل، ويمكن إضافة أدوار إضافية لاحقًا في الإعدادات",
          guideToCSV: "دليل لملء ملف .csv",
        },
      },
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV == "development",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
