import React, { useEffect, useState } from "react";
import AppConstants from "../../Helpers/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { Applogger } from "../../Helpers/Logger";
import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Breadcrumb } from "antd";
import { logoutRequest } from "../../Redux/reducers/AuthenticationReducer";
import {
  showFaliureToast,
  showSuccessToast,
  unixToDateFormater,
} from "../../Utilities";
import {
  fetchDocuments,
  createDocuments,
  uploadFile,
  downloadFile,
  onDeleteFolder,
  onEditFolder,
  onDeleteFile,
  onEditFile,
  regenerateDocumentsRequest,
} from "../../Redux/reducers/DocumentsReducer";
import { saveAs } from "file-saver";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import "../../style.css"
export default function AllFolders({ document_type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  UseLogoutHandler();

  const { token, user, role } = useSelector(
    (state) => state.AuthenticationReducer
  );

  const { documents } = useSelector((state) => state.document);
  const data = useSelector((state) => state.profile);

  const { lng } = useSelector((state) => state.languageReducer);

  const { exist: updateDeleteCompanyFile } = usePrevilagesExist(
    AppConstants.previlages.updateDeleteCompanyFile
  );

  const { exist: updateDeletePersonalFile } = usePrevilagesExist(
    AppConstants.previlages.updateDeletePersonalFile
  );

  const isAdmin = get(user, "is_admin", 0);
  const [myFolderData, setMyFolderData] = useState([]);
  const [myFilesData, setMyFilesData] = useState([]);
  const [currentStepId, setCurrentStepId] = useState("");
  const [previousStepData, setPreviousStepData] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [currentFileId, setCurrentFileId] = useState("");
  const [folderMode, setFolderMode] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [createFolderMode, setCreateFolderMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploadMode, setFileUploadMode] = useState(false);
  const [folderExists, setFolderExist] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [temp, setTemp] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredFoldersData, setFilteredFoldersData] = useState([]);
  const [filteredFilesData, setFilteredFilesData] = useState([]);
  const [sortingType, setSortingType] = useState("any");
  const [itemToEdit, setItemToEdit] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [editType, setEditType] = useState("null");
  const [foldersNavItems, setFoldersNavItems] = useState([
    {
      name: t("allFolders"),
      id: 1,
    },
  ]);

  const editTypes = {
    folder: t("folder"),
    file: t("file"),
  };
  const sortingTypes = {
    aToZ: "aToZ",
    zToA: "zToA",
    any: t("any"),
  };

  const headerTypes = [
    {
      title: t("name"),
    },
    {
      title: t("type"),
    },
    {
      title: t("dateModified"),
    },
    {
      title: t("actions"),
    },
  ];

  useEffect(() => {
    setFilteredFoldersData(myFolderData);
    setFilteredFilesData(myFilesData);
  }, [myFolderData, myFilesData]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [previousStepData]);

  useEffect(() => {
    if (document_type == AppConstants.documentTypes.personal) {
      setCurrentUserId(data?.user?.user_id);
    } else {
      setCurrentUserId(user?.user_id);
    }
    if (currentUserId != "") {
      getAllDocumentHandler();
      sortFoldersandFiles();
    }
  }, [currentUserId]);

  useEffect(() => {
    if (currentStepId !== "") {
      getAllDocumentHandler();
      sortFoldersandFiles();
    }
  }, [currentStepId]);

  useEffect(() => {
    handleSort();
  }, [sortingType]);

  const onBackButtonEvent = (e) => {
    try {
      if (previousStepData) {
        e.preventDefault();

        const currentDataIndex = foldersNavItems.findIndex(
          (val) => val.id == previousStepData.folder_parent_id
        );
        if (currentDataIndex !== -1) {
          const data = foldersNavItems[currentDataIndex];
          documentClickHandler(data);
          setCurrentStepId(previousStepData.folder_parent_id);
          const currentDataaIndex = foldersNavItems.findIndex(
            (val) => val.id == data.id
          );
          if (currentDataaIndex !== -1) {
            setPreviousStepData(foldersNavItems[currentDataaIndex]);
          } else {
            setPreviousStepData(null);
          }
        } else {
          setCurrentStepId(0);
          documentClickHandler(foldersNavItems[0]);
          setPreviousStepData(null);
          window.removeEventListener("popstate", onBackButtonEvent);
        }
      }
    } catch (err) {
      Applogger(err);
    }
  };
  const logOutRequestHandler = () => {
    dispatch(logoutRequest())
      .then((res) => {
        navigate(AppConstants.routes.login);
      })
      .catch((err) => Applogger("Error at logOutRequestHandler", err));
  };

  const getAllDocumentHandler = () => {
    if (token) {
      dispatch(
        fetchDocuments({
          token,
          document_type: document_type,
          user_id: currentUserId,
          id: currentStepId,
          lng,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setMyFolderData([]);
          setMyFilesData([]);
          // just for now apis getting infinite loop
          setTemp(res.data[0].current_id);
          setMyFolderData(res.data[0].data);
          setMyFilesData(res.data[1].data);
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            logOutRequestHandler();
          }
          Applogger("Error at getAllDocumentHandler", err.message);
        });
    }
  };

  const sortFoldersandFiles = () => {
    let currentFolderIndex = documents.findIndex(
      (val) => val.type == "folders"
    );
    let currentfileIndex = documents.findIndex((val) => val.type == "files");

    const currentFile = documents[currentfileIndex];
    const currentFolder = documents[currentFolderIndex];

    const myFilesDatatemp = get(currentFile, "data", []);
    const myFolderDatatemp = get(currentFolder, "data", []);

    if (myFolderDatatemp.length > 0) {
      setMyFolderData(myFolderDatatemp);
    }
    if (myFilesDatatemp.length > 0) {
      setMyFilesData(myFilesDatatemp);
    }
  };

  const documentClickHandler = (document) => {
    getFolderNavItemsList(document);
  };

  const getFolderNavItemsList = (document) => {
    setCurrentStepId(document.id);
    var filteredArray = foldersNavItems;

    if (filteredArray.includes(document)) {
      const currentIndex = filteredArray.findIndex(
        (val) => val.id == document.id
      );
      if (currentIndex + 1) {
        var FilteredArrayLenth = filteredArray.length - currentIndex;
        filteredArray.splice(currentIndex + 1, FilteredArrayLenth + 1);
      }
    } else {
      filteredArray.push(document);
    }
    setFoldersNavItems(filteredArray);
  };

  const createDocumentHandler = () => {
    let documentData = {};
    if (folderName) {
      if (currentStepId == "") {
        documentData = {
          folderName,
          currentStepId: temp,
          user_id: currentUserId,
        };
      } else {
        documentData = {
          folderName,
          currentStepId,
          user_id: currentUserId,
        };
      }

      dispatch(createDocuments({ token, documentData }))
        .then(unwrapResult)
        .then((res) => {
          // if (res.status == 200) {
          showSuccessToast("Document Created Successfully");
          getAllDocumentHandler();
          setFolderName("");
          setCreateFolderMode(false);
          // }
        })
        .catch((res) => {
          showFaliureToast("Document Creation Failed");
        });
    } else {
      showFaliureToast("Please enter folder name");
    }
  };

  const uploadFileHandler = () => {
    let current_step_id;
    if (selectedFile) {
      if (currentStepId == "") {
        current_step_id = temp;
      } else {
        current_step_id = currentStepId;
      }
      dispatch(uploadFile({ token, selectedFile, current_step_id }))
        .then(unwrapResult)
        .then((res) => {
          getAllDocumentHandler();
          setFileUploadMode(false);
          setSelectedFile(null);
          showSuccessToast("File Uploaded Succesfully");
        })
        .catch((err) => {
          showFaliureToast("File Upload Failed");
        });
    } else {
      showFaliureToast("Please select file to continue");
    }
  };

  function isInValidFileSize() {
    const fileSize = get(selectedFile, "size", 0) / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      return true;
    } else {
      return false;
    }
  }

  const changeHandler = (event) => {
    setSelectedFile(null);
    let fileName = event.target.files[0].name;
    let allFiles = [...myFilesData];
    setSelectedFile(event.target.files[0]);
    let currentFileIndex = allFiles.findIndex((val) => val.name === fileName);
    let currentFile = allFiles[currentFileIndex];
    if (currentFile) {
      setFileExists(false);
    } else {
      setFileExists(true);
    }
  };

  const downloadFileHandler = (id) => {
    // var windowRef = window.open();
    var a = document.createElement("a");

    dispatch(downloadFile({ token, currentFileId: id }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at downloadFileHandler", res);
        setFileUploadMode(false);
        // saveAs(res.data, "text/plain;charset=utf-8  ");
        // windowRef.open(res.data, "_blank", "noopener,noreferrer");
        // windowRef.location.href = res.data;

        var strr = res.data.split("/");
        // a.href = res.data;
        // if (strr) {
        //   a.download = strr[strr.length - 1];
        // }
        // a.target = "_blank";
        // a.click();

        saveAs(res.data, strr[strr.length - 1]);
      })
      .catch((err) => {
        showFaliureToast("Download Unsucessfull");
      });
  };

  const fileClickHandler = (id) => {
    setCurrentFileId(id);
    downloadFileHandler(id);
  };

  const checkDocumentHandler = (name) => {
    let allFolders = [...myFolderData];
    let currentFolderIndex = allFolders.findIndex((val) => val.name === name);

    let folderExi = allFolders[currentFolderIndex];
    if (folderExi !== undefined) {
      setFolderExist(true);
    } else {
      setFolderExist(false);
    }

    setFolderName(name);
  };

  const searchDocumnet = (val) => {
    setSearchValue(val.toLowerCase());

    let value = val.toLowerCase();
    let folderResults = [];
    let filesResult = [];

    folderResults = myFolderData.filter((data) => {
      return data.name.toLowerCase().search(value) != -1;
    });
    setFilteredFoldersData(folderResults);

    filesResult = myFilesData.filter((fileData) => {
      return fileData.name.toLowerCase().search(value) != -1;
    });
    setFilteredFilesData(filesResult);
  };

  const handleSort = () => {
    sortData(filteredFoldersData, setFilteredFoldersData);
    sortData(filteredFilesData, setFilteredFilesData);
  };

  const sortData = (getArray, setArray) => {
    const sortedArray = [...getArray].sort((a, b) => {
      if (sortingType === sortingTypes.aToZ) {
        return a.name.localeCompare(b.name);
      } else if (sortingType === sortingTypes.zToA) {
        return b.name.localeCompare(a.name);
      }
    });
    setArray(sortedArray);
  };

  const getModifiedDate = (date) => {
    var finalDate = "-";
    if (date !== null) {
      finalDate = unixToDateFormater(date, "DD-MMM-YY");
    }
    return finalDate;
  };

  const handleEditFolder = (folderToEdit) => {
    Applogger("Called handleEditFolder");
    setItemToEdit(folderToEdit);
    setEditType(editTypes.folder);
    setUpdatedName(folderToEdit.name);
    showModal();
  };

  const handleDeleteFolder = (folderToDelete) => {
    Applogger("Called onDeleteFolder");
    dispatch(onDeleteFolder({ token: token, folderId: folderToDelete.id }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("Folder Deleted Successfully");
        getAllDocumentHandler();
      })
      .catch((res) => {
        showFaliureToast("Folder Deletion Failed");
      });
  };

  const handleEditFile = (fileToEdit) => {
    Applogger("Called handleEditFile");
    setItemToEdit(fileToEdit);
    setEditType(editTypes.file);
    setUpdatedName(fileToEdit.name);
    showModal();
  };

  const handleDeleteFile = (fileToDelete) => {
    Applogger("Called onDeleteFile");
    dispatch(onDeleteFile({ token: token, fileId: fileToDelete.id }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("File Deleted Successfully");
        getAllDocumentHandler();
      })
      .catch((res) => {
        showFaliureToast("File Deletion Failed");
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    resetEditFlow();
  };

  const resetEditFlow = () => {
    setIsModalVisible(false);
    setEditType("");
    setItemToEdit(null);
  };

  const handleOkPress = () => {
    if (updatedName !== "") {
      if (editType === editTypes.folder) {
        callUpdateFolderApi();
      } else if (editType === editTypes.file) {
        callUpdateFileApi();
      } else {
        Applogger("Invalid Edit Type", editType);
      }
    } else {
      showFaliureToast("Please enter valid name");
    }
  };

  const callUpdateFolderApi = () => {
    Applogger("Called onEditFolder");
    dispatch(
      onEditFolder({ token: token, folderId: itemToEdit.id, name: updatedName })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("Folder name updated successfully");
        getAllDocumentHandler();
        resetEditFlow();
      })
      .catch((res) => {
        showFaliureToast("Unable to update folder name");
      });
  };

  const callUpdateFileApi = () => {
    dispatch(
      onEditFile({ token: token, fileId: itemToEdit.id, name: updatedName })
    )
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("File name updated successfully");
        getAllDocumentHandler();
        resetEditFlow();
      })
      .catch((res) => {
        showFaliureToast("Unable to update file name");
      });
  };

  const handleRegenerateDocuments = () => {
    dispatch(regenerateDocumentsRequest({ token: token }))
      .then(unwrapResult)
      .then((res) => {
        showSuccessToast("Documents Regenerated Successfully");
        getAllDocumentHandler();
      })
      .catch((err) => {
        if (get(err, "message", "")) {
          showFaliureToast(get(err, "message", ""));
        } else {
          showFaliureToast("Unable to regenerated documents");
        }
      });
  };

  const handleBreadCrumbsItemClick = (item) => {
    const currentDataIndex = foldersNavItems.findIndex(
      (val) => val.id == previousStepData.folder_parent_id
    );
    if (currentDataIndex !== -1) {
      const data = foldersNavItems[currentDataIndex];

      const currentDataaIndex = foldersNavItems.findIndex(
        (val) => val.id == data.id
      );
      if (currentDataaIndex !== -1) {
        setPreviousStepData(foldersNavItems[currentDataaIndex]);
      } else {
        setPreviousStepData(null);
      }
    }

    getFolderNavItemsList(item);
  };

  const previlagesEnabled = (personal, company) => {
    if (role == AppConstants.roleTypes.Owner) {
      return true;
    }

    if (document_type == AppConstants.documentTypes.personal) {
      if (personal) {
        return true;
      } else {
        return false;
      }
    } else {
      if (company) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getExtensionlessName = (name, ext) => {
    const extension = `.${ext}`;
    let finalName = name;
    if (finalName.includes(extension)) {
      finalName = finalName.replace(extension, "");
    }
    return finalName;
  };

  return (
    <div>
      <div className="myFlex-cs flex-wrap-sm gap-3 animate__animated animate__fadeInDown justify-between">
        <form className="col-12 col-sm-12 col-md">
          <label className="fs-6 fw-light fw-normal">{t("search")}</label>
          <input
            className="filter-form"
            placeholder={t("searchFiles")}
            type="search"
            value={searchValue}
            onChange={(e) => searchDocumnet(e.target.value)}
          />
        </form>
        <form className="col-12 col-sm-12 col-md">
          <label className="fs-6 fw-light fw-normal">{t("sortBy")}</label>
          <select
            onChange={(e) => setSortingType(e.target.value)}
            className="filter-form"
          >
            <option value={sortingTypes.any} className="fw-bold">
              {t("name")} (Any)
            </option>
            <option value={sortingTypes.aToZ} className="fw-bold">
              {t("name")} (A-Z)
            </option>
            <option value={sortingTypes.zToA} className="fw-bold">
              {t("name")} (Z-A)
            </option>
          </select>
        </form>
      </div>

      {(AppConstants.documentTypes.personal == document_type ||
        AppConstants.documentTypes.company == document_type) && (
        <div className="d-flex flex-wrap-sm-rev justify-content-between gap-2 align-items-start mb-2">
          <div>
            {createFolderMode && (
              <div>
                <h1 className="fw-bold">{t("createNewFolder")}</h1>
                <div>
                  <label className="form-label" for="customFile">
                    {t("folderName")}
                  </label>
                  <input
                    value={folderName}
                    type={"text"}
                    onChange={(e) => checkDocumentHandler(e.target.value)}
                    className="form-control"
                    id="customFile"
                  />
                  {folderExists && (
                    <div>
                      <p className="text-danger">{t("folderAlreadyExists")}</p>
                    </div>
                  )}
                </div>
                <div className="d-flex gap-2 mt-2">
                  <button
                    onClick={() => createDocumentHandler()}
                    className={`btn btn-primary-cs ${
                      folderExists ? "disabled" : ""
                    }`}
                  >
                    {t("create")}
                  </button>
                  <button
                    onClick={() => {
                      setSelectedFile(null);
                      setCreateFolderMode(false);
                    }}
                    className="btn btn-primary-outline-cs"
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            )}
            <br />
            {fileUploadMode && (
              <div className={`${fileUploadMode && "animate__backInLeft"}`}>
                <div>
                  <input
                    type={"file"}
                    className={"form-control"}
                    onChange={changeHandler}
                  />
                  {!fileExists && (
                    <p className="text-danger">{t("fileAlreadyExist")}</p>
                  )}
                  {/* {previlagesEnabled(uploadPersonalFile, uploadCompanyFile) ? ( */}
                  <div className="d-flex mt-2 gap-2">
                    <button
                      onClick={() => uploadFileHandler()}
                      className={`btn ${
                        !fileExists && "disabled"
                      } btn-primary-outline-cs`}
                    >
                      {t("uploadFile")}
                    </button>
                    <button
                      onClick={() => {
                        setSelectedFile(null);
                        setFileUploadMode(false);
                        setFileExists(true);
                      }}
                      className="btn btn-primary-outline-cs"
                    >
                      {t("cancel")}
                    </button>
                  </div>
                  {/* ) : null} */}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-wrap-sm justify-content-end gap-2 animate__animated animate__fadeInDown">
            {/* {previlagesEnabled(uploadPersonalFile, uploadCompanyFile) && ( */}
            <button
              onClick={() => setFileUploadMode(!fileUploadMode)}
              className="btn btn-primary-cs d-flex align-items-center gap-2 fs-6"
            >
              <i className="ri-upload-2-line" />
              <span>{t("upload")}</span>
            </button>
            {/* )} */}
            {/* {previlagesEnabled(createPersonalFolder, createCompanyFolder) && ( */}
            <button
              onClick={() => setCreateFolderMode(!createFolderMode)}
              className="btn btn-primary-outline-cs d-flex align-items-center gap-2 fs-6"
            >
              <i className="ri-folder-add-line" />
              <span>{t("newFolder")}</span>
            </button>
            {/* )} */}
          </div>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <Breadcrumb className="mt-2">
          {foldersNavItems.length > 0 &&
            foldersNavItems.map((item, index) => {
              return (
                <Breadcrumb.Item
                  className="fst-normal cursor-pointer"
                  onClick={() => handleBreadCrumbsItemClick(item)}
                  key={index}
                >
                  {item.name}
                </Breadcrumb.Item>
              );
            })}
        </Breadcrumb>
        <div className="d-flex flex-wrap-sm justify-content-end gap-2 animate__animated animate__fadeInDown">
          {isAdmin == 1 &&
            AppConstants.documentTypes.company == document_type && (
              <button
                onClick={() => handleRegenerateDocuments()}
                className="btn btn-primary-cs d-flex align-items-center gap-2 fs-6"
              >
                <span>{t("regenerateDocuments")}</span>
              </button>
            )}
        </div>
      </div>
      <div className="row mt-2 rbc-row-content-scroll-container animate__animated animate__backInUp border-custom">
        <table className="table table-cstm table-borderless table-cstm">
          <thead>
            <tr
              className="border-bottom-ligh "
              style={{ backgroundColor: "#00aff0" }}
            >
              {headerTypes.map((headerType) => {
                const { title } = headerType;
                return (
                  <th className="fw-bold" style={{ color: "white" }}>
                    {title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom-height">
            {filteredFoldersData.map((val, index) => {
              return (
                <tr
                  key={index}
                  className="border-bottom-light tbl-row-cs bg-light align-items-center"
                >
                  <td className="d-flex align-items-center" scope="row">
                    <i className="h1 ri-folder-line fs-4 me-2 mb-0" />
                    <button
                      onClick={() => {
                        setPreviousStepData(val);
                        documentClickHandler(val);
                      }}
                      className="text-primary text-left"
                    >
                      {val.name}
                    </button>
                  </td>
                  <td>{t("folder")}</td>
                  <td>{getModifiedDate(val.modified_at)}</td>
                  <td>
                    {(role == AppConstants.roleTypes.Admin ||
                      document_type == AppConstants.documentTypes.personal) &&
                      previlagesEnabled(
                        updateDeletePersonalFile,
                        updateDeleteCompanyFile
                      ) && (
                        <>
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-mdb-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ri-more-2-line fs-5 fw-bold" />
                          </button>

                          <ul
                            className="dropdown-menu drop"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li
                              onClick={() => handleEditFolder(val)}
                              className="dropdown-item cursor-pointer"
                            >
                              {t("edit")}
                            </li>
                            <li
                              onClick={() => handleDeleteFolder(val)}
                              className="dropdown-item cursor-pointer"
                            >
                              {t("delete")}
                            </li>
                          </ul>
                        </>
                      )}
                  </td>
                </tr>
              );
            })}
            {filteredFilesData.map((val, index) => {
              return (
                <tr
                  key={index}
                  className="border-bottom-light tbl-row-cs bg-light align-items-center"
                >
                  <td className="d-flex align-items-center" scope="row">
                    <i className="ri-file-line fs-4 me-2" />
                    <a
                      onClick={() => fileClickHandler(val.id)}
                      href="#"
                      className="text-primary text-left"
                    >
                      {getExtensionlessName(
                        val.name,
                        get(val, "extension", "")
                      )}
                    </a>
                  </td>
                  <td>{get(val, "extension", "File") ?? "File"}</td>
                  <td>{getModifiedDate(val.modified_at)}</td>
                  <td>
                    {previlagesEnabled(
                      updateDeletePersonalFile,
                      updateDeleteCompanyFile
                    ) && (
                        <>
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-mdb-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ri-more-2-line fs-5 fw-bold" />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li
                              onClick={() => handleEditFile(val)}
                              className="dropdown-item cursor-pointer"
                            >
                              {t("edit")}
                            </li>

                            <li
                              onClick={() => handleDeleteFile(val)}
                              className="dropdown-item cursor-pointer"
                            >
                              {t("delete")}
                            </li>
                          </ul>
                        </>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {filteredFilesData.length == 0 && filteredFoldersData.length == 0 ? (
          <h2 className="fs-4" style={{ textAlign: "center" }}>
            {t("noFilesFolderFound")}
          </h2>
        ) : null}
        <Modal
          title={t("enterNewName")}
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleOkPress}
        >
          <input
            type="text"
            className="filter-form"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
          />
        </Modal>
      </div>
    </div>
  );
}
