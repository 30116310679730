import React, { useState, useEffect } from "react";
import { StaffingRequest } from "../../Redux/reducers/StaffingReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { Applogger } from "../../Helpers/Logger";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import Header from "../../components/Header/Header";
import ActiveStaffing from "../../components/Staffing/ActiveStaffings";
import OlderStaffing from "../../components/Staffing/OlderStaffings";
import Shifts from "../../components/Staffing/Shifts";
import CreateStaffing from "../../components/Staffing/CreateStaffing";
import AppConstants from "../../Helpers/AppConstants";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Spinner from "../../components/Spinner";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseLogoutHandler from "../../Helpers/useLogoutHandler";
import UseUsers from "../../Helpers/useUsers";
import { updateMultipleCalendarSelect } from "../../Redux/reducers/StaffingReducer";
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const schedulesTabs = {
  active: "active_staffing",
  older: "older_staffing",
  create: "create_staffing",
  shifts: "shifts",
};

export default function Staffing(props) {
  // Dispatchers
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Custom Hooks
  UseLogoutHandler();
  useTitleHandler(t("staffing"));
  const { allUsers } = UseUsers(false);

  // Params
  const [queryParameters, setQueryParameters] = useSearchParams();
  const [params] = useSearchParams();
  const currentTab = params.get("active_tab");

  // Reducers
  const { data, loading } = useSelector((state) => state.staffing);
  const { token, role } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);

  // Previlages
  const { exist: activeCreateStaffingBtn } = usePrevilagesExist(
    AppConstants.previlages.activeCreateStaffingBtn
  );

  const { exist: listShift } = usePrevilagesExist(
    AppConstants.previlages.listShift
  );

  // Local States
  const [inActiveStaffing, setInActiveStaffing] = useState([]);
  const [currentStaffing, setCurrentStaffing] = useState(null);
  const [value, setValue] = useState(schedulesTabs.active);

  useEffect(() => {
    CallData();
    ManageStaffingData();
    clearMulitiShiftData();
  }, []);

  // This redux store is being populated in child widgets such as active shcedule / old schedules 
  // but we need to clear it in parent  as well, 
   const clearMulitiShiftData = () => {
      dispatch(updateMultipleCalendarSelect({ array: [] }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at updateMultipleCalendarSelect", res);
          // getStaffingRequest();
        })
        .catch((err) => {
          Applogger("Error at updateMultipleCalendarSelect", err);
        });
    };


  useEffect(() => {
    if (currentTab) {
      setValue(currentTab);
    }
  }, [params]);

  const copyStaffingHandler = (value) => {
    setCurrentStaffing(value);
    const newQueryParameters = new URLSearchParams();
    newQueryParameters.set("active_tab", schedulesTabs.create);
    setQueryParameters(newQueryParameters);
  };

  const copyModeDoneHandler = () => {
    setCurrentStaffing(null);
  };

  function ManageStaffingData() {
    const active = [];
    const inactive = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.status === "active") {
        active.push(element);
      } else if (element.status === "inactive") {
        inactive.push(element);
      }
    }

    setInActiveStaffing(inactive);
  }

  function CallData() {
    dispatch(StaffingRequest({ token, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at StaffingRequest", response);
      })
      .catch((err) => {
        Applogger("Error at StaffingRequest", err.message);
      });
  }

  const handleChange = (_, newValue) => {
    const newQueryParameters = new URLSearchParams();
    newQueryParameters.set("active_tab", newValue);
    setQueryParameters(newQueryParameters);
  };

  const navLinks = [
    {
      id: schedulesTabs.active,
      placeholder: t("activeStaffing"),
      role: role,
      content: (
        <ActiveStaffing
          copyStaffing={copyStaffingHandler}
          Data={inActiveStaffing}
          onClick={() => handleChange("", schedulesTabs.create)}
        />
      ),
    },
    {
      id: schedulesTabs.older,
      placeholder: t("olderStaffing"),
      role: role,
      content: (
        <OlderStaffing
          copyStaffing={copyStaffingHandler}
          Data={inActiveStaffing}
          onClick={() => handleChange("", schedulesTabs.create)}
        />
      ),
    },
    {
      id: schedulesTabs.shifts,
      placeholder: t("shifts"),
      role: role,
      content: <Shifts />,
    },
    {
      id: schedulesTabs.create,
      placeholder: t("createStaffing"),
      role: role,
      content: (
        <CreateStaffing
          copyModeDone={copyModeDoneHandler}
          currentStaff={currentStaffing}
        />
      ),
    },
  ];

  return (
    <div>
      {loading == "pending" && <Spinner />}
      <Header active={"Staffing"} />
      <br />
      <div className="container_custom py-2">
        <h2 className="top-heading">{t("staffing")}</h2>
      </div>
      <div className="container_custom animate__animated animate__backInUp">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <AntTab
                label={t("activeStaffing")}
                value={schedulesTabs.active}
              />
              <AntTab label={t("olderStaffing")} value={schedulesTabs.older} />
              {listShift && (
                <AntTab label={t("shifts")} value={schedulesTabs.shifts} />
              )}
              {activeCreateStaffingBtn && (
                <AntTab
                  label={t("createStaffing")}
                  value={schedulesTabs.create}
                />
              )}
            </TabList>
          </Box>
          {navLinks.map((i, index) => {
            return (
              <TabPanel
                key={index}
                className="py-4 "
                value={i.id}
                style={{ padding: 0 }}
              >
                {i.content}
              </TabPanel>
            );
          })}
        </TabContext>
      </div>
    </div>
  );
}
